import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';

class Dictionary {
    @observable isLoading = false;
    @observable tableData = [];
    @observable typeList = [];
    @observable selectedRows = [];//表格被选中的行
    @observable PageInfo = {};
    @observable formData = {};
    @observable type = '';//查询条件
    @observable modalVisible = false;

    //获取表格数据
    @action.bound async getData(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.dictList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取查询列表
    @action.bound async getTypeList(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.dictTypeList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.typeList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //新增/更新字典
    @action.bound async addNew(type, params) {
        this.isLoading = true
        const url = type ? services.requestUrl.saveDict : services.requestUrl.updateDict
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //删除/批量删除字典
    @action.bound async deleteDict(type, params) {
        this.isLoading = true
        //1删除，0批量删除
        const url = type ? services.requestUrl.removeDict : services.requestUrl.batchRemoveDict
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //打开/关闭模态框
    @action.bound modalChange = (type, record) => {
        if (type) {
            this.formData = record
        } else {
            this.formData = {}
        }
        this.modalVisible = !this.modalVisible
    }
}

let DictionaryStore = new Dictionary();
export default DictionaryStore;