import { observable, action } from 'mobx'
import * as services from '../services/device'
import { omit } from 'lodash'
import { isDataExist } from '../utils/dataTools'


class Store {
    @observable isLoading = false
    @observable tableData = []
    @observable pageInfo = { pageIndex: 1, pageSize: 10 }
    @observable deviceList = []
    @observable workshopList = [];
    @observable searchOptions = {}

    @observable historyLoading = false
    @observable historyData = []
    @observable historyPageInfo = { pageIndex: 1,pageSize: 10}
    @observable id = ''

    /**
     * 获取表格数据，维修报表
     * @param {Object} params 
     */
    @action.bound async getData(params) {
        this.isLoading = true
        this.tableData = []
        try {
            let res = await services.gets('getReportData')({
                ...omit(this.pageInfo, 'total'),
                ...this.searchOptions,
                ...params
            })
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.pageInfo = res.data.data.page
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * 获取设备列表
     * @param {Object} params 
    、 * @param {String} params.deviceName 设备名
     */
    @action.bound async getDeviceList(params) {
        this.deviceList = []
        try {
            let res = await services.gets('getDeviceAndGroup')(params)
            if(isDataExist) {
                this.deviceList = res.data.data
            }
        }
        catch(error) {
            console.log(error)
        }
    }
    /**
     * 获取车间列表
     */
    @action async getWorkshopList() {
        try {
            let res = await services.gets('getWorkShopList')()
            if (isDataExist(res)) {
                this.workshopList = res.data.data;
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * 获取工单历史操作记录
     * @param {Object} params 
     */
    @action async getReportInfo(params){
		this.historyLoading = true
		try {
			let res = await services.gets('getReportInfo')({
                id: this.id,
                ...omit(this.historyPageInfo, 'total'),
                ...params
            })
			this.historyLoading = false
			if(isDataExist(res)){
                this.historyData = res.data.data.items || []
                this.historyPageInfo = res.data.data.page
                return true
			}
		} catch (error) {
			console.log(error)
		}
	}	
    @action.bound clearData() {
        this.isLoading = false
        this.tableData = []
        this.pageInfo = { pageIndex: 1, pageSize: 10 }
        this.deviceList = []
        this.workshopList = [];
        this.searchOptions = {}
    }
}
let MatintainReport = new Store()
export default MatintainReport