/*
 * @catalogue: 
 * @Description: 
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-03-01 14:24:50
 */
import { get,put,post } from '../utils/request';

const requestList = {
  getList :'/mom/basic/defineColumn/list',
  getMenuList:'/uaa/menu/treeOnLogin',
  getCustomMenu:'/mom/sys/userMenu/list',
  savePassword:'/mom/sys/user/resetPwd',
  saveBasicInfo:'/uaa/user/updatePassword',

  /* get_token */
  setLogin:'/uaa/form/pcToken',
  saveCustomMenu:'/mom/sys/userMenu/save',
  addCustomMenu:'/mom/sys/userMenu/add',
  deleteCustomMenu:'/mom/sys/userMenu/delete',
  setLogout:'/uaa/user/logout',
  
  // 大屏
  getAssemblyProgress: '/mom/lk/work/getAssemblyProgress', //获取总装工单进度(大屏)
  getTestProgress: '/mom/lk/work/getTestProgress', //获取调试工单进度(大屏)
  getAllProgress: '/mom/lk/work/getAllProgress', //获取全产线工单进度(大屏)
  getWorkProgress: '/mom/lk/work/getWorkProgress', //获取 工单进度(大屏)



  /*生产分析表头*/
  getProductionOutput: '/mom/homePage/out',
  getDefectStatistics: '/mom/reportForm/unqualifiedReason',
  getDeviceStatus: '/mom/homePage/deviceStatusHours',
  /* 免密登录  */
  setAutoAuth:'/uaa/form/almomToken',
  /* 页面图片  */
  getConfigList: '/mom/resource/listLogo',
}

export function gets(type){
  return async function(data,options){
    return await get(requestList[type],data,options);
  }
}
export function posts(type){
  return async function(data,options){
    return await put(requestList[type],data,options);
  }
}
export function postsForm(type){
  return async function(data,options){
    return await post(requestList[type],data,options);
  }
}