/**
 * @description 项目通用的无状态组件 可扩展
 */
import React from 'react';
import { orderStatus, planStatus, workStatus } from 'constants/status_constant';
import { Tag } from 'antd';
import {
	CheckCircleOutlined,
	SyncOutlined,
	CloseCircleOutlined,
	ExclamationCircleOutlined,
	PauseCircleOutlined,
	MinusCircleOutlined,
	VerticalAlignBottomOutlined,
} from '@ant-design/icons';


/**
 * @author zyn
 * @description 判断table中某状态前的小圆点
 * @param {Number} value 状态类型
 */
import './index.less';


export function circleStatus(value, type, data = {}) {
	let isOrder = type === 'order',
		isPlan = type === 'plan',
		isWork = type === 'work',
		isMaintain = type === 'maintain';
	let success = isOrder ? value === 5 : isPlan ? value === 4 : isWork ? [6, 99].includes(value) : false,
		processing = isOrder ? [3,5].includes(value) : isPlan ? value === 2 : isPlan ? value === 4 : isWork ? [4, 6].includes(value) : false,
		error = isOrder ? value === 6 : false,
		warning = isOrder ? value === 7 : isWork ?  value===5 : false,
		isdefault = isOrder ?[1,2].includes(value) : isWork ? [1,2,3].includes(value) : isPlan ? value === 1 : false;
	return (
		<Tag 
		
		  color={
				success ? 'success' :
				error ? ' error' : 
				warning ? 'warning' :
				processing ? 'processing' : 
				isdefault ? 'default' : ''


		}
			icon={
				success ? <CheckCircleOutlined /> :
				error ? <CloseCircleOutlined /> :
				warning ? value === 5 ? <PauseCircleOutlined /> : <ExclamationCircleOutlined /> :
				processing ? <SyncOutlined spin /> : 
				isdefault ? value ===3? <CheckCircleOutlined />:[2].includes(value)?<VerticalAlignBottomOutlined /> :value ===1? <CloseCircleOutlined />:<MinusCircleOutlined /> : ''
			}

		>{isOrder ? orderStatus[value] :
			isPlan ? planStatus[value] :
				isMaintain ? data[value] :
					workStatus[value]}</Tag>
	);
}


