import { observable, action } from 'mobx';
import { message } from 'antd';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { omit } from 'lodash';

class Capacity {
    @observable isLoading = false;
    @observable tableData = [];
    @observable selectedRows = [];
    @observable bomList = [];
    @observable allBom = [];
    @observable modalVisible = false;
    @observable PageInfo = { pageIndex: 1, pageSize: 10 };
    @observable formData = {};
    @observable techLine = [];
    @observable searchOptions = {};

    //获取表格数据
    @action.bound async getData(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.capacityList, {
                ...omit(this.PageInfo, 'total'),
                ...this.searchOptions,
                ...params
            })
            this.isLoading = false
            if (isDataExist(res)) {
                this.PageInfo = res.data.data.page
                let count = 1
                let number = []
                for (let i = 0; i < res.data.data.items.length; i++) {
                    if ((i + 1) < res.data.data.items.length) {
                        if (res.data.data.items[i].materialName === res.data.data.items[i + 1].materialName) {
                            count++
                        } else {
                            number.push(count)
                            count = 1
                        }
                    } else if ((i + 1) === res.data.data.items.length) {
                        number.push(count)
                        count = 1
                    } else {
                        if (number.length === 0) {
                            number.push(count)
                        }
                        return
                    }
                }
                for (let j = 0; j < number.length; j++) {
                    if (j === 0) {
                        res.data.data.items[0].rowSpan = number[0]
                    } else {
                        let index = 0
                        for (let m = j - 1; m >= 0; m--) {
                            index += number[m]
                        }
                        res.data.data.items[index].rowSpan = number[j]
                    }
                }
                this.tableData = res.data.data.items
                console.log(res.data.data.items,number)
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取所有物料
    @action.bound async getAllBomNoCapacity(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.noCapacity, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.bomList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取所有物料
    @action.bound async getAllBom(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.allBomList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.allBom = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //删除产能
    @action.bound async deleteCapacity(type, params) {
        this.isLoading = true
        const url = type ? services.requestUrl.removeCapacity : services.requestUrl.removeCapacity
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //保存产能
    @action.bound async saveCapacity(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.saveCapacity, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    @action.bound async editCapacity(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.editCapacity, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    //获取工艺路线
    @action.bound async getTechLine(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.techLine, params)
            this.isLoading = false
            if (isDataExist(res)) {
                if(typeof res.data.data == 'string'){
                    message.warn(res.data.data)
                }
                else
                    this.techLine = res.data.data[0]
            }
        } catch (error) {
            console.log(error)
        }
    }
    //打开/关闭模态框
    @action.bound modalChange = (type, record) => {
        if (type) {
            this.formData = record
        } else {
            this.formData = {}
            this.techLine = { procedures: [] };
        }
        this.modalVisible = !this.modalVisible
    }
    @action clearAll(){
        this.isLoading = false;
        this.tableData = [];
        this.selectedRows = [];
        this.bomList = [];
        this.allBom = [];
        this.modalVisible = false;
        this.PageInfo = { pageIndex: 1, pageSize: 10 };
        this.formData = {};
        this.techLine = { procedures: [] };
    }
}

let CapacityStore = new Capacity();
export default CapacityStore;