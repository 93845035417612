import { get, put } from '../utils/request';

const requestList = {
  /* 计划评审 */
  getOrder: '/mom/product/order/list',
  generatePlan: '/mom/product/order/generatePlan',
  rejectPlan: '/mom/product/order/toOperate',
  batchReview: '/mom/product/order/batchToOrder',
  /* 生产任务 */
  getPlanList: '/mom/product/plan/list',
  generateOrderId: '/mom/product/order/generateOrderId', // default generateOrderId
  getOrderList: '/mom/product/order/orderList',//get related orderList
  getBomList: '/mom/basic/material/getInfo',
  savePlan: '/mom/product/plan/save',
  removePlan: '/mom/product/plan/remove',
  batchRemove: '/mom/product/plan/batchRemove',
  toWork: '/mom/product/plan/toWork',
  toSyncData: '/mom/product/plan/sync',

  /* 生产报工 */
  getDevice: '/mom/basic/device/getDeviceAndGroupInfo', //获取设备
  getWorkReport: '/mom/product/work/listWorks',
  startUp: '/mom/product/report/startup',
  getWorker: '/mom/sys/user/userList',
  toComplete: '/mom/product/report/toComplete',
  getSuccessAmount: '/mom/ipc/device/queryReportByWorker', // 获取合格数
  getReason: '/mom/ipcEx/inspect/listByProcedureId', // 获取不合格原因
  // editReport:'/mom/product/report/getInfo',
  updateReport: '/mom/ipcEx/product/reportNew', // 工单报工
  inspectReport: '/mom/inspect/inspecresult/saveInspectResult',
  getInspectInfo: '/mom/inspect/inspecschema/listSchemaByCateAndPro',
  /* 生产排程 */
  getGanttList: '/mom/product/schedule/listScheData',
  toWorkSchedule: '/mom/product/plan/toWork', //确认保存排程信息
  listByOrderOrPlan: '/mom/product/work/listByOrderOrPlan',
  getScheData: '/mom/product/work/listByMaterialOrTechline',
  getRouteList: '/mom/basic/techline/getTechlineByMaterialId',//根据物料获取工艺路线

  /* 联合排程 */
  getModuleList: '/mom/mould/mould/listAll',
  getUnionList: '/mom/product/plan/listByMouldId',
  toUnionWork: '/mom/product/plan/toUnionWork',
  perUnionWork: '/mom/product/plan/preUnionWork',

  /* 工单管理 */
  getWorkList: '/mom/product/work/list',
  saveWork: '/mom/product/work/save',
  removeWork: '/mom/product/work/remove',
  reportWork: '/mom/product/work/report',
  listProcedure: '/mom/basic/procedure/getInfo',
  openWork: '/mom/lk/work/openWork', //开启工单
  importSn: '/mom/lk/work/importSn', //导入SN
  getWorkListNew: '/mom/lk/work/workList',
  getModelList: '/mom/pile/model', //电桩型号，不分页


  generateWorkId: '/mom/product/work/generateWorkId',
  proceduresByCate: '/mom/basic/techline/getProcedureByMaterialId',
  deviceByProcedure: '/mom/basic/techline/getDeviceByMaterialIdAndProcedureId',
  editWorkInfo: '/mom/product/work/getInfo',

  toRecall: '/mom/product/work/toRecall',
  toCompleteWork: '/mom/product/work/toComplete',
  toComfirm: '/mom/product/work/toConfirm',

  // 工单操作的质检：
  getIsInspect: '/mom/device/configTask/lastInspect',

  //计件报表
  queryWorkPrice: '/mom/work/price/queryWorkPrice',
  listForignPrice: '/mom/work/price/listForignPrice',
  listPrice: '/mom/work/price/listPrice',


  /* 生产分析 */
  getProductStatic: '/mom/product/report/producStatic',
  getMonthPlanCount: '/mom/product/analy/getMonthPlanCount',
  getMonthWorkCount: '/mom/product/analy/getMonthWorkCount',
  getDayWorkCont: '/mom/product/analy/getDayWorkCount',
  getListWork: '/mom/product/work/listWork',
  getDaySuccessCount: '/mom/product/analy/getDaySuccessCount',
  getReportCountByDate: '/mom/product/analy/getReportCountByDate',

  // 产量统计
  output: '/mom/product/analy/getOutputByProcedure',
  getOutputList: '/mom/plan/statistics/list',           //获取列表
  getOutputDetail:'/mom//plan/statistics/detail',       //获取详情

  /** 执行情况 */
  getWorkshopList: '/mom/basic/workshop/getInfo',
  getExecutionData: '/mom/product/work/executeDetail',
  // 报工记录
  getReportRecord: '/mom/product/report/list',

  //获取所所有制造订单的接口
  allPlanNo: '/mom/preSche/allPlanNo',
}

/* 测试接口的数据 */
export function gets(type) {
  return async function (data, options) {
    return await get(requestList[type], data, options);
  }
}
export function posts(type) {
  return async function (data, options) {
    return await put(requestList[type], data, options);
  }
}
// test
// export async function deviceByProcedure(data,options){
//   return await get(data,options);
// }