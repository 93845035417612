import LoadingComponent from '../components/ComponentLoading';
import Loadable from 'react-loadable';

const HomeRouter = Loadable({
  loader: () => import('./HomeRouter'),
  loading: LoadingComponent
});
const LoginPage = Loadable({
  loader: () => import('layouts/LoginPage'),
  loading: LoadingComponent
});
// 图文管理
const AliIot = Loadable({
  loader: () => import('layouts/HomeLayout/AliIot'),
  loading: LoadingComponent
});

export {
  HomeRouter,
  LoginPage,
  AliIot
};