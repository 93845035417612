/**
 * @description 通用配置store,包括了表格配置title设置窗口、新增编辑窗口两重交互
 */

import React from 'react';
import { observable, action, } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/settings';
import { message, Tag } from 'antd';
import { get,omit } from 'lodash';
import moment from 'moment';

class SettingConfig {
	/* 配置弹窗内容 */
	@observable isLoading = false;
	@observable titleList = [];
	@observable tableData = [];
	@observable tablePage = { pageIndex: 1, pageSize: 10 };
	@observable column = [];
	@observable columnList = [];
	@observable columnRelativeList = [];

	@observable operateType = '';
	@observable pageKey = '';
	@observable id = '';
	@observable formData = {};

	@observable isShowDialog = false;
	@observable isShowSettingDialog = false;

  /* 设置弹窗字段 */
	@observable showList = []; //显示字段列表
	@observable showListStore = []; //	存储的全部显示字段
	@observable hiddenList = []; //隐藏字段列表
	@observable hiddenListStore = []; //存储的全部隐藏字段
	@observable showSearchList = []; //搜索的显示字段列表
	@observable hiddenSearchList = []; //搜索的隐藏字段列表

	//获取表格数据
	@action.bound async getData({url, params}) {
		this.isLoading = true;
		try {
			let res = await services.getRequest(url, {
        ...omit(this.tablePage,'total'),
				...params,
			});
			this.isLoading = false;
			if (isDataExist(res)) {
				if(params.type === 'preOrderSche'){
					this.tableData = get(res,'data.data')
				}else{
					this.tableData = get(res, 'data.data.items');
					this.tablePage = get(res, 'data.data.page');
				}
				this.titleList = get(res, 'data.dataExt.title');
			}
			this.setColumns()
			return res.data.data.items
		} catch (error) {
			console.log(error);
		}
	}
	@action.bound setColumns(){
		let columns = []
		for (const item of this.titleList) {
			let key = Object.keys(item)[0],value = item[key];
			switch( key ){
				case "create_time":
        case "update_time":
          columns.push({
						title: value,
            dataIndex: key,
            key: key,
            width: 200,
            ellipsis: true,
            render: (value, option, index) =>
              moment(value).format("YYYY-MM-DD HH:mm:ss") || "-"
					})
					break;
				case "status":
					columns.push(
						{
							title: value,
							dataIndex: key,
							key: key,
							width: 200,
							ellipsis: true,
							render: (value,) => {
								return Number(value) === 1 ? (
									<Tag color="#32C5FF">录入</Tag>
								) : Number(value) === 2 && <Tag color="#FFA200">补充</Tag> 
							}
						}
					);
					break;
			  default:
          columns.push(
						{
							title: value,
							dataIndex: key,
							key: key,
							width: 200,
							ellipsis: true,
							render: (value, option, index) => value || "-"
						}
					);
					break;
			}
			this.column = columns
		}
	}
  
	//打开/关闭模态框
	@action.bound async modalChange(type, record) {
		// type：0-关闭，1-新增，2-编辑
		this.operateType = type;
		this.id = record && record.id;
		if (type === 2) {
			// 编辑
			this.pageKey = 'edit';
			await this.getOneDetail({ id: Number(record.id), pageKey: 'edit' }).then(
				async () => {
					await this.getAllDefectDefinitionData();
				}
			);
		} else if (type === 1) {
			// 新增
			this.pageKey = 'add';
			const sendData = { mark: 'process', pageKey: 'add' };
			await this.getColumnList(sendData).then(async () => {
				await this.getAllDefectDefinitionData();
			});
		} else {
			this.formData = {};
		}
		this.isShowDialog = !this.isShowDialog;
	}
	// 编辑物料
	@action.bound async getOneDetail(params) {
		this.isLoading = true;
		const url = services.requestUrl.getOneDetailProcess;
		try {
			let res = await services.getRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.formData = get(res, 'data.data');
				let formData = get(res, 'data.data');
				let columnRelativeList = {};
				formData.forEach(async (formItem) => {
					let item = formItem.pagePropertyDo;
					if (item.fieldType === '关联关系') {
						let result = await services.getRequest(
							`/ge${item.relativeUrl}`
						);
						if (isDataExist(result)) {
							columnRelativeList = {
								...columnRelativeList,
								[item.fieldKey]: result.data && result.data.data,
							};
							this.columnRelativeList = columnRelativeList;
						}
					}
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 获取表头
	@action async getColumnList(params) {
		this.isLoading = true;
		try {
			let res = await services.getRequest(
				services.requestUrl.getTitleListProcess,
				params
			);
			if (isDataExist(res)) {
				this.columnList = get(res, 'data.data');
				let columnList = get(res, 'data.data');
				let columnRelativeList = {};
				columnList.forEach(async (columnItem) => {
					let item = columnItem.pagePropertyDo;
					if (item.fieldType === '关联关系') {
						let result = await services.getRequest(
							`/ge${item.relativeUrl}`
						);
						if (isDataExist(result)) {
							columnRelativeList = {
								...columnRelativeList,
								[item.fieldKey]: result.data && result.data.data,
							};
							this.columnRelativeList = columnRelativeList;
						}
					}
				});
			}
			this.isLoading = false;
		} catch (error) {
			console.log(error);
		}
	}
	// 删除
	@action async deleteOneData(params) {
		try {
			let url = services.requestUrl.deleteProcess;
			let res = await services.putRequest(url, params);
			if (isDataExist(res)) {
				message.info('删除成功');
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 新增
	@action async addNewData(params) {
		this.isLoading = true;
		try {
			let res = await services.putRequest(
				services.requestUrl.addNewData,
				params
			);
			if (isDataExist(res)) {
				this.getData(this.tablePage);
				message.success('添加成功');
			} else {
				message.warn('添加失败');
			}
			this.isLoading = false;
		} catch (error) {
			console.log(error);
		}
	}
	// 编辑
	@action async editOneData(params) {
		this.isLoading = true;
		try {
			let res = await services.putRequest(
				services.requestUrl.editOneData,
				params
			);
			if (isDataExist(res)) {
				this.getData(this.tablePage);
				message.success('保存成功');
			} else {
				message.warn('保存失败');
			}
			this.isLoading = false;
		} catch (error) {
			console.log(error);
		}
	}

	// 保存前先清除表格已有数据
	@action clearTableData = () => {
		this.tableData = [];
		this.titleList = [];
		this.tablePage = { pageIndex: 1, pageSize: 10 };
	}; 
	// 保存设置
	@action.bound async saveSettingChange(params) {
		this.isLoading = true;
		const url = services.requestUrl.saveSetting;
		try {
			let res = await services.putRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 获得显隐字段list
	@action.bound async getShowHiddenList(params) {
		this.isLoading = true;
		const url = services.requestUrl.showAndHideProperties;
		try {
			let res = await services.getRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.showList = get(res, 'data.data.showList');
				this.hiddenList = get(res, 'data.data.hiddenList');
				this.showListStore = get(res, 'data.data.showList');
        this.hiddenListStore = get(res, 'data.data.hiddenList');

			}
		} catch (error) {
			console.log(error);
		}
	}
	// 搜索显隐字段list
	@action.bound async searchShowHiddenList(params, isShow) {
		this.isLoading = true;
		const url = services.requestUrl.searchProperties;
		try {
			let res = await services.getRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				if (isShow === 'show') {
					this.showSearchList = get(res, 'data.data');
				} else if (isShow === 'hidden') {
					this.hiddenSearchList = get(res, 'data.data');
				}
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 关闭搜索
	@action closeSetting() {
		this.isShowSettingDialog = false;
	}
	// 过滤字段：
	@action filterSearch(searchList, allList) {
		let tempList = [];
		if (searchList.length === 0) {
			//没有与之匹配的数据
			console.log('0');
			return [];
		} else {
			// 有匹配的数据，并且在展示的list里面
			searchList.map((item) => {
				let resultList = allList.map((item2) => {
					return item2.id === item.id;
				});
				console.log('resultList', resultList);
				return resultList.indexOf(true) > -1 ? tempList.push(item) : null;
			});
			console.log('1');
			return tempList;
		}
	}

	/* 搜索可配置 */
	// 获取搜索条件
	@observable searchData=[]
	@action.bound async getSearchItems(params){
		this.searchData = []
		this.isLoading = true
		try{
			let res =await services.getRequest(services.requestUrl.getSearchItem,params)
			this.searchData = get(res, 'data.data')
		}catch(error){console.log(error)}
		this.isLoading = false
	}

	// 清除数据
	@action async clearAllData() {
		this.isLoading = false;
		this.tableData = [];
		this.titleList = [];
		this.tablePage = { pageIndex: 1, pageSize: 10 };
		this.column = [];
		this.operateType = '';
		this.pageKey = '';
		this.id = '';
		this.formData = {};
		this.isShowDialog = false;
		this.columnRelativeList = [];
		this.columnList = [];
		this.dataList = [];
		this.showList = [];
		this.showListStore = [];
		this.hiddenList = [];
		this.hiddenListStore = [];
		this.showSearchList = [];
		this.hiddenSearchList = [];
		this.searchData = []
  }

}

let SettingConfigStore = new SettingConfig();
export default SettingConfigStore;
