/*
 * @Author: your name
 * @Date: 2021-04-09 13:55:34
 * @LastEditTime: 2021-04-17 11:45:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \YunQiMOMFrontPC\src\stores\MaintainStore.js
 */
/**
 * @description 通用配置store,包括了表格配置title
 */
import { observable, action, } from "mobx";
import { isDataExist } from "Utils/dataTools";
import * as services from '../services/device';

class Maintain {
    @observable isLoading = false
    @observable tablePage = { pageIndex: 1, pageSize: 10 }
    @observable maintainList = []
    @observable modalVisible = false
    @observable pageKey = ''
    @observable formData = {}
    @observable isShowDialog = false
    @observable tab = '1'

    //打开/关闭模态框
    @action.bound modalChange = (type, record) => {
        if(type ===1){
            this.pageKey = "edit";
            this.formData = record
        }else{
            this.pageKey = "add";
            this.formData = {}
        }
        this.modalVisible = !this.modalVisible
    }

    /* 获取所有维修列表 */
    @action.bound async getMatainList(faultType, params) {
        /* 获取列表 */
        this.isLoading = true;
        try {
            let res = await services.gets('getMatainList')({
                faultType: faultType,
                ...params
            });
            this.isLoading = false;
            if (isDataExist(res)) {
                this.maintainList = res.data.data.records;
                this.tablePage.pageIndex = res.data.data.current
                this.tablePage.pageSize = res.data.data.size
                this.tablePage.total = res.data.data.total
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 保存 */
    @action.bound async saveMaintainList(faultType, params) {
        try {
            let res = await services.posts('saveMaintainList')({
                faultType: faultType,
                ...params
            });
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 删除 */
    @action.bound async deleteMaintainList(faultType, params) {
        try {
            let res = await services.posts('deleteMaintainList')({
                faultType: faultType,
                ...params
            });
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error);
        }
    }

    @action clearData() {
        this.isLoading = false
        this.tablePage = { pageIndex: 1, pageSize: 10 }
        this.maintainList = []
        this.modalVisible = false
        this.pageKey = ''
        this.formData = {}
        this.isShowDialog = false
        this.tab = '1'
    }
}

let MaintainStore = new Maintain();
export default MaintainStore;
