/**
 * @description 通用配置store,包括了表格配置title
 */
import { observable, action, toJS, } from "mobx";
import { isDataExist } from "Utils/dataTools";
import * as services from "../services/basic";
import { message, } from "antd";
import { get } from 'lodash'

class Attendence {
  // constructor(){
  //   this.settings = SettingStore
  // }
  @observable isLoading = false;
  @observable tableData = [];
  @observable tablePage = { pageIndex: 1, pageSize: 10 };
  @observable pageKey = '';
  @observable id = '';
  @observable formData = {};
  @observable isShowDialog = false;
  @observable modalVisible = false;
  @observable attendence = [];
  @observable attendenceId = '';

  /* 配置弹窗内容 */
  @observable fieldParams = {}
  @observable columnList = []
  @observable columnRelativeList = []
  /* 列表内容 */
  @observable selectedRowKeys = []

  @observable deleteTag = 0


  //获取所有班次
  @action.bound async getAllAttendence(params) {
    this.isLoading = true
    try {
      let res = await services.getRequest(services.requestUrl.getAllAttendenceName, params)
      this.isLoading = false
      if (isDataExist(res)) {
        this.attendence = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  /* 获取数据信息 */
  @action.bound async getData(params) {

    this.isLoading = true;
    try {
      let res = await services.getRequest(services.requestUrl.getAttendenceData, {
        ...this.tablePage,
        ...params,
      })

      this.isLoading = false
      if (isDataExist(res)) {
        this.tablePage = res.data.data.page
        this.tableData = res.data.data.items
      }
    } catch (error) {
      console.log(error)

    }
  }

  //打开/关闭模态框
  @action.bound modalChange = (type, record) => {
    this.id = record && record.id;
    if (type === 1) {
      let newRecord = {};
      newRecord.id = record.id;
      newRecord.attendenceKind = record.attendenceKind;
      newRecord.attendenceName = record.attendenceName;
      newRecord.attendenceTime = record.attendenceTime;
      newRecord.description = record.description;
      newRecord.creator = record.creator;
      newRecord.createTime = record.createTime;
      newRecord.updater = record.updater;
      newRecord.updateTime = record.updateTime;
      newRecord.tenantId = record.tenantId;
      //编辑
      this.pageKey = "edit";
      if (record.attendenceTime === '') {
        this.formData = record
      } else {
        let attendenceTimeAll = record.attendenceTime
        let eachList = []

        //多个时间段
        if (attendenceTimeAll.indexOf(';') >= 0) {
          let attendenceTimeS = attendenceTimeAll.split(';');
          for (var i = 0; i < attendenceTimeS.length; i++) {
            let attendenceTimeEach = attendenceTimeS[i].split('-');
            let each = {};
            each.front = attendenceTimeEach[0]
            each.back = attendenceTimeEach[1]
            eachList.push(each);
          }
        }
        //一个时间段
        else {
          var attendenceTimeEach1 = attendenceTimeAll.split('-');
          var each1 = {};
          each1.front = attendenceTimeEach1[0]
          each1.back = attendenceTimeEach1[1]
          eachList.push(each1);
        }
        newRecord.attendenceTime = eachList;
        this.formData = newRecord
      }
    }
    else if (type === 0) {
      this.pageKey = "add";
      this.formData = {}
    }
    this.modalVisible = !this.modalVisible
  }

  //新增班次
  @action.bound async saveAttendence(params) {
    this.isLoading = true;
    try {
      let res = await services.putRequest(services.requestUrl.saveAttendence, params)
      this.isLoading = false
      if (isDataExist(res)) {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  //编辑班次
  @action.bound async editAttendence(params) {
    this.isLoading = true;
    try {
      let res = await services.putRequest(services.requestUrl.editAttendence, params)
      this.isLoading = false
      if (isDataExist(res)) {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  //删除班次
  @action.bound async deleteAttendence(params) {
    this.isLoading = true
    try {
      let res = await services.putRequest(services.requestUrl.removeAttendence, params)
      this.isLoading = false
      if (isDataExist(res)) {
        if(res.code === 0){
          this.deleteTag = 1
        }
        return res
      }else{
        this.deleteTag = 0
      }
    } catch (error) {
      console.log(error)
    }
  }
  @action clearData() {
    this.attendence = [];
    this.isLoading = false;
    this.tableData = []
    this.tablePage = { pageIndex: 1, pageSize: 10 };
    this.pageKey = '';
    this.id = '';
    this.attendenceId = '';
    this.formData = {};
    this.isShowDialog = false;
  }

  // /* 获取显隐list */
  // @action.bound getShowHiddenList(params){
  //   this.settings.isShowSettingDialog = !this.settings.isShowSettingDialog
  //   this.settings.getShowHiddenList(params)
  // }


  // @action.bound modalChange = async (tag, type, record) => {
  //   this.id = record && record.id;
  //   if (tag === 2) {
  //     // 编辑
  //     this.pageKey = "edit";
  //     const sendData = { type, optionalKey: "edit" };
  //     await this.getColumnList(sendData);
  //     this.id=record.id
  //     this.formData = this.columnList.map((item)=>{
  //       // 自定义字段在保存的时候，要单独放在extInfo对象里面，这里是针对自定义字段的key加了前缀，用来在表单提交的时候区分自定义字段
  //       if(item.fieldAttr === 3){
  //         return {...item,fieldKey:`extInfo_${item.fieldKey}`,value:record[item.fieldKey]}
  //       }else{
  //         return {...item,value:record[item.fieldKey]}
  //       }
  //     })
  //     console.log('this.formData',this.formData)
  //   } else if (tag === 1) {
  //     // 新增
  //     this.pageKey = "add";
  //     const sendData = { type, optionalKey: "add" };
  //     await this.getColumnList(sendData);
  //     this.columnList = this.columnList.map((item)=>{
  //       if(item.fieldAttr === 3){
  //         return {...item,fieldKey:`extInfo_${item.fieldKey}`}
  //       }else{
  //         return {...item}
  //       }
  //     })
  //     this.formData = {};
  //     console.log('this.columnList',this.columnList)
  //   } else {
  //     this.formData = {};
  //   }
  //   this.modalVisible = !this.modalVisible;
  // };

  // @action async getColumnList(params) {
  //   try {
  //     let res = await services.getRequest(
  //       services.requestUrl.getMarkByProperties,
  //       params
  //     );
  //     if (isDataExist(res)) {
  //       let columnList = get(res, "data.data");
  //       let columnRelativeList = {};
  //       columnList.forEach(async item => {
  //         if (item.fieldType === "关联关系") {
  //           let result = await services.getRequest(
  //             `${item.relativeUrl}`
  //           );
  //           if(isDataExist(result)){
  //             columnRelativeList = {
  //               ...columnRelativeList,
  //               [item.fieldKey]: result.data&&result.data.data
  //             };
  //             this.columnRelativeList = columnRelativeList;
  //           }
  //         }
  //       });
  //       this.columnList = get(res, "data.data");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }


  // @action.bound async onSyncData(params){
  //   try {
  //     let res = await services.getRequest(services.requestUrl.onSyncData,params)
  //     console.log(res)
  //     console.log('同步信息')
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }


}

let AttendenceStore = new Attendence();
export default AttendenceStore;
