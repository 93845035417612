import { observable, action } from 'mobx';
import * as services from '../services/device';
import { isDataExist } from 'utils/dataTools';
import { message, Modal } from 'antd';
import { omit, isEmpty } from 'lodash'
class Workorder {
    @observable isLoading = false;
    @observable tableData = {}
    @observable deviceTypeList = []
    @observable deviceList = []
    @observable workshopList = []; //车间列表

    /**
     * 获取设备类型
     * @param {Object} params 
     */
    @action.bound async getDeviceTypeList(params) {
        this.deviceTypeList = []
        try {
            let res = await services.gets('getDevice')(params)
            if (isDataExist) {
                this.deviceTypeList = res.data.data
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * 根据设备类型获取设备
     * @param {Object} params
     * @param {Number} params.deviceTypeId 设备类型id 
     */
    @action.bound async getDeviceList(params) {
        this.deviceList = []
        try {
            let res = await services.gets('getDeviceByType')(params)
            if (isDataExist(res)) {
                this.deviceList = res.data.data
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * 获取车间选择列表
     */
    @action.bound async getWorkShopList(params) {
        this.workshopList = []
        try {
            let res = await services.gets('getWorkshopList')(params)
            if (isDataExist(res)) {
                this.workshopList = res.data.data
            }
        }
        catch (error) { }
    }
    /**
     * 获取维保分析
     * @param {Object} params
     * @param {Number} params.deviceTypeId 设备类型id
     * @param {Number} params.deviceId 设备id
     * @param {Number} params.type 维保类型
     * @param {String} params.startDate 开始时间
     * @param {String} params.endDate 结束时间
     */
    @action.bound async getMaintainAnalysisData(params) {
        this.tableData = {}
        this.isLoading = true
        try {
            let res = await services.gets('getMaintainAnalysisData')(params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = {
                    tableData: res.data.data.configTimelyList,
                    pieData: [{
                        item: '及时处理',
                        count: res.data.data.timely,
                    },
                    {
                        item: '超时处理',
                        count: res.data.data.timeOut,
                    }]
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }



    /* --------------异常统计 */
    @observable numberData = {} //折线图
    @observable abnormalData = [] //饼图、柱状图
    @observable reasonData = [] //饼图

    /**
     * 异常统计情况折线图
     * @param {Object} params
     * @param {Number} params.deviceTypeId 设备类型异常
     * @param {String} params.startDate 开始时间
     * @param {String} params.endDate 结束时间
     */
    @action.bound async getAbnormalLineAnalyse(params) {
        this.numberData = []
        this.isLoading = true
        try {
            let res = await services.gets('getAbnormalLineAnalyse')(params)
            this.isLoading = false
            if (isDataExist(res)) {
                let data = res.data.data
                let numberData = []
                data.map(item => {
                    item.details.length && item.details.map( x => {
                        numberData.push({
                            device: item.deviceName,
                            number: x.number,
                            type: x.abnormal,
                        })
                    })
                })
                this.numberData = numberData
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * 异常占比饼图
     * @param {Object} params
     * @param {Number} params.deviceTypeId 设备类型异常
     * @param {String} params.startDate 开始时间
     * @param {String} params.endDate 结束时间
     */
    @action.bound async getAbnormalAnalyse(params) {
        this.NumberData = {}
        this.isLoading = true
        try {
            let res = await services.gets('getAbnormalAnalyse')(params)
            this.isLoading = false
            if (isDataExist(res)) {
                let data = res.data.data || [];
                let intervalData = []
                let pieData = []
                data.map(item => {
                    intervalData.length < 5 && intervalData.push({
                        item: item.name,
                        count: item.number,
                    })
                    pieData.push({
                        item: item.name,
                        count: item.rate,
                    })
                })
                this.abnormalData = { intervalData, pieData }
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * 不合格项占比
     * @param {Object} params
     * @param {Number} params.deviceTypeId 设备类型异常 
     * @param {String} params.startDate 开始时间
     * @param {String} params.endDate 结束时间
     */
    @action.bound async getAbnormalReasonAnalyse(params) {
        this.reasonData = []
        this.isLoading = true
        try {
            let res = await services.gets('getAbnormalReasonAnalyse')(params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.reasonData = res.data.data
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * 清空数据
     */
    @action clearData() {
        this.isLoading = false;
        this.tableData = {}
        this.deviceTypeList = []
        this.deviceList = []
        this.numberData = []
        this.abnormalData = {}
        this.reasonData = []
        this.workshopList = [];
    }
}

let WorkorderStore = new Workorder();
export default WorkorderStore;
