import { get,put,post } from '../utils/request';

export const requestUrl = {
  /* 计划搜索 */
  getPlanAll:'/plan/listByPage',
  getBomAll:'/basic/material/listByPage',
  getBomByPlan:'/basic/material/listByPage', //根据选择的生产计划选择对应的制造bom列表
  listByPage:'/plan/listByPage', //预测排程的数据
  listByPage2:'/advancePlan/listByPage', //生产排程的数据
  getAllMaterial:'/basic/material/getInfo',
  getAllPlanNo:'/plan/allSaleOrder',
  allPlanNo:'/plan/allPlanNo', //获取所所有制造订单的接口


  /* 订单展开 */
  postScheExt:'/plan/preSche',
  listByOrder:'/preSche/orderlist', //查询展开的订单
  listByOrderAdvan:'/advancePlan/advance',

  /* 生产流转卡 */
  getTransferData: '/advanceSche/workInfo',
  

  /* 计划报工 */
  toReportByProcess:'/planWorkReport/report',
  toReportByOrder:'/planWorkReport/finish',
  getReportDetail:'/planWorkReport/workInfo',
  afterReportInfos:'/planWorkReport/workDetailInfo', //上报确定之后，获取历史记录

  /* 扫码维修 */
  saveMaintain:'/maintain/save', // 扫码维修
  
  
  /* 排程信息 */
  saveSche:'/work/saveSche',
  addPreScheInfo: "/plan/save", //新增
  addAdvancedScheInfo: "/advancePlan/save", //新增
  deletePreItem:'/plan/remove',
  deleteAdvancedItem:'/advancePlan/remove',

  /* 获取排程信息 */
  getMarkByProperties:"/basic/config/getProperties",
  getDataById: "/work/workDataDo/getMaterialById", // 单条详情


  /* 获取负荷数据 */
  getLoadData:'/sourceLoading/sourceLoadingList',
  getLoadOrder:'/sourceLoading/planList',
  getResourceList:'/basic/device/getInfoByPattern',
  getResourceAll:'/basic/device/getInfo',
  getResourceGroup:'/basic/deviceGroup/getDeviceGroupInfo',
  saveChangeResWithOrder:'/sourceLoading/changeResource', //修改资源绑定订单
  deleteLoadItem:'/basic/deviceGroup/remove',
  addResGroup:'/basic/deviceGroup/save',
  editResGroup:'/basic/deviceGroup/edit',
  saveResGroupByDeviceId:'/basic/deviceGroup/editWithId', //单个资源页面修改资源组所属
  transferTarget:'/basic/deviceGroup/getDeviceByDeviceId', //查询设备组的设备所属

  getHistoryData:'/sourceLoading/sourceLoadingCharts',

  /* 额定产能参数 */
  getLimit: '/basic/param/getData', //查询
  saveLimit: '/basic/param/save', //保存
  

  /* 用户信息获取 */
  getUseInfo:'/uaa/user/getUserByUserName',
  
  preSycn:'/plan/sycn',//预测排程页面同步信息
  advancedSycn:'/advanceSche/sycn',//高级排程页面同步信息


  /* 完工明细 */
  getFinishData: '/preSche/getDateByPage',
  saveFixed: '/preSche/editDateById',

  /* 计划监控 */
  getProjectAll:'/monitor/allProject', //项目查询条件
  getWorkNoAll:'/monitor/allPlan', //工单查询条件
  monitorData:'/monitor/project',
  monitorByProject: '/monitor/plan',
  monitorByPlan: '/monitor/work',


}
/* 测试接口的数据 */
export async function getRequest(url, data, options) {
  url = url.indexOf('mock') > -1 ? url : url.indexOf('uaa') > -1 ? url :`/ge${url}`
  return await get(url, data, options);
}
/* 测试接口的数据 */
export async function postRequest(url, data, options) {
  url = url.indexOf('mock') > -1 ? url : url.indexOf('uaa') > -1 ? url : `/ge${url}`
  return await post(url, data, options);
}
/* 测试接口的数据 */
export async function putRequest(url, data, options) {
  url = url.indexOf('mock') > -1 ? url : url.indexOf('uaa') > -1 ? url : `/ge${url}`
  return await put(url, data, options);
}