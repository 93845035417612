import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';

class Message {
    @observable isLoading = false;
    @observable tableData = [];
    @observable PageInfo = { pageIndex: 1, pageSize: 10 };

    //获取表格数据
    @action.bound async getData(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.message, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
}

let MessageStore = new Message();
export default MessageStore;