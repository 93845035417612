import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/retro';
import { omit, isEmpty } from 'lodash'

class Inspect {
	@observable isLoading = false;
	@observable loading = false
	@observable planNoList = []
	@observable orgChartDs = {}
	@observable planNo = ''
	@observable procedureList = [{id: 1,name: '工序1'}, {id: 2,name: '工序2'}, {id: 3,name: '工序3'}]
	@observable treeNodeDs = []
	@observable pageInfo = { pageIndex: 1, pageSize: 10 }
	
	/* 已查询叶子下的详情节点 --- 根据对应的操作进入不同的详情 */
	@observable type = ''  // device 设备工艺参数 inspection 质检记录
	@observable infos = []
	@observable infosPageInfo = { pageIndex: 1, pageSize: 10 }
	@observable currentRecord = {}
	@observable resultDetail = []
	@observable resultNum = ''
	@observable expandLoading = false
	@observable detailVisible = false
	@observable detailLoading = false

	/**
	 * 根据物料框编码获取计划号
	 * @param {Object} params frameCode 物料框编码
	 */
	@action async getPlanNoList(params) {
		this.planNoList = []
		try {
			let res = await services.getRequest(services.requestUrl.getPlanNoList, params)
			if (isDataExist(res)) {
				this.planNoList = res.data.data
			}
		} catch (error) {
			console.log(error)
		}
	}

	/**
	 * 根据计划号查询Bom结构
	 * @param {Object} params planNo 计划号 
	 */
	@action async getTreeData(params) {
		this.isLoading = true;
		this.orgChartDs = {}
		try {
			let res = await services.getRequest(services.requestUrl.getTreeData, {
				planNo: this.planNo,
				...params
			});
			this.isLoading = false;
			if (isDataExist(res)) {
				let data = res.data.data
				let orgChartDs = {}
				if (!isEmpty(data)) {
					orgChartDs = {
						name: '物料编码：' + data.materialCode,
						title: "<div class='org-title'>物料名称：" + data.materialName + "</div>",
						materialId: data.materialId,
						children: []
					}
				}
				if (!isEmpty(data.reviewDTOList)) {
					orgChartDs.children = this.setChildren(data.reviewDTOList)
				}
				this.orgChartDs = orgChartDs
			}
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * Bom结构子节点递归
	 * @param {Object}} data 子节点数据
	 */
	setChildren = (data) => {
		let array = []
		for (let item of data) {
			let current = {
				name: '物料编码：' + item.materialCode,
				title: "<div class='org-title'>物料名称：" + item.materialName + "</div>",
				materialId: item.materialId,
				children: []
			}
			if (!isEmpty(item.reviewDTOList)) {
				current.children = this.setChildren(item.reviewDTOList)
			}
			array.push(current)
		}
		return array
	}

	/**
	 * 点击节点获取工序
	 * @param {Object} params materialId 物料id 
	 */
	@action.bound async getProcedureList(params) {
		this.procedureList = []
		try {
			let res = await services.getRequest(services.requestUrl.getProcedureList, params)
			if (isDataExist(res)) {
				this.procedureList = res.data.data
			}
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * 点击节点获取表格数据
	 * @param {Object} params materialId物料id procedureId工序id operateType操作类型
	 */
	@action.bound async getTreeNodeDetail(params) {
		this.loading = true
		this.treeNodeDs = []
		try {
			let res = await services.getRequest(services.requestUrl.getTreeNodeDetail, {
				...omit(this.pageInfo,'total'),
				planNo: this.planNo,
				...params
			})
			this.loading = false
			if (isDataExist(res)) {
				this.treeNodeDs = res.data.data.items
				this.pageInfo = res.data.data.page
			}
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * 
	 * @param {String} type 查询类型 inspect质检结果 device设备详情
	 * @param {Object} params 查询条件 
	 */
	/* 根据工单获取对应信息 */
	@action async getInfoByWorkNo(type, params) {
		this.detailLoading = true
		try {
			// type: device：获取设备工艺参数  inspect：获取检测信息
			let req = type === 'device' ? services.requestUrl.getInspectByWork :
					type === 'inspect' ? services.requestUrl.getInspectByWork : ''
			let res = await services.getRequest(req, {
				...omit(this.infosPageInfo,'total'),
				...params
			})
			this.detailLoading = false
			if (isDataExist(res)) {
				let infos = []
				if(type === 'inspect'){
					this.infos = res.data.data.items;
				    this.infosPageInfo = res.data.data.page;
				}
				else{
					let diff = params.endTime.diff(params.startTime,"days")
					let tempDate=new Date(params.startTime)//把日期字符串转换成日期格式
					for(let i = 0; i<= diff; ++i){
                        let resultDate=new Date((tempDate/1000+86400*i)*1000)//增加n天后的日期
                        let resultDateStr=resultDate.getFullYear()+"-"+(resultDate.getMonth()+1)+"-"+(resultDate.getDate());
						infos.push({
							year: resultDateStr,
							value: Math.round(Math.random()*10000)/100,
							id:i,
							type: '历史工艺参数'
						})
					}
					this.infos = infos
				}
			}
		} catch (error) {
			console.log(error);

		}
	}

	/**
	 * 质检操作详情
	 * @param {Object} params id工单id type之间类型 
	 */
	@action async getResultInfo(params) {
		this.resultDetail = []
		this.resultNum = 0
		this.expandLoading = true
		try {
			let res = await services.getRequest(services.requestUrl.getResultInfo, params);
			this.expandLoading = false
			if (isDataExist(res)) {
				let data = res.data.data,list = [];
				if (!!data.length) {
					this.resultNum = res.data.data[0].infos.length;
				    for(let i=0;i<data.length;i++){
						let item=data[i];
						for(let j=0;j<data[i].infos.length;j++){
							Object.assign(item,{
								...data[i].infos[j],
								['actural'+j] : data[i].infos[j].actural
							})
						}
						list.push(item)
					}
				}
				this.resultDetail = list
				return true;
			}
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	/**
	 * 清除数据
	 */
	@action clearData() {
		this.planNo = ''
		this.orgChartDs = {}
		this.procedureList = []
		this.treeNodeDs = []
		this.pageInfo = { pageIndex: 1, pageSize: 10 }

		this.type = ''
		this.infos = []
		this.infosPageInfo = { pageIndex: 1, pageSize: 10 }
		this.currentRecord = {}
		this.detailVisible = false
	}
}

let InspectStore = new Inspect();
export default InspectStore;
