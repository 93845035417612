/*
 * @Author: your name
 * @Date: 2021-03-09 09:27:56
 * @LastEditTime: 2021-05-19 16:19:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \YunQiMOMFrontPC\src\services\device.js
 */
import { get, put } from '../utils/requestNew';

const requestList = {
  getDevice: '/mom/basic/deviceType/getInfo',
  getDeviceList: '/mom/basic/device/getInfo',
  getDeviceAll: '/mom/device/monitor/listDevice',
  getWorkShopList: '/mom/basic/workshop/getInfo',
  getAllConfig: '/mom/device/config/listAll',
  listDeviceParams:"/mom/device/collectParam/listDeviceParams", //设备参数列表接口
  queryHistory:"/mom/device/collectParam/queryHistoryCollectParam", //历史设备参数接口
  RealTimeParameters:'/mom/device/collectParam/listDeviceParamsNow', //实时参数接口
  ParametricCurve:'/mom/device/collectParam/listDeviceParamsHistory', //设备参数历史曲线
  EquipmentParameters:'/mom/basic/device/getDeviceById', //设备参数
  /* 库 */
  getMaintain: '/mom/device/config/list', // 获取库
  singleRemove: '/mom/device/config/remove',
  saveMaintain: '/mom/device/config/save',
  getServeInfo: '/mom/device/config/getInfo',

  /* 方案 */
  getScheme: '/mom/device/configPath/list',
  batchDelSchema: '/mom/device/configPath/batchRemove',
  delSchema: '/mom/device/configPath/remove',
  saveSchema: '/mom/device/configPath/save',
  schemaInfo: '/mom/device/configPath/getInfo',

  /* 计划 */
  getPlan: '/mom/device/configPlan/list',
  savePlan: '/mom/device/configPlan/save',
  delPlan: '/mom/device/configPlan/remove',
  batchDelPlan: '/mom/device/configPlan/batchRemove',
  getPlanInfo: '/mom/device/configPlan/getInfo',
  toDetail: '/mom/device/configPlan/save',
  getDeviceByType: '/mom/basic/device/listByTypeId',
  getSchemaByType: '/mom/device/configPath/listByTypeId',
  recoverPlan: '/mom/device/configPlan/remove',//回收计划

  /* 操作 */
  getDeviceByOp: '/mom/basic/device/listByType',
  getOperateList: '/mom/device/configTask/list',
  getListByConfigs: '/mom/device/configPath/getDetailInfo', //弹窗内获取列表数据
  saveOperate: '/mom/device/configTask/save', 


  /* 查询 */
  getQuery: '/mom/device/configTask/list',
  getResultInfo: '/mom/device/configTask/getInfo',
  getWorkshopList: '/mom/basic/workshop/getInfo',

  /* ------------设备监控------------- */
  getMonitor: '/mom/device/monitor/list',
  getMonitorDetail: '/mom/device/monitor/getDeviceDetail',
  getMonitorParams: '/mom/device/monitor/listDeviceParamValue',
  getDeviceStatusNumber: '/mom/device/monitor/statusNum',

  /* 设备日志 */
  getDeviceLog: '/mom/device/maintainRecord/listNew',


  /* ------------工具---------------- */
  getMould: '/mom/mould/mould/listAll',

  /* ------------异常统计---------------- */
  getAbnormalLineAnalyse: '/mom/device/analy/abnormalLineAnalyse', //异常统计情况
  getAbnormalAnalyse: '/mom/device/analy/abnormalAnalyse', //异常占比
  getAbnormalReasonAnalyse: '/mom/device/analy/abnormalReasonAnalyse', //不合格项占比

  /* -----------维修报表 --------------- */
  getReportData: '/mom/device/maintain/listAll',
  getDeviceAndGroup: '/mom/basic/device/getAllInfo',
  getReportInfo: '/mom/device/maintain/history',

  /* ---------- 维保分析 ---------- */
  getMaintainAnalysisData: '/mom/device/analy/timelyAnalysis',

  /* ---------- 设备切片 ---------- */
  getDeviceSlice: '/mom/basic/device/deviceSliceAnalyse',
  /**---------- 历史参数 ---------- */
  getDeviceListByWorkshop: '/mom/basic/device/getInfoByWorkshopId',

  /**---------- 设备维修 ---------- */
  getMatainList: '/mom/device/configFault/list',
  saveMaintainList: '/mom/device/configFault/save',
  deleteMaintainList: '/mom/device/configFault/delete',

  /**---------- 设备分析 ---------- */
  getAnalysisDetail: '/mom/device/analy/reliabilityAnalysis',

  /**---------- 设备参数 ---------- */
  getParamsList: '/mom/device/collectParam/list',    //参数列表
  getDeviceTypeId: '/mom/basic/deviceType/listAll',  //查询-设备类型
  getDeviceNameCode: '/mom/basic/device/listAll',    //查询-设备名称编码
  getAllDeviceParamFieldList: '/mom/device/collectParam/getAllDeviceParamFieldList',
  getCurrentDeviceParamFieldList: '/mom/device/collectParam/getCurrentDeviceParamFieldList',
  getCollectParamsByDeviceId: '/mom/device/collectParam/getCollectParamsByDeviceId',
  getUnConfigDeviceList: '/mom/device/collectParam/getUnConfigDeviceList', //新增时获取可选设备
  addParams: '/mom/device/collectParam/save',        //新增/编辑参数
  deleteParams: '/mom/device/collectParam/delete',   //删除参数
  copyParams: '/mom/device/collectParam/copyByCollectParams',  //复制参数
  editEnableStatus: '/mom/device/collectParam/toggleDeviceParamEnableStatus',  //编辑状态
  saveUserTempDeviceCollect: '/mom/device/collectParam/saveUserTempDeviceCollect', //保存用户临时应用的设备参数
  getUserTempDeviceCollect: '/mom/device/collectParam/getUserTempDeviceCollect', //获取用户临时应用的设备参数
  clearUserTempDeviceCollect: '/mom/device/collectParam/cleanUserTempDeviceCollect', //清除用户临时应用的设备参数
}

export function gets(type) {
  return async function (data, options) {
    return await get(requestList[type], data, options);
  }
}
export function posts(type) {
  return async function (data, options) {
    return await put(requestList[type], data, options);
  }
}