
import { observable, action, values } from 'mobx';
import * as services from '../services/production';
import { isDataExist } from 'utils/dataTools';
import { message, Modal } from 'antd';
import { omit, isEmpty } from 'lodash'
class Workorder {
    @observable isLoading = false;
    /* 筛选条件 */
    @observable plan_orderList = [];
    @observable plan_bomList = [];
    @observable report_deviceList = [];
    @observable proceduresList = [];
    @observable planByOrder = {};
    @observable searchOptions = {}
    /* 列表 */
    @observable workSelectKeys = [];
    @observable workList = []
    @observable workPage = { pageIndex: 1, pageSize: 10 }
    @observable deviceByList = []

    /* 弹窗 */
    @observable visible = false
    @observable status = ''
    @observable currentWork = {}
    @observable editInfo = {}

    @observable materialId = ''
    @observable deviceId = ''
    @observable procedureId = ''

    /* 查询条件 */
    @action async getOrderList() {
        this.plan_orderList = []
        try {
            let res = await services.gets('getOrderList')();
            if (isDataExist(res)) {
                this.plan_orderList = res.data.data;
       
            }
           
        } catch (error) {
            console.log(error);
        }
    } 
    
    @action async getBomList(params) {
        this.plan_bomList = []
        try {
            let res = await services.gets('getBomList')(params);
            if (isDataExist(res)) {
                this.plan_bomList = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async generateWorkId() {
        try {
            let res = await services.posts('generateWorkId')();
            if (isDataExist(res)) {
                return res.data.data;
            }
        } catch (error) {
            console.log(error)
        }
    }
    @action async proceduresByCate(params) {
        this.proceduresList = []
        try {
            let res = await services.gets('proceduresByCate')(params)
            if (isDataExist(res)) {
                this.proceduresList = res.data.data;
            }
        } catch (error) {
            console.log(error);

        }
    }
    @action async listProcedure(params) {
        this.proceduresList = []
        try {
            let res = await services.gets('listProcedure')(params)
            if (isDataExist(res)) {
                this.proceduresList = res.data.data;
            }
        } catch (error) {
            console.log(error);

        }
    }
    @action async deviceByProcedure(params) {
        try {
            let res = await services.gets('deviceByProcedure')(params);
            if (isDataExist(res)) {
                this.deviceByList = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getDevice(params) {
        try {
            let res = await services.gets('getDevice')(params);
            if (isDataExist(res)) {
                this.report_deviceList = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    /* 修改对应的数据 */
    @action modifyFormData({ id, index, type, keys }) {
        let materialVOS = this.currentWork.detailList || [];
        let current = type === 'material' ? this.plan_bomList.filter(m => m.id === id)[0] : [];
        if (type === 'add') {
            if (isEmpty(materialVOS[index + 1])) {
                materialVOS.push({
                    id: index + 1,
                    materialId: undefined,
                    planCount: undefined
                })
            }
        } else if (type === 'delete') {
            materialVOS.splice(index, 1)
        } else {
            if (!isEmpty(materialVOS)) {
                switch (type) {
                    case 'material':
                        materialVOS[index] = {
                            ...omit(current, 'id'),
                            materialId: current.id
                        };
                        break;
                    case 'planCount':
                        materialVOS[index].planCount = id;
                        break;
                    default:
                        break;
                }
            } else {
                switch (type) {
                    case 'material':
                        materialVOS.push({
                            ...omit(current, 'id'),
                            materialId: current.id
                        });
                        break;
                    case 'planCount':
                        materialVOS.push({
                            planCount: id
                        });
                        break;
                    default:
                        break;
                }
            }
        }
        this.currentWork = {
            ...this.currentWork,
            detailList: materialVOS
        };
    }
    /**
     * @Description: 生产任务-工单管理 表格数据
     * @param {object} data 查询条件
     * @return {*}
     */
    @action async queryWorkPrice(data = {}) {
        this.isLoading = true;
        this.workList = []
        try {
            let params = {
                ...this.searchOptions,
                ...omit(this.workPage, 'total'),
                ...data
            };
            let res = await services.gets('queryWorkPrice')(params);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.workList = res.data.data.items;
                this.workPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    /* 新增工单信息 */
    @action.bound handleAddWork() {
        this.status = 'add'
        this.visible = true
    }

    /* 批量删除 */
    @action.bound handleDeleteWork() {
        let selectedKeys = this.workSelectKeys;
        Modal.confirm({
            title: '提示',
            content: '确定要删除选中的' + selectedKeys.length + '条数据？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                this.toOperateAll({
                    ids: selectedKeys
                }, 'removeMore');
            }
        })
    }
    /* 保存编辑信息 */
    @action async saveWork(params) {
        try {
            let res = await services.posts('saveWork')(params);
            if (isDataExist(res)) {
                this.getWorkList();
                this.status === 'add' ? message.success('添加成功！') :
                    this.status === 'edit' ? message.success('修改成功！') :
                        message.success('保存成功！');
                return res
            }
        } catch (error) {
            console.log(error);
        }
    }

    @action async toOperateAll(params, type) {
        try {
            let req = type === 'remove' ? 'removeWork' : 'saveWork'
            let res = await services.posts(req)(params)
            if (isDataExist(res)) {
                let msg = type === 'recycle' ? '回收成功' :
                    type === 'toComplete' ? '完结成功' :
                        type === 'toSend' ? '下发成功' : '删除成功';
                message.success(msg)
            }
        } catch (error) {
            console.log(error);
        }
        this.getWorkList();
    }
    /* 完结之前判断工单是否进行末检 */
    @action async getIsInspect(params) {
        try {
            let res = await services.gets('getIsInspect')(params);
            if (isDataExist(res)) {
                let success = false
                if (res.data.data.length > 0) {
                    success = true
                }
                this.getWorkList();
                return success
            }
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * 获取车间选择框
     */
    @observable workshopList = [];
    @action.bound async getWorkshopList(params) {
        this.workshopList = [];
        try {
            let res = await services.gets('getWorkshopList')(params);
            if (isDataExist(res)) {
                this.workshopList = res.data.data;
            }
        }
        catch (error) { }
    }


    /* 获取操作工 */
    @observable workerList = []
    @observable workerId = ''
    @action async getWorker(params) {
        this.rep_loading = false;
        this.workerList = []
        try {
            let res = await services.gets('getWorker')(params);
            this.rep_loading = true;
            if (isDataExist(res)) {
                this.workerList = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    @action changeVisible = (visible) => {
        this.visible = visible;
    }
    @action clearModal() {
        this.status = '';
        this.visible = false;
        this.workerId = '';
        this.currentWork = {}
    }
    @action clearData() {
        this.isLoading = false;
        /* 筛选条件 */
        this.plan_orderList = [];
        this.plan_bomList = [];
        this.report_deviceList = [];
        this.proceduresList = [];
        this.planByOrder = {};
        this.searchOptions = {};
        /* 列表 */
        this.workSelectKeys = [];
        this.workList = []
        this.workPage = { pageIndex: 1, pageSize: 10 }
        this.deviceByList = []

        /* 弹窗 */
        this.visible = false
        this.status = ''
        this.currentWork = {}
        this.editInfo = {}

        this.materialId = ''
        this.deviceId = ''
        this.procedureId = ''
        this.workshopList = [];
    }
}

let WorkorderStore = new Workorder();
export default WorkorderStore;
