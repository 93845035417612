import { observable, action, } from 'mobx'
import * as services from 'services/graphic'
import { isDataExist } from 'utils/dataTools'
import { message } from 'antd'

class Graphic {
  @observable picList = []
  @observable picPage = { pageIndex: 1, pageSize: 10 }
  @observable picLoading = false
  @observable deviceList = []
  @observable procedureList = []
  @observable fileList = []
  @observable formData = {}
  @observable selectData = []; //第一级关联
  @observable secondSelectData = []; //第二级关联

  // 获取表格数据
  @action.bound async getData(params) {
    this.picLoading = true
    try {
      let res = await services.getRequest(services.requestUrl.getPic, params)
      this.picLoading = false
      if (isDataExist(res)) {
        this.picList = res.data.data.items
        this.picPage = res.data.data.page
      }
    } catch (error) {

    }
  }
  /**
   * 获取搜索框/一级关联列表
   * @param {type} type 文档类型 101设备文档 102工艺文档 103质量文档 104工具文档
   * @param {object} params.deviceName 101时设备名
   * @param {number} params.materialName 102/103时物料名
   * @param {number} params.mouldName 104是工具名
   * @param {number} params.pattern 104时工具类型 1模具 2工装
   */
  @action async getRelation(type, params) {
    this.selectData = [];
    try {
      let url = type == 101 ? services.requestUrl.getDevice
        : type == 102 ? services.requestUrl.getMaterial
          : type == 103 ? services.requestUrl.getMaterial
            : services.requestUrl.getTool
      let res = await services.getRequest(url, params)
      if (isDataExist(res)) {
        this.selectData = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 获取二级关联列表
   * @param {type} type 文档类型 102工艺文档 103质量文档
   * @param {object} params
   * @param {number} params.materialId 102时传入物料id
   */
  @action async getSecondRelation(type, params) {
    this.secondSelectData =[];
    try {
      let url = type == 102 ? services.requestUrl.getProcedureByMaterialId
        : services.requestUrl.getProcedure
      let res = await services.getRequest(url, params)
      if (isDataExist(res)) {
        this.secondSelectData = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 删除
  @action.bound async delete(params) {
    try {
      let res = await services.putRequest(services.requestUrl.delete, params)
      if (isDataExist(res)) {
        message.success('删除成功！')
        this.getData({ businessType: params.typeId, ...this.picPage })
      }
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * 添加/修改文档
   * @param {type} type 
   * @param {number} params.businessType 文档类型 101设备文档 102工艺文档 103质量文档 104工具文档 105其他文档
   * @param {number} params.relationId 101时设备id 102时物料id 103时物料id 104时工具id
   * @param {number} params.secondRelationId 102时工序id 103时工序id
   * @param {number} params.id 编辑时数据id
   */
  @action async save(params) {
    try {
      let res = await services.putRequest(services.requestUrl.save, params)
      if (isDataExist(res)) {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
}
let GraphicStore = new Graphic()
export default GraphicStore
