/*
 * @Description: 
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: hhy
 * @LastEditTime: 2021-04-26 15:20:20
 */
import { observable, action, } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { message } from 'antd';

class ToolsLife {
    @observable isLoading = false
    @observable tableList = []
    @observable waresList = []
    @observable waresPage = { pageIndex: 1, pageSize: 10 }
    @observable tablePage = { pageIndex: 1, pageSize: 10 }
    @observable searchOptions = {};

    /* 弹窗 */
    @observable type = 0
    @observable formData = {}
    @observable visible = false
    @observable selectedRowKeys = []
    @observable plantList = []
    @observable toolList = []

    @action.bound async getData(type, params) {
        try {
            this.isLoading = true
            let req = !type ? 1 : 2 /*1：模具，2：工装，3：夹具*/
            let res = await services.getRequest(services.requestUrl.getToolsLife, { 
                ...this.tablePage,
                ...this.searchOptions,
                ...params, pattern: req
            });
            /*查询的四个参数：↑ pageIndex，pageSize，type(选择模具or工装)，name(输入的名字)*/
            this.isLoading = false
            if (isDataExist(res)) {
                this.tablePage = res.data.data.page
                this.tableList = res.data.data.items;
            }
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * @Description: 工具管理-工具寿命管理-刀具
     * @param {object} params
     */
    @action.bound async listKnifeLife(params) {
        try {
            this.isLoading = true
            let res = await services.getRequest(services.requestUrl.listKnifeLife, { 
                ...this.tablePage,
                ...this.searchOptions,
                ...params, pattern: 4
            });
            this.isLoading = false
            if (isDataExist(res)) {
                this.tablePage = res.data.data.page
                this.tableList = res.data.data.items;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @observable knifeTypeList = []; // 刀具类型
	/**
     * @Description: 工具管理-工具定义-刀具类型
     * @param {object} params
     */
	@action.bound async getKnifeTypeList(params) {
		try {
			let res = await services.getRequest(services.requestUrl.getKnifeTypeList, params);
			if (isDataExist(res)) {
				this.knifeTypeList = res.data.data;
			}
		} catch (error) {
			console.log(error);
		}
	}
    @observable current = {}; // 选中刀具查看详情
    /**
     * @Description: 工具管理-工具寿命管理-刀具-详情
     * @param {object} params
     */
     @action.bound async listKnifeDetail(params) {
        try {
            this.isLoading = true
            let res = await services.getRequest(services.requestUrl.listKnifeDetail, { 
                knifeId: this.current.mouldId,
                ...this.tablePage,
                ...this.searchOptions,
                ...params,
            });
            this.isLoading = false
            if (isDataExist(res)) {
                this.tablePage = res.data.data.page
                this.tableList = res.data.data.items;
            }
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * 工具选择框
     * @param {Number} type 0：模具，1：工装
     * @param {Object} params 
     */
    @action async getTool(type,params) {
        this.toolList = []
        try {
            let req = !type ? 1 : 2 /*1：模具，2：工装，3：夹具*/
            let res = await services.getRequest(services.requestUrl.getTool, {...params,pattern: req, type: 2 } )
            if (isDataExist(res)) {
                this.toolList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }

    @action async getReportCount(params) {
        try {
            let res = await services.getRequest(services.requestUrl.getReportCount, params);
            if (isDataExist(res)) {
                let histogramDs = [{
                    name: 'failCount'
                }, {
                    name: 'successCount'
                }];
                histogramDs.map(ele => {
                    res.data.data.map(item => {
                        ele[item.reportTime] = item[ele.name];
                    });
                });
                this.histogramDs = histogramDs;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action clearData() {
        this.isLoading = false
        this.tableList = []
        this.tablePage = { pageIndex: 1, pageSize: 10 }
        this.searchOptions = {}
        this.toolList = [];
        this.knifeTypeList = [];

        /* 弹窗 */
        this.type = 0
        this.formData = {}
        this.visible = false
        this.selectedRowKeys = []
        this.plantList = []
    }
}
let ToolsLifeStore = new ToolsLife();
export default ToolsLifeStore;