import { observable, action,toJS } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import { message } from 'antd';
import * as services from '../services/production';

class PlanReview {
  @observable isLoading = false;
  @observable searchOrderId = '';
  @observable orderList = [];
  @observable orderPageInfo = {};
  
  @action async getOrder(params){
    this.isLoading = true;
    try {
      let res = await services.gets('getOrder')({...this.orderPageInfo,...params});
      this.isLoading = false;
      if(isDataExist(res)){
        this.orderList = res.data.data.items;
        this.orderPageInfo = res.data.data.page;
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action async generatePlan(params){
    try {
      let res = await services.posts('generatePlan')(params);
      if(isDataExist(res)){
        message.success('已生成生产计划');
      }
    } catch (error) {
      console.log(error);
    }
    this.getOrder({
      orderId:this.searchOrderId,
    });
  }
  @action async rejectPlan(params){
    try {
      let res = await services.posts('rejectPlan')(params);
      if(isDataExist(res)){
        return res
      }
    } catch (error) {
      console.log(error);
    }
    this.getOrder({
      orderId:this.searchOrderId,
      pageIndex:1,pageSize:10
    });
  }
  @action async batchReview(params){
    try {
      let res = await services.posts('batchReview')(params);
      if(isDataExist(res)){
        message.success('批量评审通过成功');
      }
    } catch (error) {
      console.log(error);
      
    }
    this.getOrder({
      orderId:this.searchOrderId,
      pageIndex:1,pageSize:10
    });
  }
}
let PlanReviewStore = new PlanReview();
export default PlanReviewStore;
