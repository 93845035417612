
import { observable,action  } from 'mobx';
import * as services from "../services/progressCharts";
import {isDataExist} from "../utils/dataTools";

class IntelAI {
  @observable tag01 = {
    Avail:{
      percent:'97%',
      huan:-33.33,
      tong:27.25
    },
    Perform:{
      percent:'67%',
      huan:-5.21,
      tong:7.56
    },
    Quality:{
      percent:'26%',
      huan:-3.26,
      tong:7.89
    },
    Oee:{
      percent:'22%',
      huan:-13.32,
      tong:1.02
    }
  }
  @observable lineData = []
  @observable lineData1 = []
  @observable lineData2 = []
  @observable chartData1 = []
  @observable chartData2 = []
  // @observable

  @action resetData(){
    this.lineData = []
    this.lineData1 = []
    this.lineData2 = []
    this.chartData1 = []
    this.chartData2 = []
    for(let i = 0;i<12;i++){
      let item = {
        month: `${i+1}`,
        city: '',
        revenue: Math.round(Math.random()*10),
      }
      this.lineData.push(item)
    }
    for(let i = 0;i<12;i++){
      let item = {
        month: `${i+1}`,
        city: '',
        revenue: Math.round(Math.random()*10),
      }
      this.lineData1.push(item)
    }
    for(let i = 0;i<12;i++){
      let item = {
        month: `${i+1}`,
        city: '',
        revenue: Math.round(Math.random()*10),
      }
      this.lineData2.push(item)
    }
    this.chartData1 = [
      {
        year: "等待生产",
        sales: Math.round(Math.random()*500)
      },
      {
        year: "无计划停机",
        sales: Math.round(Math.random()*500)
      },
      {
        year: "设备保养",
        sales: Math.round(Math.random()*500)
      },
      {
        year: "计划维修",
        sales: Math.round(Math.random()*500)
      },
      {
        year: "设备调试",
        sales: Math.round(Math.random()*500)
      },
      {
        year: "紧急维修",
        sales: Math.round(Math.random()*500)
      },
    ]
    this.chartData2 = [
      {
        item: "产品一",
        count: Math.round(Math.random()*10)
      },
      {
        item: "产品二",
        count: Math.round(Math.random()*10)
      },
      {
        item: "产品三",
        count: Math.round(Math.random()*10)
      },
      {
        item: "产品四",
        count: Math.round(Math.random()*10)
      },
      {
        item: "产品五",
        count: Math.round(Math.random()*10)
      }
    ];
  }

  @observable assemblyProgressInfo = []
  /**
   * @Description: 获取总装工单进度(大屏)
   */
  @action async getAssemblyProgress(params) {
    this.isLoading = true;
    try {
      let res = await services.gets('getAssemblyProgress')({
        tenantId: sessionStorage.getItem('tenantId'),
        ...params
      })
      this.isLoading = false;
      if (isDataExist(res)) {
        this.assemblyProgressInfo = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  @observable testProgress = []
  /**
   * @Description: 获取调试工单进度(大屏)
   */
  @action async getTestProgress(params) {
    this.isLoading = true;
    try {
      let res = await services.gets('getTestProgress')({
        tenantId: sessionStorage.getItem('tenantId'),
        ...params
      })
      this.isLoading = false;
      if (isDataExist(res)) {
        this.testProgress = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  @observable workProgressInfo7 = [] //7天工单统计
  @observable workProgressInfo12 = []  //12月工单统计
  /**
   * @Description: 获取总装工单进度(大屏)
   */
  @action async getWorkProgress(params) {
    this.isLoading = true;
    try {
      let res = await services.gets('getWorkProgress')({
        tenantId: sessionStorage.getItem('tenantId'),
        ...params
      })
      this.isLoading = false;
      if (isDataExist(res)) {
        if(params.queryType===1){
          this.workProgressInfo7 = res.data.data
        }else if(params.queryType===2){
          this.workProgressInfo12 = res.data.data
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  @observable allProgressInfo = []
  /**
   * @Description: 获取全产线进度(大屏)
   */
  @action async getAllProgress(params) {
    this.isLoading = true;
    try {
      let res = await services.gets('getAllProgress')({
        tenantId: sessionStorage.getItem('tenantId'),
        ...params
      })
      this.isLoading = false;
      if (isDataExist(res)) {
        this.allProgressInfo = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }
}

let IntelAIStore = new IntelAI();
export default IntelAIStore;