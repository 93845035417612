/* 
* router by zyn on 0509
* Router -- 根节点
* BrowserHistory 建立路由传递文件 当前路由引入方式
* Switch 路由切换组件 Router 路由包裹  Redirect 路由指向
* HomeLayout 最外层Home包裹架子
*/

import React,{PureComponent} from 'react';
import { 
  BrowserRouter as Router,
  // Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { ConfigProvider } from 'antd';

/* 引入组件 */
import { 
  HomeRouter,
  LoginPage,AliIot
} from './home';
import { THEME_LIST } from 'constants/theme_config'

import zhCN from 'antd/lib/locale/zh_CN';

export default class RootRouter extends PureComponent{
  render(){
    return (
      <ConfigProvider locale={zhCN} >
      
    
      <Router >
        <Switch>
          <Route path='/login' component={LoginPage} />
          <Route path='/aliiot' component={AliIot} />
          <Route path='/' component={HomeRouter} />
        </Switch>
      </Router>
      </ConfigProvider>
    );
  }
  componentDidMount(){
    let curTheme = {}
    if(sessionStorage.getItem('ownTheme')){
      for(let theme of THEME_LIST){
        if([theme.color,theme.name].includes(sessionStorage.getItem('ownTheme'))){
          curTheme = theme
        }
      }
    }else{
      curTheme = THEME_LIST[0]
    }
    window.less.modifyVars(
      {
        '@primary-color': curTheme.color,
        '@menu-dark-item-active-bg':curTheme.color,
        '@link-color': curTheme.color,
        '@text-color':curTheme.color,
        '@btn-primary-bg': curTheme.color,
        '@layout-header-background':curTheme.color === '#6236FF' ? 'linear-gradient(137deg, #674dc5 0%, #150f23 100%)' : 
        '#001529',
        '@menu-dark-submenu-bg':'transparent',
      }
      )
      .then(() => { 
        sessionStorage.setItem('ownTheme',curTheme.color)
    })
    .catch(error => {
      console.log(error)
    });
  }
}



