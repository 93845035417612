import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/code';
import { message } from 'antd';
import { omit } from 'lodash';

class Code {
    @observable isLoading= false;
    @observable formData={}; //定义form表单
    @observable selectedRows = []; //条码id
    @observable tableData=[];  //表格数据
    @observable PageInfo={ pageIndex:1,pageSize:10 }; //表格分页
    @observable modalVisible = false; //模态框
    @observable searchData = [];
    @observable codeVisible = false; //查看二维码 模态框
    @observable name = "" ;  //物料框名称
    
    //获取列表
    @action.bound async getData(params) {
      this.isLoading = true
      try {
        let res = await services.getRequest(services.requestUrl.codeList, {
          name:this.name,
          ...omit(this.PageInfo,'total'),
          ...params
        })
          this.isLoading = false
          if (isDataExist(res)) {         
            this.tableData = res.data.data.items
            this.PageInfo = res.data.data.page
          }
      } catch (error) {
          console.log(error)
      }
    }

    //新增
    @action.bound async saveData(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.codeSave, params)
            this.isLoading = false
            if (isDataExist(res)) { 
                return res
            }
        } catch (error) {
            console.log(error)
        }
        this.getData()
      }

    //打开/关闭模态框type=1为编辑，否则是新增
    @action.bound modalChange = (type,record)=>{
        if(type){
            this.formData=record; 
        }else{
            this.formData={};  
        }
        this.modalVisible=!this.modalVisible;
    }

}
let CodeStore = new Code();
export default CodeStore;
  