/*
 * @catalogue: 报表管理store
 * @Description: 请求函数
 * @Author: 黄红燕
 * @Date: 2020-12-23 10:50:46
 * @LastEditors: 黄红燕
 * @LastEditTime: 2020-12-23 14:07:55
 */
import React from 'react';
import { observable, action } from 'mobx';
import * as service from '../services/bigScreen';
import { isDataExist } from 'utils/dataTools';
import { omit } from 'lodash';
class Store {
    /** ---------- 数据集 ---------- */ 
    @observable isLoading = false;
    @observable tableData = []; //表格数据
    @observable pageInfo = { pageIndex: 1, pageSize: 10 }; // 分页信息
    /**
     * @Description: 获取表格数据
     * @param {object} params 
     */
    @action.bound async getBigScreenData(params) {
        this.isLoading = true;
        try {
            let res = await service.gets('getBigScreenData')({
                ...omit(this.pageInfo, 'total'),
                ...params
            });
            if (isDataExist(res)) {
                this.tableData = res.data.data.items;
                this.pageInfo = res.data.data.page;
            }
        }
        catch (error) { }
        this.isLoading = false;
    }
    /**
     * @Description: 清空数据
     */
    @action.bound clearData() {
        this.isLoading = false;
        this.tableData = [];
        this.pageInfo = { pageIndex: 1, pageSize: 10 };
    }

}
let BigScreenStore = new Store();
export default BigScreenStore;

