/*
 * @catalogue: 知识图谱-设备定义-设备类型
 * @Description: 设备类型/设备组列表/设备列表store
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-03-05 11:33:38
 */
import { observable, action, } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { message } from 'antd';
import { omit } from 'lodash'

class DeviceList {
    @observable isLoading = false;
    @observable tableData = [];
    @observable typeData = [];
    @observable selectedRows = [];
    @observable PageInfo = {};
    @observable formData = {};
    @observable attForm = {};//属性列表中form
    @observable modalVisible = false;
    @observable attModalVisible = false;
    @observable entityType = "";//设备类型
    @observable attStatusList = []
    @observable deviceList = []
    @observable groupDevice = []
    @observable ipcList = [] //工控机-下拉框
    @observable workshopList = [] //车间-下拉框
    @observable expandedRowKeys = []//展开的行
    @observable searchOptions = {};

    /* 二维码 */
    @observable codeVisible = false

    /**
     * @Description: 知识图谱-设备定义-设备列表/设备类型/设备组列表表格数据
     * @param {number} type 1设备列表 2设备类型 3设备类型属性列表 4设备组列表
     * @param {object} params 查询条件
     */
    @action.bound async getData(type, params) {
        this.isLoading = true
        let urlList = {
            1: services.requestUrl.deviceList,
            2: services.requestUrl.deviceTypeList,
            3: services.requestUrl.typeAttributeList,
            4: services.requestUrl.deviceGroupList
        }
        try {
            let res = await services.getRequest(urlList[type], {
                ...omit(this.PageInfo, 'total'),
                ...this.searchOptions,
                ...params
            })
            this.isLoading = false
            if (isDataExist(res)) {
                if ([3].includes(type)) {
                    this.typeData = res.data.data
                } else {
                    this.tableData = []
                    this.PageInfo = {}
                    if (res.data.data.items) {
                        this.tableData = res.data.data.items
                        this.PageInfo = res.data.data.page
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取全部设备的数据
    @action.bound async getDevice(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.getDeviceList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.deviceList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取全部工控机-下拉框的数据
    @action.bound async getIpcList() {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.getIpcList)
            this.isLoading = false
            if (isDataExist(res)) {
                this.ipcList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取全部车间-下拉框的数据
    @action.bound async getWorkshopList() {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.getWorkshopList)
            this.isLoading = false
            if (isDataExist(res)) {
                this.workshopList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }

    @action.bound async getDevType(params) {
        this.isLoading = true
        //1设备定义列表，2设备类型列表，3设备类型属性列表
        const url = services.requestUrl.getDeviceTypeList;
        try {
            let res = await services.getRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.typeData = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }

    //获取嵌套子表数据
    @action.bound async getGroupDeviceList(params) {
        this.isLoading = true
        const url = services.requestUrl.getGroupDevice;
        try {
            let data = {
                ...params, pageSize: 10000, pageIndex: 1
            }
            let res = await services.getRequest(url, data)
            this.isLoading = false
            if (isDataExist(res)) {
                this.groupDevice = res.data.data

            }
        } catch (error) {
            console.log(error)
        }
    }

    // 保存数据
    @action.bound async saveData(type, params) {
        try {
            let res = await services.putRequest(services.requestUrl.saveDevice, params)
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error);
        }
    }
    //保存设备组设备编辑数据
    @action.bound async saveGroupDeviceData(type, params) {
        try {
            let res = await services.putRequest(services.requestUrl.saveDevice, params)
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error);
        }
    }


    //删除
    @action.bound async deleteDevice(type, params) {
        this.isLoading = true
        const url = type ? services.requestUrl.removeDevItem : services.requestUrl.removeDevItem
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //removeGroupDevice
    @action.bound async deleteGroupDevice(params) {
        this.isLoading = true
        const url = services.requestUrl.removeGroupDevice
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    /* 设备列表 */
    // 删除
    @action.bound async deleteType(type, params) {
        this.isLoading = true
        const url = type ? services.requestUrl.removeType : services.requestUrl.removeType
        try {
            let res = await services.putRequest(url, params)
            // services.requestUrl.removeRole ? await services.postRequest(url, params) : await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                message.success('删除成功')
            }
        } catch (error) {
            console.log(error)
        }
    }
    //删除设备组
    @action.bound async deleteGroup(type, params) {
        this.isLoading = true
        const url = type ? services.requestUrl.removeDeviceGroup : services.requestUrl.removeDeviceGroup
        try {
            let res = await services.putRequest(url, params)
            // services.requestUrl.removeRole ? await services.postRequest(url, params) : await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                message.success('删除成功')
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 保存
    @action.bound async saveType(type, params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.saveType, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    // saveDeviceGroup
    @action.bound async saveGroup(type, params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.saveDeviceGroup, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 基本属性 */
    @action.bound async saveAttribute(type, params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.saveAttribute, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
        this.getData(3, { entityType: this.entityType })
    }
    @action.bound async removeAttribute(params) {
        this.isLoading = true
        const url = services.requestUrl.removeAttribute
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                message.success('删除成功')
            }
        } catch (error) {
            console.log(error)
        }
        this.getData(3, { entityType: this.entityType })
    }
    @action async getAttributeDetail(params) {
        this.isLoading = true
        const url = services.requestUrl.getAttributeDetail
        try {
            let res = await services.getRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                let data = res.data.data;
                if (data.length < 1) {
                    data.push({
                        id: 1,
                        realValue: '',
                        deviceStatus: 0
                    })
                }
                this.attStatusList = data;
            }
        } catch (error) {
            console.log(error)
        }
    }
    /* 基本类型 */
    //打开/关闭模态框
    @action.bound modalChange = (type, record) => {
        if (type) {
            this.formData = record
        } else {
            this.formData = {}
        }
        this.modalVisible = !this.modalVisible
    }
    //打开/关闭属性模态框
    @action.bound attModalChange = (type, record) => {
        if (type) {
            this.attForm = record
            this.getAttributeDetail({ id: record.id }).then(() => {
                this.attModalVisible = !this.attModalVisible
            })
        } else {
            this.attForm = {}
            this.attModalVisible = !this.attModalVisible
        }
    }

    // 清楚数据
    @action clearData() {
        this.formData = {}
        this.attForm = {}
        this.modalVisible = false
        this.attModalVisible = false
        this.tableData = []
        this.typeData = []
        this.entityType = ''
        this.PageInfo = { pageIndex: 1, pageSize: 10 }
        this.selectedRowKeys = []
        this.searchOptions = {};
    }


}

let DeviceListStore = new DeviceList();
export default DeviceListStore;