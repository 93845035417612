/*
 * @catalogue: 报表管理services
 * @Description: 接口地址
 * @Author: 黄红燕
 * @Date: 2020-12-23 10:52:50
 * @LastEditors: 黄红燕
 * @LastEditTime: 2020-12-23 14:04:11
 */
import { get, put } from '../utils/request';

const requestList = {
    getBigScreenData:'/mom/ipc/screenImportInfo',
}

export function gets(type) {
    return async function (data, options) {
        return await get(requestList[type], data, options);
    }
}
export function posts(type) {
    return async function (data, options) {
        return await put(requestList[type], data, options);
    }
}