import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/device';
import { message } from 'antd';
import { omit } from 'lodash'

class DeviceMaintain {
    /* 查询页面数据 */
    @observable isLoading = false;
    @observable mouldList = []
    @observable mouldId = '';

    @observable maintainList = [];
    @observable maintainPage = {
        pageIndex: 1,
        pageSize: 10,
    };
    @observable maintainType = 1;
    @observable planType = 1;
    @observable checkedTag = 1

    /* 弹窗内容 */
    @observable currentMain = {};
    @observable info = {}
    @observable editVisible = false
    @observable configList = []

    /* 获取所有点检项列表 */
    @action async getAllConfig() {
        /* 获取列表 */
        try {
            let res = await services.gets('getAllConfig')({
                type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10,
            });
            if (isDataExist(res)) {
                this.configList = res.data.data
            }
        } catch (error) {
            console.log(error);
        }
    }
	/**
	 * 工具列表
	 * @param {mouldName: 工具名} params 
	 */
    @action async getMould(params) {
        /* 获取列表 */
        try {
            let res = await services.gets('getMould')({ pattern: this.planType, ...params });
            if (isDataExist(res)) {
                this.mouldList = res.data.data
            }
        } catch (error) {
            console.log(error);
        }
    }


    /* ---------------- 点检库 */

    @action async getMaintain(params = {}) {
        /* 获取列表 */
        this.isLoading = true;
        this.maintainList = []
        try {
            let res = await services.gets('getMaintain')({
                type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10,
                ...params
            });
            this.isLoading = false;
            if (isDataExist(res)) {
                this.maintainList = res.data.data.items;
                this.maintainPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 表格操作 */
    @action async removeMaintain(params) {
        try {
            let data = {
                ...params,
                type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10,
            }
            let res = await services.posts('singleRemove')(data);
            if (isDataExist(res)) {
                message.success('删除成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getMaintain();
    }
    /* 获取级联框的编辑信息 */
    @action async getContentInfo(params, status = 'edit') {
        try {
            let res = await services.gets('getServeInfo')(params);
            if (isDataExist(res)) {
                this.info = { ...res.data.data, status }
                this.editVisible = true
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 保存 */
    @action async saveMaintain(params) {
        try {
            let data = {
                ...params,
                type: this.planType === 1 ? (this.maintainType === 7 ? 7 : 8) : (this.maintainType === 7 ? 9 : 10),
            }
            let res = await services.posts('saveMaintain')(data);
            if (isDataExist(res)) {
                message.success('保存成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getMaintain();
    }

    /* ------------ 方案 */
    @observable schemeList = [];
    @observable schemePage = { pageIndex: 1, pageSize: 10 };

    @action async getScheme(params = {}) {
        this.isLoading = true;
        this.schemeList = []
        try {
            let data = {
                type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10,
                ...omit(this.schemePage, 'total'),
                ...params
            };
            let res = await services.gets('getScheme')(data);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.schemeList = res.data.data.items;
                this.schemePage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getSchemaInfo(params) {
        try {
            let res = await services.gets('schemaInfo')(params);
            if (isDataExist(res)) {
                this.currentMain = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async saveSchema(params) {
        let data = {
            ...params,
            type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10,
        };
        try {
            let res = await services.posts('saveSchema')(data);
            if (isDataExist(res)) {
                message.success('保存成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getScheme();
    }
    @action async delSchema(params, type) {
        let req = type === 'batch' ? 'batchDelSchema' : 'delSchema';
        try {
            let data = {
                ...params,
                type: this.planType === 1 ? (this.maintainType === 7 ? 7 : 8) : this.maintainType === 8 ? 9 : 10,
            }
            let res = await services.posts(req)(data);
            if (isDataExist(res)) {
                message.success('删除成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getScheme();
    }

    /* ----------- 计划 */
    @observable checkPlanList = [];
    @observable checkPlanPage = { pageIndex: 1, pageSize: 10 };
    @observable deviceByIdList = [];
    @observable schemaByIdList = [];

    @action async getPlan(params = {}) {
        this.isLoading = true;
        this.checkPlanList = []
        try {
            let data = {
                type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10,
                ...this.checkPlanPage,
                ...params
            };
            let res = await services.gets('getPlan')(data);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.checkPlanList = res.data.data.items;
                this.checkPlanPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getListByType(type, data) {
        try {
            let req = type === 'mouldId' ? 'getMould' : 'getSchemaByType';
            let params = { ...data, type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10 };
            let res = await services.gets(req)(params);
            if (isDataExist(res)) {
                if (type === 'mouldId') {
                    this.mouldList = res.data.data;
                } else {
                    this.schemaByIdList = res.data.data;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async savePlan(params) {
        let req = 'savePlan';
        let data = {
            ...params,
            type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10,
        };
        try {
            let res = await services.posts(req)(data);
            if (isDataExist(res)) {
                message.success('保存成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getPlan();
    }
    @action async delPlan(params, type) {
        let req = type === 'batch' ? 'batchDelPlan' : 'delPlan';
        try {
            let res = await services.posts(req)({ ...params, type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10, });
            if (isDataExist(res)) {
                message.success('删除成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getPlan();
    }

    @action async recoverPlan(params) {
        let req = 'recoverPlan';
        try {
            let res = await services.posts(req)({ ...params, type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10, });
            if (isDataExist(res)) {
                message.success('回收成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getPlan();
    }

    @action async getPlanInfo(params) {
        try {
            let res = await services.gets('getPlanInfo')({ ...params, type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10, });
            if (isDataExist(res)) {
                this.currentMain = res.data.data;
                return res.data.data.excuteTime;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async toDetail(params) {
        try {
            let res = await services.posts('toDetail')({ ...params, type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10, });
            if (isDataExist(res)) {
                message.success('下发成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getPlan();
    }

    /* ---------点检操作-------- */
    @observable deviceId = 0;
    @observable op_deviceList = [];
    @observable operateList = [];
    @observable operatePage = { pageIndex: 1, pageSize: 10 };
    @observable listByConfigs = [];
    @observable infos = []
    @observable checkedList = []
    @observable operateVisible = false

    @action async getOperateList(params) {
        this.isLoading = true;
        this.checkPlanList = []
        try {
            let data = {
                type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10,
                ...this.operatePage,
                ...params,
                result: 1
            };
            let res = await services.gets('getOperateList')(data);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.operateList = res.data.data.items;
                this.operatePage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getListByConfigs(params) {
        this.isLoading = true
        try {
            let res = await services.gets('getListByConfigs')(params);
            this.isLoading = false

            if (isDataExist(res)) {
                this.listByConfigs = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async saveOperate(params) {
        try {
            let res = await services.posts('saveOperate')(params);
            if (isDataExist(res)) {
                message.success('操作成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getOperateList();
    }
    @action modifyConfigs({ config, level, data }) {
        try {
            const { contentType } = config
            if (level === 1) {
                this.listByConfigs.map(item => {
                    if (item.id === config.id) {
                        Object.assign(item, {
                            result: contentType === 7 ? data.format('YYYY-MM-DD') :
                                [1, 4].includes(contentType) ? data.target.value : data
                        })
                    }
                })
            }
        } catch (error) {

        }
    }

    /* ----------查询---------- */
    @observable result = 0
    @observable typeId = 0
    @observable deviceId = 0
    @observable queryList = []
    @observable queryPage = { pageIndex: 1, pageSize: 10, }
    @observable resultDetail = []
    @observable resultVisible = false
    @observable searchInfo = {}

    /**
     * 获取表格数据
     * @param {object} params 
     */
    @action.bound async getQuery(params) {
        this.queryList = []
        this.isLoading = true;
        try {
            let data = {
                ...this.queryPage,
                // planType 1模具 2 工装   maintainType 7保养 
                type: this.planType === 1 ? this.maintainType === 7 ? 7 : 8 : this.maintainType === 7 ? 9 : 10, 
                // result: 2,
                typeId: this.typeId || '',
                deviceId: this.deviceId || '',
                ...this.searchInfo,
                ...params,
            }
            let res = await services.gets('getOperateList')(data)
            this.isLoading = false;
            if (isDataExist(res)) {
                this.queryList = res.data.data.items;
                this.queryPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getResultInfo(params) {
        this.isLoading = true
        try {
            let res = await services.gets('getResultInfo')(params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.resultDetail = res.data.data
                return true
            }
        } catch (error) {
            console.log(error)
            return false
        }
    }

    @action clearData() {
        this.result = 0
        this.typeId = 0
        this.deviceId = 0
        this.queryList = []
        this.queryPage = {}
        this.editVisible = false
        this.currentMain = {}
        this.info = {}
        this.planType = 1
        this.searchInfo = {}
    }
    @action clearCascadeData() {
        this.status = ''
        this.currentMain = {}
        this.contentType = ''
        this.minValue = ''
        this.maxValue = ''
        this.info = {}
        this.isMust = ''
    }

    @action.bound modifyValue(key, value) {
        this[key] = value
    }

}

let ToolsMaintainStore = new DeviceMaintain();
export default ToolsMaintainStore;
