import { get, post, put } from '../utils/request';
// 敏捷版url前缀
let frontAPI = '/mom'
/* 存储请求地址*/
export const requestUrl = {
    /* 消息中心 */
    message: '/mom/basic/message/list',//消息中心获取列表

    departmentList: '/uaa/dept/list',//部门列表
    /* 设备定义 */
    processList: '/mom/basic/procedure/list',//工序列表
    allProcess: '/mom/basic/procedure/listAll',//工序列表
    orderList: '/mom/basic/defineColumn/list',//订单列表
    warehouseList: '/mom/basic/warehouse/list',//仓库列表
    zeroWarehouse: '/mom/basic/warehouse/warehouseList',//仓库列表
    deviceList: '/mom/basic/device/list',//设备列表
    deviceGroupList: '/mom/basic/deviceGroup/list',//设备组列表
    removeDeviceGroup: '/mom/basic/deviceGroup/remove',//删除设备组
    saveDeviceGroup: '/mom/basic/deviceGroup/save',//增加设备组
    getDeviceList: '/mom/basic/device/getInfo', //获取所有的设备列表
    getGroupDevice: '/mom/basic/deviceGroup/getDevice',//获取设备组设备 ？id=
    removeGroupDevice: '/mom/basic/deviceGroup/removeDevice',//删除设备组下的设备 ?id = 设备id
    deviceTypeList: '/mom/basic/deviceType/list',//设备类型列表
    typeAttributeList: '/mom/basic/deviceType/noPagedListAttribute',//设备类型属性列表
    getAttributeDetail: '/mom/basic/deviceType/getAttributeDetail', //设备类型属性获取离线列表
    saveType: '/mom/basic/deviceType/save',
    updateType: '/mom/basic/deviceType/update',
    removeType: '/mom/basic/deviceType/remove',
    batchRemoveType: '/mom/basic/deviceType/batchRemove',
    saveAttribute: '/mom/basic/deviceType/saveAttribute',
    updateAttribute: '/mom/basic/deviceType/updateAttribute',
    removeAttribute: '/mom/basic/deviceType/removeAttribute',
    saveDevice: '/mom/basic/device/save',
    updateDevice: '/mom/basic/device/update',
    removeDevItem: '/mom/basic/device/remove',
    batchRemoveDev: '/mom/basic/device/batchRemove',
    getIpcList: '/mom/basic/ipc/getInfo', //工控机-下拉框列表
    getWorkshopList: '/mom/basic/workshop/getInfo',//车间-下拉框列表
    getDeviceTypeList: '/mom/basic/deviceType/getInfo', // 设备类型-下拉框



    /* 数据字典 */
    dictList: '/mom/common/dict/list',//获取字典列表
    dictTypeList: '/mom/common/dict/type',//获取字典类型列表
    saveDict: '/mom/common/dict/save',//新增字典
    updateDict: '/mom/common/dict/update',//更新字典
    removeDict: '/mom/common/dict/remove',//删除字典
    batchRemoveDict: '/mom/common/dict/batchRemove',//删除字典

    /* 工序 */
    devTypeList: '/mom/basic/deviceType/list',//设备类型列表
    // processList: '/mom/basic/procedure/list',//工序列表
    saveProcedure: '/mom/basic/procedure/save',//新增工序
    updateProcedure: '/mom/basic/procedure/update',//更新工序信息
    deleteProcedure: '/mom/basic/procedure/remove',//删除工序信息

    /* 订单定义 */
    // orderList: '/mom/basic/defineColumn/list',//订单列表
    saveOrder: '/mom/basic/defineColumn/save',//新增订单定义
    updateOrder: '/mom/basic/defineColumn/update',//更新订单定义
    removeOrder: '/mom/basic/defineColumn/remove',//删除订单定义

    /* 仓库定义 */
    // warehouseList: '/mom/basic/warehouse/list',//仓库列表
    savewarehouse: '/mom/basic/warehouse/save',//新增仓库
    updateWarehouse: '/mom/basic/warehouse/update',//新增仓库
    bomList: '/mom/basic/bom/pagedList',//物料列表
    bomNoPagedList: '/mom/basic/bom/list',
    deleteWarehouse: '/mom/basic/warehouse/remove',
    mudSave: '/mom/mould/mouldStock/save', //新增、编辑工具仓库 
    adrList: '/mom/mould/mouldStock/list',//工具仓库列表  
    /* 产能定义 */
    capacityList: '/mom/basic/capacity/list',//产能列表
    allBomList: '/mom/basic/material/getInfo',//所有物料列表
    techLine: '/mom/basic/capacity/techlineInfo',//获取工艺路线
    removeCapacity: '/mom/basic/capacity/remove',//删除产能定义
    batchRemoveCapacity: '/mom/basic/capacity/batchRemove',//批量删除产能定义
    saveCapacity: '/mom/basic/capacity/save',//保存产能定义
    removeDevice: '/mom/basic/deviceList/remove',//保存产能定义
    batchRemoveDevice: '/mom/basic/deviceList/batchRemove',//保存产能定义
    editCapacity: '/mom/basic/capacity/edit',//修改产能定义


    /* 工厂日历 */
    calendarList: '/mom/basic/calender/list',//获取日历列表
    saveCalendar: '/mom/basic/calender/save',//保存日历
    updateCalendar: '/mom/basic/calender/update',//修改日历
    copyCalendar: '/mom/basic/calender/copy',//复制日历
    removeCalendar: '/mom/basic/calender/remove',//删除日历

    /* 工艺路线 */
    techLineList: '/mom/basic/techline/list',//工艺路线列表
    listProcedure: '/mom/basic/procedure/getInfo',//工艺路线-工序列表
    removeTechLine: '/mom/basic/techline/remove',//删除工艺路线
    batchRemoveTechLine: '/mom/basic/techline/batchRemove',//删除工艺路线
    noTechLine: '/mom/basic/material/getNotConfigured',//未配置工艺路线的物料
    saveTechLine: '/mom/basic/techline/save',//保存工艺路线
    updateTechLine: '/mom/basic/techline/edit',//修改工艺路线
    showFile: '/mom/basic/techline/showFile',
    getProcedureById: '/mom/basic/device/getDeviceByProcedure',

    /* 物料定义 */
    getMaterial: '/mom/basic/material/list', //物料定义
    saveMaterial: '/mom/basic/material/save',
    removeMaterial: '/mom/basic/material/remove',
    syncMaterial: '/mom/basic/material/sync',
    syncBom: '/mom/basic/bom/sync',
    getDeviceListByWorkShop: '/mom/basic/device/getInfoByWorkshopId',


    /* Bom 定义 */
    addBom: '/mom/basic/bom/add',
    saveBom: '/mom/basic/bom/save',//保存物料定义
    enitBom: '/mom/basic/bom/add',//编辑BOM定义
    updateBom: '/mom/basic/bom/update',//编辑物料定义
    removeBom: '/mom/basic/bom/remove',//编辑物料定义
    getBomList: '/mom/basic/material/getInfo',
    getBomSec: '/mom/basic/bom/getSubList',//获取bomm下级

    /* 质检定义 */
    resultData: '/mom/inspect/inspecresult/listByWorkId',
    getWorkInfo: '/mom/inspect/inspecresult/list',

    /* 工厂建模 */
    getPlantList: '/mom/basic/factory/list',
    getWorkShop: '/mom/basic/workshop/list',
    deletePlant: '/mom/basic/factory/remove',
    deleteWorkShop: '/mom/basic/workshop/remove',
    savePlant: '/mom/basic/factory/save',
    saveWorkShop: '/mom/basic/workshop/save',
    getAllPlant: '/mom/basic/factory/getInfo',


    /* 工艺参数 */
    paramsList: '/mom/basic/deviceParam/list',
    saveParams: '/mom/basic/deviceParam/save',
    deleteParams: '/mom/basic/deviceParam/remove',

    /* 终端定义 */
    ipcList: '/mom/basic/ipc/list',
    saveIpc: '/mom/basic/ipc/save',
    deleteIpc: '/mom/basic/ipc/remove',

    /*工具定义 */
    getToolsList: '/mom/mould/mould/list',//获取工具列表get*/
    saveTools: '/mom/mould/mould/save',//工具保存接口post*/
    removeTools: '/mom/mould/mould/remove',//工具删除接口post*/
    checkoutTools: '/mom/mould/mould/stock',//工具出库接口post*/
    toolsWareList: '/mom/mould/mouldWarehouse/list',//工具库接口save remove
    deleteWare: '/mom/mould/mouldWarehouse/remove',
    deleteWareStock: '/mom/mould/mouldStock/remove',//删除工具仓库定义
    getToolsLife: '/mom/mould/mouldLife/list',//工具寿命查询
    getMaterialListInfo: '/mom/basic/material/getInfo',//物料列表
    getDevicetypeInfo: '/mom/basic/deviceType/listAll',//设备列表
    // getWarehouseList:'/mom/mould/mouldWarehouse/listAll',//库位（仓库）信息
    saveWarehouse: '/mom/mould/mouldWarehouse/save',//工具库新增
    getMouldInfo: '/mom/mould/mould/getInfo',
    getTool: '/mom/mould/mould/canInOrOutStockInfo',//工具-下拉框
    stockInOut: '/mom/mould/mould/stock',//出入库
    stockHistory: '/mom/mould/mould/stockList',//出入库记录
    getWarehouseList: '/mom/mould/mouldStock/listAll',//仓库-下拉框
    getLocatorList: '/mom/mould/mouldWarehouse/listEmpty', //空余库位-下拉框
    getToolList: '/mom/mould/mould/listAll',/**工具-选择框 */
    getKnife:'/mom/tool/knife/list', // 工具管理-工具定义-刀具-表格
    listKnifeLife: '/mom//tool/knife/listKnifeLife', // 工具管理-工具寿命管理-刀具
    listKnifeDetail: '/mom//tool/knife/listKnifeDetail', // 工具管理-工具寿命管理-刀具-详情
    getKnifeTypeList: '/mom/tool/knife/listKnifeType', // 工具管理-刀具-刀具类型选择框
    /* 原因定义 */
    reasonList: '/mom/inspect/reason/list',
    processNoPage: '/mom/basic/procedure/getInfo',
    deleteReason: '/mom/inspect/reason/remove',
    saveReason: '/mom/inspect/reason/save',

    //知识图谱-制造bom
    getlistByPage: '/mom/basic/manufactureBom/listByPage',
    getremove: '/mom/basic/manufactureBom/remove',   //删除
    getsave: '/mom/basic/manufactureBom/save',  //保存
    SyncData: '/mom//basic/manufactureBom/syncBom', //同步

    //班次信息
    getAllAttendenceName: "/mom/basic/attendence/listAttendenceName", //获取所有班次
    getAttendenceData: "/mom/basic/attendence/listByPage", //获取班次表格
    saveAttendence: frontAPI + '/basic/attendence/save',//新增班次
    editAttendence: frontAPI + '/basic/attendence/edit',//编辑班次
    removeAttendence: '/mom/basic/attendence/remove',//删除班次信息


    //日历信息
    getAllDevice: frontAPI + "/basic/device/getInfo", //获取所有设备/资源
    getAttendenceCalendarData: frontAPI + "/basic/attendenceCalendar/listByPage", //获取工厂日历表格
    removeAttendencelendar: frontAPI + '/basic/attendenceCalendar/remove',//删除工厂日历
    saveAttendenceCalendar: frontAPI + '/basic/attendenceCalendar/save',//新增班次
    editAttendenceCalendar: frontAPI + '/basic/attendenceCalendar/edit',//编辑班次
}




/* 测试接口的数据 */
export async function getRequest(url, data, options) {
    return await get(url, data, options);
}

/* 测试接口的数据 */
export async function postRequest(url, data, options) {
    return await post(url, data, options);
}
/* 测试接口的数据 */
export async function putRequest(url, data, options) {
    return await put(url, data, options);
}