import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';

class FactoryCalendar {
    @observable isLoading = false;
    @observable weeks = [];
    @observable rangeData = [];//复制时间范围
    @observable isNew = 1;
    @observable singleWeeks = [];//单周
    @observable doubleWeeks = [];//双周
    @observable isDouble = 0 //判断点击的选项 0：初始未点击 1：单休 2：双休
    @observable modalVisible = false;//modal
    @observable formData = {};//表单数据
    @observable timeArg = {};//点击的日历对象
    @observable startDate = "";
    @observable endDate = "";
    @observable calendarEvents = [];//日历事件对象

    //获取表格数据
    @action.bound async getData(params,) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.calendarList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.calendarEvents = [];
                this.tableData = res.data.data
                for (let i = 0; i < this.tableData.length; i++) {
                    if (this.tableData[i].type === 1) {
                        let str = `工作性质：${this.tableData[i].workType === 1 ? `常白\n时间：${this.tableData[i].beginTime}-${this.tableData[i].endTime}` : this.tableData[i].workType === 2 ? `黑白\n时间1：${this.tableData[i].beginTime}-${this.tableData[i].endTime}\n时间2：${this.tableData[i].beginTime2}-${this.tableData[i].endTime2}` : `三班\n时间1：${this.tableData[i].beginTime}-${this.tableData[i].endTime}\n时间2：${this.tableData[i].beginTime2}-${this.tableData[i].endTime2}\n时间3：${this.tableData[i].beginTime3}-${this.tableData[i].endTime3}`}`
                        let obj = {}
                        obj.title = str
                        obj.allDay = true
                        obj.start = this.tableData[i].calendarDay
                        obj.source = 111
                        obj.id = this.tableData[i].id
                        obj.backgroundColor = this.tableData[i].workType === 1 ? '#1890ff' : this.tableData[i].workType === 2 ? '#FFA50D' : '#A8A496'
                        obj.borderColor = this.tableData[i].workType === 1 ? '#1890ff' : this.tableData[i].workType === 2 ? '#FFA50D' : '#A8A496'
                        this.calendarEvents.push(obj)
                        // this.calendarEvents = Array.from(new Set(this.calendarEvents))
                    }
                    else if(this.tableData[i].type === 0){
                        let str=`休息日`
                        let obj={}
                        obj.title=str
                        obj.allDay = true
                        obj.start = this.tableData[i].calendarDay
                        obj.source = 111
                        obj.id = this.tableData[i].id
                        this.calendarEvents.push(obj)
                    }
                }
                return this.calendarEvents
                // callback(this.calendarEvents)
            }
        } catch (error) {
            console.log(error)
        }
    }

    @action.bound chooseSingle = (type) => {
        const week = document.querySelectorAll(".fc-sat.fc-widget-content,.fc-sun.fc-widget-content")
        const sats = document.querySelectorAll(".fc-sat.fc-widget-content")
        //点击
        for (let item of week) {
            item.classList.remove("week-active");
        }
        if (type) {
            for (let item of sats) {
                item.classList.add("week-active");
            }
        }
        const day = document.querySelectorAll(".week-active")
        this.singleWeeks = []
        for (let item of day) {
            this.singleWeeks.push(item.getAttribute('data-date'))
        }
    }
    @action.bound chooseDouble = (type) => {
        const week = document.querySelectorAll(".fc-sat.fc-widget-content,.fc-sun.fc-widget-content")
        //点击
        for (let item of week) {
            item.classList.remove("week-active");
        }
        if (type) {
            for (let item of week) {
                item.classList.add("week-active");
            }
        }
        const day = document.querySelectorAll(".week-active")
        this.doubleWeeks = []
        for (let item of day) {
            this.doubleWeeks.push(item.getAttribute('data-date'))
        }
    }
    //保存日历
    @action.bound async saveCalendar(type, params) {
        this.isLoading = true
        //1新增，2复制，0编辑
        const url = type !== 2 ? services.requestUrl.saveCalendar : services.requestUrl.copyCalendar 
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //保存日历
    @action.bound async deleteCalendar(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.removeCalendar, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //打开/关闭模态框
    @action modalChange = (type, record) => {
        if (type) {
            this.formData = record
            this.isNew = 0
        } else {
            this.formData = {
                beginTime: new Date(),
                endTime: new Date(),
                type: 1,
                workType: 1
            }
            this.isNew = 1
        }
        this.modalVisible = !this.modalVisible
    }
}

let FactoryCalendarStore = new FactoryCalendar();
export default FactoryCalendarStore;