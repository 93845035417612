import { observable, action, } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/device';
// import { omit } from 'lodash'
import { message } from 'antd';

class DeviceDefine {
  @observable typeList = []
  @observable typePage = {}
  @observable current = '1'
  @observable stepList = [
    { id: 1, title: "类型管理", status: 'process' },
    { id: 2, title: "型号管理", status: 'wait' },
    { id: 3, title: "设备列表", status: 'wait' },
  ]
  @observable status = 'add'
  @observable visible = false
  @observable codeVisible = false
  @observable devTypeList = []
  @observable currentMain = {}
  @observable fileList = []
  @observable selectModel = []
  @observable selectType = []
  @observable selectDevice = []
  @observable selectedRowKeys = []
  @observable isLoading = false
  @observable searchName = ''

  /* ------------设备定义------获取设备类型列表 */
  @action async getType(params = {}) {
    this.isLoading = true;
    try {
      let data = {
        pageIndex: 1, pageSize: 10,
        ...params,
      };
      if (params) {
        if (this.current === '2') {
          data.modelName = this.searchName
        } else {
          data.typeName = this.searchName
        }
      }
      let req = this.current === '1' ? 'getType' : 'getModel';
      let res = await services.gets(req)(data);
      this.isLoading = false;
      if (isDataExist(res)) {
        this.typeList = res.data.data.items;
        this.typePage = res.data.data.page;
      }

    } catch (error) {
      console.log(error)
    }
  }
  @action async saveType(params) {

    try {
      let req = this.current === '1' ? 'saveType' : 'saveModel';
      let res = await services.posts(req)(params)
      if (isDataExist(res)) {
        message.success('保存成功')
      }
    } catch (error) {
      console.log(error);
    }
    this.getType();
    this.getSelectType();
    // this.getSelectModel();
  }
  @action async delType(params, type) {
    try {
      let req = this.current === '1' ? type === 'batch' ? 'batchDeleteType' : 'deleteType' :
        type === 'batch' ? 'deleteBatchModal' : 'deleteModal';
      let res = await services.posts(req)(params)
      if (isDataExist(res)) {
        message.success('删除成功')
      }
    } catch (error) {
      console.log(error);
    }
    this.getType();
  }
  /* 删除文件类型 */
  @action async delFile(params) {
    try {
      let res = await services.posts('deleteFile')(params)
      if (isDataExist(res)) {
        message.success('删除成功')
      }
    } catch (error) {
      console.log(error);
    }
  }
  /* 获取设备文件 */
  @action async getDeviceFileList(params) {
    this.isLoading = true;
    try {
      let res = await services.gets('getDeviceFileList')(params)
      this.isLoading = false;
      if (isDataExist(res)) {
        this.fileList = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action async downloadFile(params) {
    try {
      let res = await services.gets('downloadFile')(params);
      if (isDataExist(res)) {
        message.success('下载成功！')
      }
    } catch (e) { }
  }
  /* 获取所有的设备类型 */
  @action async getSelectType(params) {
    try {
      let res = await services.gets('getSelectType')(params)
      if (isDataExist(res)) {
        this.selectType = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  /* 获取设备型号 */
  @action async getSelectModel(params) {
    this.isLoading = true;
    try {
      let res = await services.gets('getSelectModel')(params)
      this.isLoading = false;
      if (isDataExist(res)) {
        this.selectModel = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  /* 获取设备 */
  @action async getSelectDevice(params) {
    this.isLoading = true;
    try {
      let res = await services.gets('getSelectDevice')(params)
      this.isLoading = false;
      if (isDataExist(res)) {
        this.selectDevice = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action changeStep() {
    this.stepList.map((item, index) => {
      if (index === this.current) {
        Object.assign(item, {
          status: 'process'
        })
      } else {
        Object.assign(item, {
          status: 'wait'
        })
      }
    })
  }


  /* --------------设备列表 */
  @observable devList = []
  @observable devPage = {}
  @observable codeInfo = {}
  @observable sortList = []
  @observable listVisible = false
  @observable listStatus = 'add'
  @observable isDevLoading = false
  @observable devCurrent = {}
  @observable devListSelectedRowKeys = []
  @observable queryType = 1
  @observable employeeList = []
  @observable searchCondition = {}
  @observable devStandList = []
  @observable devStandPage = []
  @observable devStandCols = []
  @observable qrcodeInfo = {
    "id": 1,
    "backgroundUrl": "/img.png",
    "codePosition":1,
    "info": [
      {
        "key": "deviceName",
        "title": "设备名称",
        "sort": 1,
        "id": 1
      },
      {
        "key": "deviceName",
        "title": "设备名称",
        "sort": 1,
        "id": 1
      },
      {
        "key": "deviceName",
        "title": "设备名称",
        "sort": 1,
        "id": 1
      },
      {
        "key": "deviceName",
        "title": "设备名称",
        "sort": 1,
        "id": 1
      }
    ]
  }

  /* 查询设备台账 */
  @action async getDevStanding(params) {
    this.isDevLoading = true
    try {
      let data = {
        pageIndex: 1,
        pageSize: 10,
        queryType: this.queryType,
        deviceType: '',
        deviceName: '',
        startTime: '', endTime: '', customerId: '',
        ...this.searchCondition,
        ...params
      }
      let res = await services.gets('getDevStanding')(data)
      this.isDevLoading = false
      if (isDataExist(res)) {
        this.devStandList = res.data.data.items;
        this.devStandPage = res.data.data.page;
        this.devStandCols = res.data.dataExt;
      }
    } catch (error) {
      console.log(error);

    }
  }

  /* 查询设备列表 */
  @action async getDevList(params) {
    this.isDevLoading = true
    try {
      let data = {
        pageIndex: 1,
        pageSize: 10,
        queryType: this.queryType,
        deviceType: '',
        deviceName: '',
        startTime: '', endTime: '', customerId: '',
        ...this.searchCondition,
        ...params
      }
      let res = await services.gets('getDevList')(data)
      this.isDevLoading = false
      if (isDataExist(res)) {
        this.devList = res.data.data.items;
        this.devPage = res.data.data.page;
      }
    } catch (error) {
      console.log(error);

    }
  }
  /* 铭牌框 */
  @action.bound async codeChange() {
    this.codeVisible = !this.codeVisible
    if (!this.codeVisible) {
      this.codeInfo = {}
    }
    // console.log(this.codeVisible)
  }
  /* 获取铭牌设置*/
  @action async getSortList(params) {
    try {
      let res = await services.gets('getQrcode')(params);
      if (isDataExist(res)) {
        this.sortList = []
        this.sortList = res.data.data;
        // console.log(this.sortList, '获取排序列表')
      }
    } catch (error) {
      console.log(error);
    }
  }
  /* 保存名牌设置 */
  @action async saveSortList(params) {
    try {
      let res = await services.posts('saveQrcode')(params);
      if (isDataExist(res)) {
        message.success('设置成功')
      }
    } catch (error) {
      console.log(error);
    }
  }
  /* 获取设备列表详细信息 */
  @action async getDevInfo(params) {
    try {
      let res = await services.gets('getDevInfo')(params);
      if (isDataExist(res)) {
        this.devCurrent = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action async getUsersByRoles(params) {
    try {
      let res = await services.gets('getUsersByRoles')(params);
      if (isDataExist(res)) {
        this.employeeList = res.data.data;
      }
    } catch (error) {
      console.log(error)
    }
  }
  @action async saveDevice(params) {
    try {
      let res = await services.posts('saveDevice')(params)
      if (isDataExist(res)) {
        message.success('保存成功！');
      }
    } catch (error) {

    }
    this.getDevList()
  }
  @action async deleteDevice(params) {
    try {
      let res = await services.posts('deleteDevice')(params)
      if (isDataExist(res)) {
        message.success('删除成功！');
      }
    } catch (error) {

    }
    this.getDevList()
  }

  @action async downloadModule() {
    try {
      let res = await services.gets('downloadModule')()
      if (isDataExist(res)) {
        message.success('下载成功')
      }
    } catch (error) {
      console.log(error)
    }
  }


  /* 修改value */
  @action changeValue(key, value) {
    this[key] = value;
  }

  /* -------------设备配置 */
  @observable customerList = []
  @observable configVisible = false;
  @observable configStatus = 'add';

  @action async getCustomerList(params) {
    try {
      let res = await services.gets('getCustomerList')(params);
      if (isDataExist(res)) {
        this.customerList = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /* --------------设备日志 */
  @observable deviceLog = []
  @observable deviceLogPage = {}
  @observable pageIndex = 1
  @observable dailyDeviceId = ''
  @observable dailyTypeId = ''
  @observable dailyModelId = ''
  @observable isDaily = false
  @observable currentDaily = {}
  @observable logDetailList = []

  @action async getDeviceLog(params) {
    this.pageIndex = params.pageIndex
    try {
      if (this.pageIndex === 1) {
        this.deviceLog = []
      }
      let res = await services.gets('getDeviceLog')(params);
      if (isDataExist(res)) {
        let deviceLog = JSON.parse(JSON.stringify(this.deviceLog))
        deviceLog = deviceLog.concat(res.data.data.items);
        this.deviceLog = deviceLog
        this.deviceLogPage = res.data.data.page
      }
    } catch (error) {
      console.log(error);

    }
  }
  @action async getDetailLog(params) {
    try {
      let res = await services.gets('getDetailLog')(params)
      if (isDataExist(res)) {
        this.logDetailList = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
let DeviceDefineStore = new DeviceDefine();
export default DeviceDefineStore;