import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import { message } from 'antd';
import * as services from '../services/spareparts';

class Spareparts {
    @observable isLoading = false;
    @observable modalTitle = 1;
    @observable tableData = []; // 表格数据
    @observable inPutLogData = [];
    @observable columnsType = '1'; // 备件类型1备件列表2
    @observable PageInfo = {}; // 分页信息 
    @observable id = "";
    @observable formData = {};
    @observable searchDate = [];//查询时间
    @observable modalVisible = false;
    @observable modalType = 0; //新增1详情2编辑3
    @observable typeName = ''; // 备件类型名称
    @observable spareName = undefined; //备件名称
    @observable typeList = []; //备件类型-下拉框
    @observable devTypeList = [];
    @observable oddList = [];
    @observable spareList = [];
    @observable workList = [];

    //获取表格数据
    @action.bound async getData(type, params) {
        this.isLoading = true
        //1.备件类型，2.备件列表，3.库存预警
        const url = type === 1 ? services.requestUrl.getSpareType : type === 2 ? services.requestUrl.getSpare : services.requestUrl.warnList
        try {
            let res = await services.getRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取库存数据
    @action.bound async getLogData(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.spareRecordList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.inPutLogData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取工单信息
    @action.bound async getworkData(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.getByType, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.workList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //新增备件类型
    @action.bound async addNewType(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.saveSpareType, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //新增备件
    @action.bound async addNewSpa(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.saveSpare, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //删除备件类型
    @action.bound async deleteType(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.deleteSpareType, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //删除备件
    @action.bound async deleteSpa(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.deleteSpare, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 备件导出
    @action async export(params){
        this.isLoading = true
        let data = {
            ids:params.ids.join(',')
        }
        try {
            let res = await services.getRequest(services.requestUrl.export, data)
            this.isLoading = false
            if (isDataExist(res)) {
                message.success('导出成功')
            }
        } catch (error) {
            console.log(error)
        }
    }
    //备件出入库
    @action.bound async inPutStorage(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.operationSpare, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //所有备件类型
    @action.bound async getAllType(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.allSpareType, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.typeList = res.data.data

            }
        } catch (error) {
            console.log(error)
        }
    }
    //所有备件-下拉框
    @action.bound async getAllSpare(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.spareAll, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.spareList = res.data.data

            }
        } catch (error) {
            console.log(error)
        }
    }
    //所有设备类型
    @action.bound async getAllDeviceType(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.getDeviceType, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.devTypeList = []
                res.data.data.map(item => {
                    this.devTypeList.push({ label: item.modelName, value: item.id.toString() })
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    //所有工单
    @action.bound async getAllOdd(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.getAllOdd, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.oddList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }

    //打开/关闭模态框
    @action modalChange = (type, record) => {
        if (type) {
            this.formData = record
        } else {
            this.formData = {}
        }
        this.modalType = type
        this.modalVisible = !this.modalVisible
    }
    //打开/关闭模态框
    @action clearData = () => {
        this.isLoading = false;
        this.modalTitle = 1;
        this.tableData = [];
        this.inPutLogData = [];
        this.columnsType = '1';
        this.PageInfo = { pageIndex: 1, pageSize: 10 };
        this.formData = {};
        this.searchDate = [];
        this.modalVisible = false;
        this.typeName = '';
        this.spareName = undefined;
        this.typeList = [];
        this.devTypeList = [];
        this.oddList = [];
        this.workList = [];
    }
}

let SparepartsStore = new Spareparts();
export default SparepartsStore;