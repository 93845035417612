/*
 * @Author: 周洋琴
 * @Date: 2021-03-17 14:17:15
 * @LastEditTime: 2021-04-08 15:50:27
 * @LastEditors: 周洋琴
 * @Description: In User Settings Edit
 * @FilePath: \YunQiMOMFrontPC\src\stores\OutputStore.js
 */
import { observable, action } from 'mobx';
import * as services from '../services/production';
import { isDataExist } from 'utils/dataTools';
import { message, Modal } from 'antd';
import { omit, isEmpty } from 'lodash'

class Output {
    @observable isLoading = false;
    /* 筛选条件 */
    @observable plan_bomList = [];
    @observable searchOptions = {}
    /* 弹窗 */
    @observable visible = false
    /* 列表 */
    @observable outputList = []
    @observable outputPage = { pageIndex: 1, pageSize: 10 }
    /* 详情 */
    @observable outputDetail = []

    /* 查询条件-物料名称或编码 */
    @action async getBomList(params) {
        this.plan_bomList = []
        try {
            let res = await services.gets('getBomList')(params);
            if (isDataExist(res)) {
                this.plan_bomList = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * @Description: 生产管理-产量统计-表格数据
     * @param {object} data 查询条件
     * @return {*}
     */
    /**

    /* 获取产量统计列表 */
    @action async getOutputList(data = {}) {
        this.isLoading = true;
        this.outputList = []
        try {
            let params = {
                ...this.searchOptions,
                ...omit(this.outputPage, 'total'),
                ...data
            };
            let res = await services.gets('getOutputList')(params);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.outputList = res.data.data.items;
                this.outputPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 获取产量统计详情 */
    @action async getOutputDetail(data = {}) {
        this.isLoading = true;
        try {
            let params = {
                ...data
            };
            let res = await services.gets('getOutputDetail')(params);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.outputDetail = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    @action changeVisible = (visible) => {
        this.visible = visible;
    }

    @action clearModal() {
        this.visible = false;
    }

    @action clearData() {
        this.isLoading = false;
        /* 筛选条件 */
        this.plan_bomList = [];
        /* 列表 */
        this.outputList = [];
        this.outputPage = { pageIndex: 1, pageSize: 10 }
        /* 详情 */
        this.outputDetail = [];

        /* 弹窗 */
        this.visible = false
    }
}

let OutputStore = new Output();
export default OutputStore;
