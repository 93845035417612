/*
 * @catalogue: 设备管理-设备监控/设备点检/设备保养
 * @Description: store
 * @Author: 黄红燕
 * @Date: 2020-12-18 09:56:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-28 12:13:04
 */
import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/device';
import { message } from 'antd';
import { omit } from 'lodash'

class DeviceMaintain {
    /* 查询页面数据 */
    @observable isLoading = false;
    @observable addCheckVisiable = false;
    @observable deviceList = [];
    @observable maintainList = [];
    @observable maintainPage = {
        pageIndex: 1,
        pageSize: 10,
    };
    @observable deviceType = '';
    @observable maintainType = 1;
    @observable devStatus = 1;
    @observable tab = '';
    @observable tagList = [
        { name: '全部(0)', key: '' },
        { name: '运行(0)', key: 2 },
        { name: '待机(0)', key: 3 },
        { name: '离线(0)', key: 4 },
        { name: '告警(0)', key: 5 },
    ]
    @observable checkedTag = 1

    /* 监控详情 */
    @observable isDetail = false

    /* 弹窗内容 */
    @observable currentMain = {};
    @observable info = {}
    @observable editVisible = false
    @observable configList = []

    /* 是否立即新增保养计划 */
    @observable deviceName = '';
    @observable deviceSchemaName = '';
    @observable addPlanCheck = false


    /* 获取所有点检项列表 */
    @action async getAllConfig() {
        /* 获取列表 */
        try {
            let res = await services.gets('getAllConfig')({
                type: this.maintainType
            });
            if (isDataExist(res)) {
                this.configList = res.data.data
            }
        } catch (error) {
            console.log(error);
        }
    }


    /* ---------------- 点检库 */
    @action async getDevice() {
        /* 获取所有设备类型 */
        this.isLoading = true;
        try {
            let res = await services.gets('getDevice')();
            this.isLoading = false;
            if (isDataExist(res)) {
                this.deviceList = ([{ name: '全部', id: '' }]).concat(res.data.data);
                this.deviceType = '';
            }
        } catch (error) {
            console.log(error);
        }

    }
    /**
     * @Description: 设备管理-设备点检项/保养项表格数据
     * @param {object} params 查询条件
     */
    @action async getMaintain(params = {}) {
        /* 获取列表 */
        this.isLoading = true;
        this.maintainList = []
        try {
            let res = await services.gets('getMaintain')({
                type: this.maintainType,
                ...this.searchOptions,
                ...params
            });
            this.isLoading = false;
            if (isDataExist(res)) {
                this.maintainList = res.data.data.items;
                this.maintainPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 表格操作 */
    @action async removeMaintain(params) {
        try {
            let data = {
                ...params,
                type: this.maintainType
            }
            let res = await services.posts('singleRemove')(data);
            if (isDataExist(res)) {
                message.success('删除成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getMaintain();
    }
    /* 获取级联框的编辑信息 */
    @action async getContentInfo(params, status = 'edit') {
        try {
            let res = await services.gets('getServeInfo')(params);
            if (isDataExist(res)) {
                this.info = { ...res.data.data, status }
                this.editVisible = true
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 保存 */
    @action async saveMaintain(params) {
        try {
            let data = {
                ...params,
                type: this.maintainType
            }
            let res = await services.posts('saveMaintain')(data);
            if (isDataExist(res)) {
                this.getMaintain();
                return res
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* ------------ 方案 */
    @observable schemeList = [];
    @observable schemePage = { pageIndex: 1, pageSize: 10 };
    /**
     * @Description: 设备管理-设备点检/保养方案表格数据
     * @param {object} 查询条件
     */
    @action async getScheme(params = {}) {
        this.isLoading = true;
        this.schemeList = []
        try {
            let data = {
                type: this.maintainType,
                deviceTypeId: this.deviceType,
                ...omit(this.schemePage, 'total'),
                ...this.searchOptions,
                ...params
            };
            let res = await services.gets('getScheme')(data);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.schemeList = res.data.data.items;
                this.schemePage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getSchemaInfo(params) {
        try {
            let res = await services.gets('schemaInfo')(params);
            if (isDataExist(res)) {
                this.currentMain = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async saveSchema(params) {
        let data = {
            ...params,
            type: this.maintainType
        };
        try {
            let res = await services.posts('saveSchema')(data);
            if (isDataExist(res)) {
                this.getScheme();
                return res
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async delSchema(params, type) {
        let req = type === 'batch' ? 'batchDelSchema' : 'delSchema';
        try {
            let data = {
                ...params,
                type: this.maintainType
            }
            let res = await services.posts(req)(data);
            if (isDataExist(res)) {
                message.success('删除成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getScheme();
    }

    /* ----------- 计划 */
    @observable checkPlanList = [];
    @observable checkPlanPage = { pageIndex: 1, pageSize: 10 };
    @observable deviceByIdList = [];
    @observable schemaByIdList = [];
    /**
     * @Description: 设备管理-设备点检/保养计划表格数据
     * @param {object} 查询条件
     */
    @action async getPlan(params = {}) {
        this.isLoading = true;
        this.checkPlanList = []
        try {
            let data = {
                type: this.maintainType,
                deviceTypeId: this.deviceType,
                ...omit(this.checkPlanPage, 'total'),
                ...this.searchOptions,
                ...params
            };
            let res = await services.gets('getPlan')(data);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.checkPlanList = res.data.data.items;
                this.checkPlanPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getListByType(type, data) {
        try {
            let req = type === 'device' ? 'getDeviceByType' : 'getSchemaByType';
            let params = { deviceTypeId: this.deviceType, typeId: this.deviceType, ...data };
            if (type === 'schema') params.type = this.maintainType;
            let res = await services.gets(req)(params);
            if (isDataExist(res)) {
                if (type === 'device') {
                    this.deviceByIdList = res.data.data;
                } else {
                    this.schemaByIdList = res.data.data;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async savePlan(params) {
        let req = 'savePlan';
        let data = {
            ...params,
            type: this.maintainType,
        };
        try {
            let res = await services.posts(req)(data);
            if (isDataExist(res)) {
                this.getPlan();
                return res
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async delPlan(params, type) {
        let req = type === 'batch' ? 'batchDelPlan' : 'delPlan';
        try {
            let res = await services.posts(req)({ ...params, type: this.maintainType, });
            if (isDataExist(res)) {
                message.success('删除成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getPlan();
    }
    @action async recoverPlan(params) {
        let req = 'recoverPlan';
        try {
            let res = await services.posts(req)({ ...params, type: this.maintainType });
            if (isDataExist(res)) {
                message.success('回收成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getPlan();
    }
    @action async getPlanInfo(params) {
        this.currentMain = {}
        try {
            let res = await services.gets('getPlanInfo')({ ...params, type: this.maintainType, });
            if (isDataExist(res)) {
                this.currentMain = res.data.data;
                return res.data.data.excuteTime;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async toDetail(params) {
        try {
            let res = await services.posts('toDetail')({ ...params, type: this.maintainType, });
            if (isDataExist(res)) {
                message.success('下发成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getPlan();
    }

    /* ---------点检操作-------- */
    @observable deviceId = 0;
    @observable op_deviceList = [];
    @observable operateList = [];
    @observable operatePage = { pageIndex: 1, pageSize: 10 };
    @observable listByConfigs = [];
    @observable typeList = []
    @observable deviceList = []
    @observable infos = []
    @observable checkedList = []
    @observable operateVisible = false

    @action async getDeviceType() {
        try {
            let res = await services.gets('getDevice')();
            if (isDataExist(res)) {
                this.typeList = ([{ name: '全部', id: '' }]).concat(res.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getDeviceByType(params) {
        try {
            let res = await services.gets('getDeviceByType')(params);
            if (isDataExist(res)) {
                this.deviceList = ([{ name: '全部', id: '' }]).concat(res.data.data);
                this.deviceId = '';
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getOperateList(params) {
        this.isLoading = true;
        this.operateList = []
        try {
            let data = {
                type: this.maintainType,
                ...this.operatePage,
                ...params,
                result: 1
            };
            let res = await services.gets('getOperateList')(data);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.operateList = res.data.data.items;
                this.operatePage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getListByConfigs(params) {
        this.isLoading = true
        try {
            let res = await services.gets('getListByConfigs')(params);
            this.isLoading = false

            if (isDataExist(res)) {
                this.listByConfigs = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async saveOperate(params) {
        try {
            let res = await services.posts('saveOperate')(params);
            if (isDataExist(res)) {
                this.getOperateList();
                return res
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action modifyConfigs({ config, level, data }) {
        try {
            const { contentType } = config
            if (level === 1) {
                this.listByConfigs.map(item => {
                    if (item.id === config.id) {
                        Object.assign(item, {
                            result: contentType === 7 ? data.format('YYYY-MM-DD') :
                                [1, 4].includes(contentType) ? data.target.value : data
                        })
                    }
                })
            }
        } catch (error) {

        }
    }

    /* ----------查询---------- */
    @observable result = 0
    @observable typeId = 0
    @observable deviceId = 0
    @observable queryList = []
    @observable queryPage = { pageIndex: 1, pageSize: 10, }
    @observable resultDetail = []
    @observable resultVisible = false
    @observable searchOptions = {} //查询页面查询条件
    @observable workshopList = []
    @observable queryDeviceList = []

    /**
     * 获取所有设备
     * @param {Object} params 
     */
    @action async getDeviceAndGroup(params) {
        try {
            let res = await services.gets('getDeviceAndGroup')(params)
            if (isDataExist(res)) {
                this.queryDeviceList = res.data.data
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    /**
     * 获取所有车间
     * @param {Object} params 
     */
    @action async getWorkshopList(params) {
        try {
            let res = await services.gets('getWorkshopList')(params)
            if (isDataExist(res)) {
                this.workshopList = res.data.data
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    /**
     * @Description: 设备管理-设备点检/保养结果表格数据
     * @param {object} params 查询条件
     */
    @action async getQuery(params) {
        this.queryList = []
        this.isLoading = true;
        try {
            let res = await services.gets('getOperateList')({
                ...omit(this.queryPage, 'total'),
                type: this.maintainType,
                ...this.searchOptions,
                ...params
            })
            this.isLoading = false;
            if (isDataExist(res)) {
                this.queryList = res.data.data.items;
                this.queryPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getResultInfo(params) {
        this.isLoading = true
        try {
            let res = await services.gets('getResultInfo')(params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.resultDetail = res.data.data
                return true
            }
        } catch (error) {
            console.log(error)
            return false
        }
    }


    /* -----------设备监控------------ */
    @observable monitorList = []
    @observable monitorPage = { pageIndex: 1, pageSize: 12 }
    @observable monitorVisible = false
    @observable detailInfos = {}
    @observable detailId = ''
    @observable detailInfo = {}
    @observable paramsData = []
    @observable paramsLoading = false

    /**
     * 获取设备各状态总数
     * @param {object} params
     */
    @action async getDeviceStatusNumber(params) {
        this.isLoading = true
        try {
            let res = await services.gets('getDeviceStatusNumber')({
                ...this.searchOptions,
                ...params,
            });
            this.isLoading = false
            if (isDataExist(res)) {

                this.tagList = [
                    { name: `全部(${res.data.data.totalNum})`, key: '' },
                    { name: `运行(${res.data.data.runNum})`, key: 2 },
                    { name: `待机(${res.data.data.waitNum})`, key: 3 },
                    { name: `离线(${res.data.data.offNum})`, key: 4 },
                    { name: `告警(${res.data.data.errorNum})`, key: 5 },
                ]
            }
        } catch (error) {
        }
    }

    /**
     * 获取设备管理-设备监控表格数据
     * @param {object} params
     * @param {number} params.deviceStatus 设备状态
     * @param {number} params.workshopId 车间id
     */
    @action async getMonitor(params) {
        this.isLoading = true
        try {
            let res = await services.gets('getMonitor')({
                ...this.searchOptions,
                deviceStatus: this.devStatus,
                ...omit(this.monitorPage, 'total'),
                ...params,
            });
            this.isLoading = false
            if (isDataExist(res)) {
                this.monitorList = res.data.data.items;
                this.monitorPage = res.data.data.page;
            }
        } catch (error) {
        }
    }
    @action async getMonitorDetail() {
        try {
            let res = await services.gets('getMonitorDetail')({ deviceId: this.detailId })
            if (isDataExist(res)) {
                this.detailInfos = res.data.data;
            }
        } catch (error) {
            console.log(error);

        }
    }
    @action async getMonitorParams(params) {
        this.paramsLoading = true
        try {
            let res = await services.gets('getMonitorParams')({ deviceId: this.detailId, ...params })
            this.paramsLoading = false
            if (isDataExist(res)) {
                let list = []
                for (let item of res.data.data) {
                    list.push({
                        year: item.createTime.split(' ')[1],
                        value: parseFloat(item.value),
                        id: item.id
                    })
                }
                this.paramsData = list;
            }
        } catch (error) {
            console.log(error);

        }
    }

    /* 查询所有的设备名称 */
    @observable devList = []
    @action async getDeviceAll() {
        try {
            let res = await services.gets('getDeviceAll')()
            if (isDataExist(res)) {
                this.devList = res.data.data;
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 查询所有的车间名称
    @observable workShopList = []
    @action async getWorkShopList() {
        try {
            let res = await services.gets('getWorkShopList')()
            if (isDataExist(res)) {
                this.workShopList = res.data.data;
            }
        } catch (error) {
            console.log(error)
        }
    }

    /* -----------设备分析------------ */
    @observable analysisDetail = {}
    @observable analysisDetailData = []

    /* 设备分析-获取设备详情 */
    @action async getAnalysisDetail(params) {
        this.isLoading = true;
        try {
            let res = await services.gets('getAnalysisDetail')({ deviceId: this.detailId, ...params })
            this.isLoading = false;
            if (isDataExist(res)) {
                this.analysisDetail = res.data.data;
                this.analysisDetailData = res.data.data.handleDetailPage.records
            }
        } catch (error) {
            console.log(error);

        }
    }

    @action clearData() {
        this.result = 0
        this.typeId = 0
        this.deviceId = 0
        this.queryList = []
        this.queryPage = {}
        this.editVisible = false
        this.currentMain = {}
        this.info = {}
        this.searchOptions = {}
        this.tab = ''
        this.analysisDetail = {}
        this.analysisDetailData = []
    }
    @action clearCascadeData() {
        this.status = ''
        this.currentMain = {}
        this.contentType = ''
        this.minValue = ''
        this.maxValue = ''
        this.info = {}
        this.isMust = ''
    }

    @action.bound modifyValue(key, value) {
        this[key] = value
    }

}

let DeviceMaintainStore = new DeviceMaintain();
export default DeviceMaintainStore;
