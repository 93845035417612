import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/device';
import { message } from 'antd';
import { omit } from 'lodash';
import { copySync } from 'fs-extra';
import { testImgWidthHeight } from 'layouts/SystemManager/ConfigPage/methods';

class Store {
    @observable isLoading = false;
    @observable tableData = [];
    @observable pageInfo = { pageIndex: 1, pageSize: 10 };
    @observable searchOptions = {};
    @observable workshopList = [];
    @observable deviceList = [];
    @observable statusNumber = [0, 0, 0, 0, 0, 0,];
    @observable ListDevice = [];
    @observable HistoricalEquipment = []; //历史设备参数接口
    @observable EquipmentContent = []; //历史设备参数接口
    @observable RealTimeParameters = []; //实时参数接口数据
    @observable ParametricCurve = []; //设备参数历史曲线 单轴的情况下
    @observable Multiaxial=[];   //多轴的情况下
    @observable detailPageInfo={pageIndex:1,pageSize:100}
    @observable searchData = {};
    /* ---------- 设备切片 ---------- */
    /**
     * 获取设备切片数据 
     */ 
    @action.bound async getDeviceSlice(params) {
        this.isLoading = true;
        this.tableData = [];
        try {
            let res = await services.gets('getDeviceSlice')({
                tenantId: sessionStorage.getItem('tenantId'),
                ...this.searchOptions,
                ...omit(this.pageInfo, 'total'),
                ...params
            });
            this.isLoading = false;
            if (isDataExist(res)) {
                let data = res.data.data;
                this.tableData = data.items.items || [];
                this.statusNumber = [0, 0, data.items.runNum, data.items.waitNum, data.items.offNum, data.items.errorNum]
                this.pageInfo = data.page || {};
            }
        }
        catch (error) { }
    }

    //设备参数的列表
    @action.bound async getListDeviceParams(params){
    this.ListDevice=[];
     try{
         let res=await services.gets('listDeviceParams')(params)
         this.ListDevice=res.data.data
         console.log('this.ListDevice',this.ListDevice)
     }catch(error){
         console.log(error)
     }
    }

    //设备信息
    @action.bound async getEquipmentParameters(params){
        this.ListDevice=[];
         try{
             let res=await services.gets('EquipmentParameters')(params)
             console.log('res.data.data',res.data.data)
             this.searchData=res.data.data
            //  this.ListDevice=res.data.data
            //  console.log('this.ListDevice',this.ListDevice)
         }catch(error){
             console.log(error)
         }
        }
    // 历史设备参数接口
    /**
     * @param {object} params
     *  */ 
    @action.bound async getHistoricalEquipment(params){
        this.HistoricalEquipment=[];
         try{
             let res=await services.gets('queryHistory')({...params})
             this.HistoricalEquipment=res.data.data.fieldAndContentMapList
             this.EquipmentContent=res.data.data.historyRecordPage.items
             this.detailPageInfo=res.data.data.historyRecordPage.page
         }catch(error){
             console.log(error)
         }
        }

  //实时参数接口
  @action.bound async getRealTimeParameters(params){
    this.RealTimeParameters=[];
     try{
         let res=await services.gets('RealTimeParameters')(params)
         this.RealTimeParameters=res.data.data
         console.log('this.RealTimeParameters',this.RealTimeParameters)
     }catch(error){
         console.log(error)
     }
    }


   //设备参数历史曲线
   @action.bound async getParametricCurve(params){
    this.ParametricCurve=[];
     try{
         let res=await services.gets('ParametricCurve')(params)
         console.log('res11111',res.data.data)
        //  this.ParametricCurve=res.data.data[0].X&&res.data.data[0].Y&&res.data.data[0].Z?res.data.data[0]:res.data.data
         res.data.data[0].X&&res.data.data[0].Y&&res.data.data[0].Z?this.Multiaxial=res.data.data[0]:this.ParametricCurve=res.data.data
     }catch(error){
         console.log(error)
     }
    }


    /**
     * 获取车间选择框
     */
    @action.bound async getWorkshopList() {
        this.workshopList = [];
        try {
            let res = await services.gets('getWorkshopList')();
            if (isDataExist(res)) {
                this.workshopList = res.data.data;
            }
        }
        catch (error) { }
    }

    /**
     * 获取设备选择框
     * @param {object} params
     * @param {string} params.deviceName 设备名称
     */
    @action.bound async getDeviceList(params) {
        this.deviceList = [];
        try {
            let res = await services.gets('getDeviceAndGroup')(params);
            if (isDataExist(res)) {
                this.deviceList = res.data.data;
            }
        }
        catch (error) { }
    }
    /**
     * 根据车间id获取设备选择框
     * @param {object} params
     * @param {string} params.workshopId  车间id
     */
    @action.bound async getDeviceListByWorkshop(params) {
        this.deviceList = [];
        try {
            let res = await services.gets('getDeviceListByWorkshop')(params);
            if (isDataExist(res)) {
                this.deviceList = res.data.data;
            }
        }
        catch (error) { }
    }
    /* ---------- 历史参数 ---------- */
    @observable paramList = []; //设备参数列表
    /**
     * 根据设备id获取设备参数列表
     * @param {object} params
     * @param {number} params.deviceId 设备id 
     */
    @action.bound async getParamListByDevice(params) {
        this.paramList = [];
        try {
            let res = await services.gets('getMonitorDetail')(params);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.paramList = res.data.data.deviceParamDos || [];
            }
        }
        catch (error) { }
    }
    /**
     * 获取历史参数
     * @param {object} params 
     */
    @action.bound async getHistoryParams(params) {
        this.isLoading = true;
        try {
            let res = await services.gets('getMonitorParams')(params);
            this.isLoading = false;
            if (isDataExist(res)) {
                let object = {};
                object[this.searchOptions.param] = res.data.data;
                this.tableData.push(object);
            }
        }
        catch (error) { }
    }
    @observable tagList = [
        { name: '全部(0)', key: '' },
        { name: '运行(0)', key: 2 },
        { name: '待机(0)', key: 3 },
        { name: '离线(0)', key: 4 },
        { name: '告警(0)', key: 5 },
    ]; // 设备状态列表
    @observable devieTypeList = []; // 设备类型列表
    @action.bound async getDevieTypeList(params) {
        try {
            let res = await services.gets('getDevice')(params);
            if (isDataExist(res)) {
                this.devieTypeList = res.data.data;
            }
        } catch (error) {
        }
    }
    /**
      * @Description: 设备管理-设备参数-设备参数设备表格数据
      * @param {object} params
      */
    @action async getMonitor(params) {
        this.isLoading = true
        try {
            let res = await services.gets('getMonitor')({
                ...this.searchOptions,
                ...omit(this.pageInfo, 'total'),
                ...params,
            });
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items;
                this.pageInfo = res.data.data.page;
            }
        } catch (error) {
        }
    }
    /**
      * @Description: 设备管理-设备参数-设备参数-设备各状态总数
      * @param {object} params
      */
    @action async getDeviceStatusNumber(params) {
        this.isLoading = true
        try {
            let res = await services.gets('getDeviceStatusNumber')({
                ...this.searchOptions,
                ...params,
            });
            this.isLoading = false
            if (isDataExist(res)) {
                this.tagList = [
                    { name: `全部(${res.data.data.totalNum})`, key: '' },
                    { name: `运行(${res.data.data.runNum})`, key: 2 },
                    { name: `待机(${res.data.data.waitNum})`, key: 3 },
                    { name: `离线(${res.data.data.offNum})`, key: 4 },
                    { name: `告警(${res.data.data.errorNum})`, key: 5 },
                ]
            }
        } catch (error) {
        }
    }
    @observable formData = {}
    @observable detailTableData = [];
    @observable detailPageInfo = [];
    @observable detailSearchOptions = {};
    @action.bound clearData() {
        this.isLoading = false;
        this.tableData = [];
        this.pageInfo = { pageIndex: 1, pageSize: 10 };
        this.searchOptions = {};
        this.workshopList = [];
        this.deviceList = [];
        this.statusNumber = [0, 0, 0, 0, 0, 0,];
        this.paramList = [];
        this.tagList = [
            { name: '全部(0)', key: '' },
            { name: '运行(0)', key: 2 },
            { name: '待机(0)', key: 3 },
            { name: '离线(0)', key: 4 },
            { name: '告警(0)', key: 5 },
        ];
        // this.formData = {};
        this.detailTableData = [];
        this.detailSearchOptions = {};
    }
}
let DeviceStore = new Store();
export default DeviceStore;