import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/logistics';
import { message } from 'antd';

class Logistics {
    @observable isLoading = false;
    @observable TableData = [];
    @observable PageInfo = { pageIndex: 1, pageSize: 10 };
    @observable formData = {};//异常定义form表单
    @observable modalVisible = false;//结果查询模态框switch
    @observable tagData = [];
    @observable bomList = []; //物料列表-查询
    @observable materialInfo=[]; //物料列表-入库 
    @observable orderData = [];
    @observable warehouseId = "";//仓库ID
    @observable searchData = "";
    @observable materialId = '';//物料编码
    @observable warehouseName = "";//仓库名称
    @observable dialogType = 1;//1入库，0出库
    @observable beginDate = "";//开始时间
    @observable endDate = "";//结束时间
    @observable radioType = "";//结束时间
    @observable selectCode = '';//选择物料

    // 获取表格数据
    @action.bound async getData(type, params) {
        this.isLoading = true
        //1发货管理列表,2仓库库存列表,3库存历史,4出库明细列表
        const url = type === 1 ? services.requestUrl.deliveryList : 
            type === 2 ? services.requestUrl.warehouseList : 
                [3,4].includes(type) ? services.requestUrl.stockHistoryList : ""
        try {
            let res = await services.getRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.TableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取筛选标题数据
    @action.bound async getOrderData(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.listByCompentCode, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.orderData = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取筛选标题数据-仓库
    @action.bound async getTagData(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.allWarehouse, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.tagData = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取物料信息-查询
    @action.bound async getBom(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.bomList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.bomList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取物料信息-入库
    @action.bound async getmaterialInfo(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.materialInfo, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.materialInfo = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取出入库信息
    @action.bound async outInStock(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.outInStock, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取筛选标题数据
    @action.bound async saveDel(type, params) {
        this.isLoading = true
        const url = type ? services.requestUrl.saveDelivery : services.requestUrl.updateDelivery
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取筛选标题数据
    @action.bound async deleteCol(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.removeDelivery, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //打开/关闭模态框type=1为编辑，否则是新增
    @action.bound modalChange = (type, record) => {
        if (type === 1) {
            this.formData = record
        } else if (type === 2) {
            this.formData = {}
            this.formData.warehouseName = this.warehouseName
            this.dialogType = 1
        } else if (type === 3) {
            this.formData = {}
            this.formData.warehouseName = this.warehouseName
            this.dialogType = 0
        } else {
            this.formData = {}
        }
        this.modalVisible = !this.modalVisible
    }
    @action changeValue(text, value) {
        this[text] = value;
    }


    /* 流转库存 */
    @observable componentList = []
    @observable detailList = []
    @observable detailPage = {pageIndex:1,pageSize:10}
    @observable logisCompentId = ''
    @observable childCompentList = []
    @observable procedureList = []
    @observable isCompentLoading = false
    @observable loading = false

    // 获取物料列表-下拉框
    @action async getCompentList(params){
        this.isCompentLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.getComponentIdList,{...params})
            this.isCompentLoading = false
            if(isDataExist(res)){
                this.componentList = res.data.data;
                // this.logisCompentId = res.data.data.items[0].componentId
            }
        } catch (error) {
            console.log(error)
        }
    }
    @action.bound async getDetailByComponent(params){
        this.loading = true
        try {
            let res = await services.getRequest(services.requestUrl.getDetailByComponent,{...params, componentId:this.logisCompentId})
            this.loading = false
            if(isDataExist(res)){
                this.detailList = res.data.data.items;
                this.detailPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error)
        }
    }
    @action async getChildComponent(params){
        try {
            let res = await services.getRequest(services.requestUrl.getChildComponent,params)
            if(isDataExist(res)){
                this.childCompentList = res.data.data;
            }
        } catch (error) {
            console.log(error)
        }
    }
    @action async getProcedure(params){
        try {
            let res = await services.getRequest(services.requestUrl.getProcedure,params)
            if(isDataExist(res)){
                this.procedureList = res.data.data;
            }
        } catch (error) {
            console.log(error)
        }
    }
    @action async saveLogistic(type,params){
        try {
            let res = type == 1 ? services.requestUrl.saveLogisticManage : services.requestUrl.saveLogisticModify;
            if(isDataExist(res)){
                message.success('保存成功')
            }
        } catch (error) {
            console.log(error)
        }
        if(type === 1){
            this.getCompentList()
        }else{
            this.getDetailByComponent()
        }
    }
    /**
     * 获取所有车间
     * @param {Object} params 
     */
    @observable workshopList = []
    @action.bound async getWorkshopList(params){
        try{
            let res = await services.getRequest(services.requestUrl.getWorkshopList, params)
            if(isDataExist(res)){
                this.workshopList = res.data.data
            }
        }
        catch(error){
            console.log(error)
        }
    }
}

let LogisticsStore = new Logistics();
export default LogisticsStore;