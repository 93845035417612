export const THEME_LIST = [
  {
    id:1,
    name:'博拉紫',
    color:'#6236FF',
    isPurple:true,
  },{
    id:2,
    name:'博拉蓝',
    color:'#1890FF',
    isBlue:true
  },{
    id:3,
    name:'博拉绿',
    color:'#1DA57A',
    isGreen:true
  }
]

/* 页面配置，不同页面的图片key */
export const configKey = {
  menulogo:1004,
  mainlogo:1001,
  logoTitle:1002,
  bllogo:1003,
  loginbkg:1006,
  menubkg:1005
}