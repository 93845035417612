import { get, post, put } from '../utils/request';

/* 存储请求地址*/
export const requestUrl = {
    /* fahuo  */
    deliveryList: '/mom/warehouse/delivery/list',//发货管理获取列表
    saveDelivery: '/mom/warehouse/delivery/save',//发货管理获取列表
    updateDelivery: '/mom/warehouse/delivery/update',//发货管理获取列表
    removeDelivery: '/mom/warehouse/delivery/remove',//发货管理获取列表

    /* 库存历史 */
    warehouseList: '/mom/warehouse/stock/list',//仓库管理获取仓库列表
    allWarehouse: '/mom/basic/warehouse/getInfo', //所有仓库的获取
    stockHistoryList: "/mom/warehouse/stockHis/list",//仓库历史列表

    outInStock: '/mom/warehouse/stock/save',//出入库
    bomList: '/mom/warehouse/stock/getMaterialInfo',//仓库内物料信息
    materialInfo:'/mom/basic/material/getInfo',//所有物料信息
    listByCompentCode: "/mom/warehouse/stockOccupy/listByCompentCode",
    
    /* 流转库存 */
    getComponentIdList:'/mom/basic/material/getInfo',
    getDetailByComponent:'/mom/warehouse/circulation/list',
    getChildComponent:'/mom/basic/bom/listAll',
    getProcedure:'/mom/basic/procedure/listAll',
    saveLogisticManage:'/mom/warehouse/stockProcedure/save',
    saveLogisticModify:'/mom/ss',
    getWorkshopList:'/mom/basic/workshop/getInfo',

}

/* 测试接口的数据 */
export async function getRequest(url, data, options) {
    return await get(url, data, options);
}

/* 测试接口的数据 */
export async function postRequest(url, data, options) {
    return await post(url, data, options);
}

/* 测试接口的数据 */
export async function putRequest(url, data, options) {
    return await put(url, data, options);
}