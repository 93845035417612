/*
 * @Author: your name
 * @Date: 2021-04-27 16:34:42
 * @LastEditTime: 2021-05-24 16:54:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \YunQiMOMFrontPC\src\stores\DeviceParamsSettingStore.js
 */
import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/device';
import { omit } from 'lodash';

class ParamsStore {
    @observable isLoading = false;
    @observable tableData = [];
    @observable deviceChecked = [];
    @observable deviceCheckedVal = [];
    @observable deviceCheckArr = [];
    @observable copyDevice = [];
    @observable tablePage = { pageIndex: 1, pageSize: 10 };
    @observable searchOptions = {};
    @observable searchChange = {};
    @observable modalVisible = false;
    @observable copyVisible = false;
    @observable deviceVisible = false;
    @observable pageKey = '';
    @observable copyKey = '';
    @observable formData = {};
    @observable copyData = {};
    @observable paramsPanes = [];
    @observable paramsList = [];
    @observable paramsList1 = [];
    @observable deviceTypeId = [];
    @observable deviceNameCode = [];
    @observable deviceNameCode1 = [];
    @observable deviceNameCode2 = [];
    @observable AllParamFieldList = [];
    @observable AllParamFieldList1 = [];
    @observable currentParamFieldList = [];
    @observable CollectParams = [];
    @observable CollectParamsId = [];
    @observable unConfigDeviceList = [];
    @observable panes = [];
    @observable success = true;
    @observable copyOption = {};
    @observable iCcopy = false;
    @observable goAhead = false;
    @observable copyDeviceChecked = '';
    @observable deviceIds = []
    @observable workshopId = 0
    @observable workshopId1 = 0

    @action.bound modalChange = (type, record) => {
        // console.log('111111111','modalVisible',this.modalVisible)
        if (type === 1) {
            //编辑
            this.pageKey = "edit";
            this.formData = record
        }
        else if (type === 0) {
            this.pageKey = "add";
            this.formData = {}
        }
        this.modalVisible = !this.modalVisible
    }

    @action.bound copyChange = (type, record) => {
        if (type === 1) {
            //编辑
            this.copyKey = "edit";
            this.copyData = record
        }
        else if (type === 0) {
            this.copyKey = "add";
            this.copyData = {}
        }
        this.copyVisible = !this.copyVisible
    }

    @action.bound deviceModalChange = () => {
        this.deviceVisible = !this.deviceVisible
    }

    @action.bound async getDeviceTypeId(params) {
        this.deviceTypeId = [];
        try {
            let res = await services.gets('getDeviceTypeId')({
                ...params
            });
            if (isDataExist(res)) {
                this.deviceTypeId = res.data.data
            }
        }
        catch (error) { }
    }

    @action.bound async getDeviceNameCode(params) {
        this.deviceNameCode = [];
        try {
            let res = await services.gets('getDeviceNameCode')({
                ...this.searchChange,
                ...params
            });
            if (isDataExist(res)) {
                this.deviceNameCode = res.data.data
            }
        }
        catch (error) { }
    }

    @action.bound async getDeviceNameCode1(params) {
        this.deviceNameCode1 = [];
        try {
            let res = await services.gets('getDeviceNameCode')({
                ...params
            });
            if (isDataExist(res)) {
                this.deviceNameCode1 = res.data.data
            }
        }
        catch (error) { }
    }

    @action.bound async getDeviceNameCode2(params) {
        this.deviceNameCode2 = [];
        try {
            let res = await services.gets('getDeviceNameCode')({
                ...params
            });
            if (isDataExist(res)) {
                this.deviceNameCode2 = res.data.data
                return res
            }
        }
        catch (error) { }
    }

    @action.bound async getUnConfigDeviceList(params) {
        this.deviceNameCode2 = [];
        try {
            let res = await services.gets('getUnConfigDeviceList')({
                ...params
            });
            if (isDataExist(res)) {
                this.deviceNameCode2 = res.data.data
            }
        }
        catch (error) { }
    }

    @action.bound async getAllDeviceParamFieldList(params) {
        this.AllParamFieldList = [];
        try {
            let res = await services.gets('getAllDeviceParamFieldList')({
                ...params
            });
            if (isDataExist(res)) {
                this.AllParamFieldList = res.data.data
                return res
            }
        }
        catch (error) { }
    }

    @action.bound async getCurrentDeviceParamFieldList(params) {
        this.currentParamFieldList = [];
        try {
            let res = await services.gets('getCurrentDeviceParamFieldList')({
                ...params
            });
            if (isDataExist(res)) {
                this.currentParamFieldList = res.data.data
            }
        }
        catch (error) { }
    }

    @action.bound async getCollectParamsByDeviceId(params) {
        this.CollectParams = [];
        this.CollectParamsId = [];
        try {
            let res = await services.gets('getCollectParamsByDeviceId')({
                ...params
            });
            if (isDataExist(res)) {
                this.CollectParams = res.data.data
                this.CollectParamsId = res.data.data.map(item => {
                    return item.id
                })
            }
        }
        catch (error) { }
    }


    @action.bound async getParamsList(params) {
        this.isLoading = true;
        this.paramsList = [];
        try {
            let res = await services.gets('getParamsList')({
                ...this.searchOptions,
                ...omit(this.tablePage, 'total'),
                ...params
            });
            this.isLoading = false;
            if (isDataExist(res)) {
                this.paramsList = res.data.data.records
                this.tablePage.pageIndex = res.data.data.current
                this.tablePage.pageSize = res.data.data.size
                this.tablePage.total = res.data.data.total
            }
        }
        catch (error) { }
    }

    @action.bound async addParams(params) {
        try {
            let res = await services.posts('addParams')({
                ...params
            });
            if (isDataExist(res)) {
                return res
            }
        }
        catch (error) { }
    }

    @action.bound async deleteParams(params) {
        try {
            let res = await services.posts('deleteParams')({
                ...params
            });
            if (isDataExist(res)) {
                return res
            }
        }
        catch (error) { }
    }

    @action.bound async copyParams(params) {
        try {
            let res = await services.posts('copyParams')({
                ...this.copyOption,
                ...params
            });
            if (isDataExist(res)) {
                return res
            }
        }
        catch (error) { }
    }

    @action.bound async editEnableStatus(params) {
        try {
            let res = await services.gets('editEnableStatus')({
                ...params
            });
            if (isDataExist(res)) {
                return res
            }
        }
        catch (error) { }
    }

    @action.bound async saveUserTempDeviceCollect(params) {
        try {
            let res = await services.posts('saveUserTempDeviceCollect')({
                ...params
            });
            if (isDataExist(res)) {
                return res
            }
        }
        catch (error) { }
    }

    @action.bound async clearUserTempDeviceCollect(params) {
        try {
            let res = await services.posts('clearUserTempDeviceCollect')({
                ...params
            });
            if (isDataExist(res)) {
                return res
            }
        }
        catch (error) { }
    }

    @action.bound async getUserTempDeviceCollect(params) {
        this.userParamsList = [];
        try {
            let res = await services.gets('getUserTempDeviceCollect')({
                ...params
            });
            if (isDataExist(res)) {
                this.userParamsList = res.data.data;
                return res
            }
        }
        catch (error) { }
    }

    @action.bound clearData() {
        this.isLoading = false;
        this.tableData = [];
        this.deviceChecked = []
        this.deviceCheckedVal = []
        this.deviceCheckArr = []
        this.tablePage = { pageIndex: 1, pageSize: 10 };
        this.searchOptions = {};
        this.searchChange = {};
        this.pageKey = '';
        this.copyKey = '';
        this.formData = {};
        this.copyData = {};
        this.paramsPanes = [];
        this.paramsList = [];
        this.paramsList1 = [];
        this.deviceTypeId = [];
        this.deviceNameCode = [];
        this.deviceNameCode1 = [];
        this.deviceNameCode2 = [];
        this.AllParamFieldList = [];
        this.AllParamFieldList1 = [];
        this.currentParamFieldList = [];
        this.CollectParams = [];
        this.CollectParamsId = [];
        this.unConfigDeviceList = [];
        this.panes = [];
        this.success = true;
        this.copyOption = {};
        this.iCcopy = false;
        this.goAhead = false;
        this.copyDeviceChecked = '';
        this.deviceIds = []
        this.workshopId = 0
        this.workshopId1 = 0
    }
}
let DeviceParamsSettingStore = new ParamsStore();
export default DeviceParamsSettingStore;