/*
 * @catalogue: 知识图谱-工艺路线
 * @Description: store
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-01-12 12:45:26
 */
import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { omit } from 'lodash';
const uuid = require('uuid/v1')

class Process {
    @observable isLoading = false;
    @observable tableData = [];
    @observable selectedRows = [];
    @observable procedureList = [];
    @observable bomList = [];
    @observable noTechLineList = [];
    @observable techLineList = [];
    @observable PageInfo = { pageIndex: 1, pageSize: 10 };
    @observable formData = {};
    @observable modalVisible = false;
    @observable deviceList = []
    @observable searchOptions = {};

    //获取表格数据 ---工艺路线
    /**
     * @Description: 知识图谱-工序定义表格数据
     * @param {object} params 查询条件
     */
    @action.bound async getData(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.techLineList,
                {
                    ...omit(this.PageInfo, 'total'),
                    ...this.searchOptions,
                    ...params
                })
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取工序列表
    @action.bound async getProcedure(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.listProcedure, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.procedureList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取物料名称
    @action.bound async getBomList(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.allBomList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.bomList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取未配置工艺路线的物料
    @action.bound async getNoTechLine(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.noTechLine, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.noTechLineList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //删除工艺路线
    @action.bound async deleteTechLine(type, params) {
        this.isLoading = true
        const url = type ? services.requestUrl.removeTechLine : services.requestUrl.batchRemoveTechLine
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //保存工艺路线
    @action.bound async saveTechLine(type, params) {
        this.isLoading = true
        const url = type ? services.requestUrl.saveTechLine : services.requestUrl.updateTechLine
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }

    @action async showFile(params) {
        try {
            let res = await services.getRequest(services.requestUrl.showFile, params)
            if (isDataExist(res)) {
                Object.keys(res.data.data).map(key => {
                    this.formData.tags2.map(per => {
                        if (key === per.procedureId) {
                            for (let i of res.data.data[key]) {
                                per.defaultFileList.push({
                                    uid: uuid(),
                                    name: i.fileName || '文件01',
                                    status: 'done',
                                    url: i.url,
                                })
                            }
                        }
                        return per
                    })
                    return key
                })
            }

        } catch (error) {
            console.log(error)
        }
    }

    /* 根据工序获取设备列表 */
    @action.bound async getProcedureById(params) {
        try {
            let res = await services.getRequest(services.requestUrl.getProcedureById, params)
            if (isDataExist(res)) {
                return res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }


    //打开/关闭模态框
    @action.bound modalChange = (type, record) => {
        if (type) {
            this.formData = record
        } else {
            this.formData = {}
        }
        this.modalVisible = !this.modalVisible
    }


    /* 工艺参数定义 */
    //获取表格数据
    @action.bound async getTechParams(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.processList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    @action clearData() {
        this.isLoading = false;
        this.tableData = [];
        this.selectedRows = [];
        this.procedureList = [];
        this.bomList = [];
        this.noTechLineList = [];
        this.techLineList = [];
        this.PageInfo = { pageIndex: 1, pageSize: 10 };
        this.formData = {};
        this.modalVisible = false;
        this.deviceList = []
        this.searchOptions = {}
    }
}

let ProcessStore = new Process();
export default ProcessStore;