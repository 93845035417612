/*
 * @catalogue: 知识图谱-制造bom
 * @Description: store
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-02-05 11:53:12
 */
import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { omit } from 'lodash';
import { Message } from 'antd';

class IpcManage {
	@observable deviceList = []
	@observable materialList = []
	@observable modalVisible = false
	@observable tableData = []
	@observable PageInfo = {}
	@observable searchOptions = {};
	/**
	 * @Description: 知识图谱-制造bom
	 * @param {object} params 查询条件
	 */
	@action.bound async getParams(params) {
		this.isLoading = true;
		try {
			let res = await services.getRequest(
				services.requestUrl.getlistByPage,
				{
					...omit(this.PageInfo, 'total'),
					...this.searchOptions,
					...params
				}
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.tableData = res.data.data.items;
				this.PageInfo = res.data.data.page;
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 新增参数
	@action.bound async addParams(params) {
		this.isLoading = true;
		const url = services.requestUrl.getsave;
		try {
			let res = await services.putRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 删除参数
	@action.bound async deleteParams(params) {
		this.isLoading = true;
		try {
			let res = await services.putRequest(
				services.requestUrl.getremove,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	@action.bound async getDeviceList(params) {
		try {
			let res = await services.getRequest(
				services.requestUrl.getDeviceList, params
			)
			if (isDataExist(res)) {
				this.deviceList = res.data.data;
			}
		} catch (error) {
			console.log(error)
		}
	}
	@action.bound async getBomList(params) {
		try {
			let res = await services.getRequest(
				services.requestUrl.allBomList, params
			)
			if (isDataExist(res)) {
				this.materialList = res.data.data;
			}
		} catch (error) {
			console.log(error)
		}
	}

	/* 通用使用------ */
	//打开/关闭模态框
	@action.bound modalChange(type, record) {
		if (type) {
			this.formData = record;
		} else {
			this.formData = {};
		}
		this.modalVisible = !this.modalVisible;
	};

	/* 同步信息 */
	@action.bound async SyncData(type) {
		this.btnLoading = true;
		try {
			let req =  services.requestUrl.SyncData
			let res = await services.getRequest(req);
			this.btnLoading = false;
			if (isDataExist(res)) {
				Message.success('同步成功');
			}
		} catch (error) {
			Message.success('同步失败');
			console.log(error);
		}
		this.getParams(type);
	}
	
	@action clearData(){
		this.techParamsName = '';
		this.deviceList = []
		this.materialList = []
		this.modalVisible = false
		this.searchOptions = []
		this.tableData = []
		this.PageInfo = {}
	}
}

let IpcManageStore = new IpcManage();
export default IpcManageStore;
