/*
 * @catalogue: 知识图谱-工厂建模
 * @Description: store
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-01-12 09:44:57
 */
import { observable, action, } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { message } from 'antd';
import { omit } from 'lodash';

class FactoryUML {
  @observable isLoading = false
  @observable tableList = []
  @observable tablePage = {pageIndex:1,pageSize:10}
  @observable searchOptions = {};

  /* 弹窗 */
  @observable type = 0
  @observable formData = {}
  @observable visible = false
  @observable selectedRowKeys = []
  @observable plantList = []
  @observable DepList = []
  /**
   * @Description: 知识图谱-工厂建模 工厂定义/车间定义
   * @param {number} type 0工厂定义 1车间定义
   * @param {object} params 查询条件
   */
  @action.bound async getData(type,params) {
    try {
      this.isLoading = true
      let req = !type ? 'getPlantList' : 'getWorkShop'
      let res = await services.getRequest(services.requestUrl[req],{
        ...omit(this.tablePage, 'total'),
        ...this.searchOptions,
        ...params
      });
      this.isLoading = false
      if(isDataExist(res)){
        this.tablePage = res.data.data.page
        this.tableList = res.data.data.items;
      }
    } catch (error) {
      console.log(error);
    }
  }

 //获取部门列表
    @action.bound async getDepList(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.departmentList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.DepList = res.data.data.items
            }

        } catch (error) {
            console.log(error)
        }
    }

  @action.bound async getPlant(){
    try {
      let res = await services.getRequest(services.requestUrl.getAllPlant,{})
      if(isDataExist(res)){
        this.plantList = res.data.data;
      }
    } catch (error) {
      
    }
  }

  @action async getReportCount(params) {
    try {
      let res = await services.getRequest(services.requestUrl.getReportCount,params);
      if(isDataExist(res)){
        let histogramDs = [{
          name: 'failCount'
        }, {
          name: 'successCount'
        }];
        histogramDs.map(ele => {
          res.data.data.map(item => {
            ele[item.reportTime] = item[ele.name];
          });
        });
        this.histogramDs = histogramDs;
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action async deleteItem(type,params){
    try {
      let req = !type ? 'deletePlant' : 'deleteWorkShop';
      let res = await services.putRequest(services.requestUrl[req],params)
      if(isDataExist(res)){
        message.success('删除成功！')
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action async saveItem(type,params){
    try {
      // 0: 工厂保存  1:车间保存  2:工厂更新  3:车间更新
      let req = type === 1 ? 'saveWorkShop' : type === 2 ? 'savePlant' : type === 3 ? 'saveWorkShop' : 'savePlant';
      let res = await services.putRequest(services.requestUrl[req],params)
      if(isDataExist(res)){
          return res  
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action modalChange(obj){
    this.type = obj.type;
    this.formData = obj.record || {};
    this.visible = obj.visible;
  }
  @action changeValue(key, value) {
    this[key] = value;
  }
  @action clearData(){
    this.isLoading = false
    this.tableList = []
    this.tablePage = {pageIndex:1,pageSize:10}
    this.searchOptions = {};
  
    /* 弹窗 */
    this.type = 0
    this.formData = {}
    this.visible = false
    this.selectedRowKeys = []
    this.plantList = []
  }
}
let FactoryUMLStore = new FactoryUML();
export default FactoryUMLStore;