import { observable, action, toJS } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import { message } from 'antd';
import * as services from '../services/tool'
class ToolsPub {
    @observable isLoading = false;//正在加载
    @observable radio = 1; // 二级选择
    @observable toolsType = [{ name: '工装', id: 1 }, { name: '模具', id: 2 }] //三级选择
    @observable selectedType = '工装' //三级选中，默认第一个
    @observable tableData = [] // 表格数据
    @observable selectedRowKeys = [] // 多选选中信息rowKey
    @observable pageInfo = { pageSize: 10, pageIndex: 1 } //分页信息
    @observable visible = false //对话框是否可见
    @observable status = '' // add/edit;in/out
    @observable current = '' //当前编辑的信息
    @observable selectToolsType = []//工具列表-添加-下拉选择项
    @observable selectToolsList = [] //库存-查询-下拉选项
    @observable searchName='' // 查询输入框内容
    @observable searchDate='' //查询选择时间
    @observable deviceTypes = [{ label: '设备1', value: '设备1' },
    { label: '设备2', value: '设备2' },
    { label: '设备3', value: '设备3' },
    { label: '设备4', value: '设备4' },]
    @observable wareWarning = { warnMin: '', warnMax: '' } // 库存预警

    // 获取工具类型列表
    @action.bound async getToolsType(params) {
        this.isLoading = true
        const url = this.selectedType === '模具' ? services.mouldUrl : services.frockUrl
        try {
            let res = await services.getRequest(url.getSpareType, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.pageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 增加/修改工具类型
    @action.bound async addToolsType(params) {
        this.isLoading = true
        const url = this.selectedType === '模具' ? services.mouldUrl : services.frockUrl
        try {
            let res = await services.putRequest(url.saveSpareType, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 获取工具类型列表-下拉框
    @action.bound async getSelectToolsType(params) {
        this.isLoading = true
        const url = this.selectedType === '模具' ? services.mouldUrl : services.frockUrl
        try {
            let res = await services.getRequest(url.allSpareType, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.selectToolsType = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 获取工具列表
    @action.bound async getToolsList(params) {
        this.isLoading = true
        const url = this.selectedType === '模具' ? services.mouldUrl : services.frockUrl
        try {
            let res = await services.getRequest(url.getSpare, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.pageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
        this.selectedRowKeys = []
    }
    // 增加/修改工具
    @action.bound async addToolsList(params) {
        this.isLoading = true
        const url = this.selectedType === '模具' ? services.mouldUrl : services.frockUrl
        try {
            let res = await services.putRequest(url.saveSpare, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 获取工具列表-下拉框
    @action.bound async getSelectToolsList(params) {
        this.isLoading = true
        const url = this.selectedType === '模具' ? services.mouldUrl : services.frockUrl
        try {
            let res = await services.getRequest(url.spareAll, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.selectToolsList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 删除工具
    @action.bound deleteToolsList({ type, record }) {
        if (type == 'batch') {
            this.selectedRowKeys.map(id => {
                for (let i = 0; i < this.tableData.length; ++i) {
                    if (this.tableData[i].id == id) {
                        this.tableData.splice(i, 1)
                        break
                    }
                }
            })
        }
        else if (type == 'single') {
            for (let i = 0; i < this.tableData.length; ++i) {
                if (this.tableData[i].id == record.id) {
                    this.tableData.splice(i, 1)
                    break;
                }
            }
        }
    }
    // 出入库
    @action.bound async toolsOperation(params) {
        this.isLoading = true
        const url = this.selectedType === '模具' ? services.mouldUrl : services.frockUrl
        try {
            let res = await services.putRequest(url.operationSpare,params)
            this.isLoading = false
            if (!isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 获取出入库记录
    @action.bound async getInOutRecord(params) {
        this.isLoading = true
        const url = this.selectedType === '模具' ? services.mouldUrl : services.frockUrl
        try {
            let res = await services.getRequest(url.spareRecordList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.pageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 选择数据(选中行rowkey)
    @action.bound handleSelect = selectedRowKeys => {
        this.selectedRowKeys = selectedRowKeys
    };
    // 显示/关闭对话框
    @action.bound handleVisible = (type, record) => {
        if (type) {
            this.current = record
        } else {
            this.current = {}
        }
        this.status = type
        this.visible = !this.visible
        this.wareWarning = { min: '', max: '' }
    };
    // 清除数据
    clearData(){
        this.searchName=''
        this.searchDate=[]
        this.selectedRowKeys = []
    }
}
let ToolsPubStore = new ToolsPub;
export default ToolsPubStore;