import { observable, action, toJS, } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/home';
import { isEmpty, uniqBy, omit } from 'lodash';
import { checkCurrentMenu } from 'utils/dataTools';
import { MenuObj, } from '../constants/configs';
import { message } from 'antd';
class Home {

  /* 其他变量 */
  @observable contentScrollHeight = 0; //当前content滚动高度
  @observable collapsed = false;
  @observable menuObj = [];
  @observable openKeys = []; // 菜单初始化默认打开的key数组
  @observable selectedKeys = ['101']; // 菜单初始化默认选中的key数组
  @observable crumbsList = []; // 全局crumbs需求
  @observable size_class = '';
  @observable isClickCollapsed = false;
  @observable isRecommend = true; // 是否需要推荐菜单
  @observable customMenu = [];// 首页自定义菜单
  @observable isLoading = false;
  @observable toggledActionId = 0;
  @observable isThemeLoading = false //设置切换主题的loading 
  @observable isSwitchTheme = {}
  @observable emptyDesc = '正在加载中'

  /* 设置登陆信息 */
  @action async setLogin(params, finished) {
    this.isLoading = true;
    let result = { success: false, token: null };
    try {
      sessionStorage.setItem('token', 'Basic cGM6c2VjcmV0')
      let res = await services.postsForm('setLogin')({
        ...params,
      })
      this.isLoading = false;
      if (isDataExist(res)) {
        let data = res.data.data
        if (data.access_token && data.token_type) {
          let token = `${data.token_type}${data.access_token}`
          result.success = true
          result.token = token
          result.accessToken = data.access_token
          result.refreshToken = data.refresh_token
          result.tenantId = res.data.dataExt.tenantId
          result.name = res.data.dataExt.name
        }
      }
    } catch (error) {
      console.log(error)
    }
    if (finished)
      finished(result);
  }
  // HomeStore
  @action async setLogout(params) {
    this.isLoading = true;
    try {
      let res = await services.posts('setLogout')({
        ...params,
      })
      this.isLoading = false;
      if (isDataExist(res)) {
        let data = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  /* 首页初始化获取菜单信息 */
  @action async getMenuList(params) {
    this.isLoading = true;
    let menuObj = MenuObj.leafMenuModels;
    try {
      let res = await services.gets('getMenuList')({ id: 0 });
      this.isLoading = false;
      if (isDataExist(res)) {
        if (res.data.data) {
          let data = res.data.data.childMenu;
          /* 初始化开发 菜单项 */
          menuObj.map(lv => {
            Object.assign(lv, {
              displayNone: true
            });
            if (!lv.displayNone) {
              Object.assign(lv, {
                displayNone: false
              });
              if (lv.leafMenuModels) {
                lv.leafMenuModels.map(lv2 => {
                  Object.assign(lv2, {
                    displayNone: false
                  });
                })
              }
            }
          })

          /* 一级菜单 */
          menuObj.map(lv => {
            Object.assign(lv, {
              displayNone: true
            });
            data.map(item => {
              if (lv.name === item.name) {
                if (item.childMenu) {
                  lv.children = item.childMenu;
                }
                Object.assign(lv, {
                  treeId: item.id,
                  displayNone: false
                });
              }
            })
          })
          /* 二级菜单 */
          menuObj.map(lv => {
            if (!lv.displayNone) {
              Object.assign(lv, {
                displayNone: false
              });
              if (lv.leafMenuModels) {
                lv.leafMenuModels.map(lv2 => {
                  Object.assign(lv2, {
                    displayNone: true
                  });
                  if (!isEmpty(lv.children)) {
                    lv.children.filter(item => item.parentId === lv.treeId).filter(item => item.name === lv2.name).map(child => {
                      Object.assign(lv2, {
                        displayNone: false
                      })
                    })
                  }
                })
              }
            }
          })
        } else {
          menuObj = []
          this.emptyDesc = '很抱歉，您暂无系统任何权限，请联系管理员添加'
        }
        this.menuObj = menuObj;
      }
    } catch (error) {
      console.log('获取menu:', error);
    }
  }

  /* 菜单加载的初始化 */
  @action initMenu(pathname) {
    if (pathname === '/order')
      pathname = '/order/query';
    try {
      let menuObj = toJS(this.menuObj);
      let currentMenu = [], crumbsList = [];
      /* 菜单查询 */
      currentMenu = menuObj.filter(leaf => leaf.path === pathname);
      if (isEmpty(currentMenu)) {
        currentMenu = menuObj.filter(lv => pathname.indexOf(lv.path) > -1)
      }
      if (!isEmpty(currentMenu)) {
        for (let lv of currentMenu) {
          /* 面包屑的数据接入 */
          crumbsList.push({
            id: lv.id, name: lv.name, path: lv.path
          });
          /* 校验菜单路径 */
          if (lv.path === pathname) {
            this.selectedKeys = [`${lv.id}`];
            this.openKeys = [`${lv.parentId}`];
          } else {
            /* 判断二级 */
            lv.leafMenuModels.map(lv2 => {
              if (lv2.path === pathname) {
                this.selectedKeys = [`${lv2.id}`];
                this.openKeys = [`${lv2.parentId}`];
                crumbsList.push({
                  id: lv2.id, name: lv2.name, path: lv2.path
                });
              } else {
                if (lv2.activeRouter) {
                  if (lv2.activeRouter.indexOf(pathname) > -1) {
                    this.selectedKeys = [`${lv2.id}`];
                    this.openKeys = [`${lv2.parentId}`];
                  }
                } else {
                  /* 判断三级 */
                  if (!isEmpty(lv2.leafMenuModels)) {
                    lv2.leafMenuModels.map(lv3 => {
                      if (lv3.path === pathname) {
                        this.selectedKeys = [`${lv3.id}`];
                        this.openKeys = [`${lv2.parentId}`];
                      }
                    })
                  }
                }
              }
            })
          }
        }
      }
      let history = sessionStorage.getItem('menu') || [];
      if (!isEmpty(history)) {
        history = JSON.parse(history);
        history = uniqBy(history, 'id');
      }
      sessionStorage.setItem('menu', JSON.stringify(history));
      this.crumbsList = crumbsList;
    } catch (error) {
      console.log(error);
    }
  }
  @action toggleMenu({ actionItem, actionId }, finished) {
    try {
      // 首页
      if (actionId == -1) {
        this.crumbsList = [];
        finished('/index');
        this.toggledActionId = ''
        this.selectedKeys = [];
        this.openKeys = [];
      }
      let menuObj = toJS(this.menuObj); // 根菜单节点
      actionId = parseInt(actionId);
      /* 查询当前菜单item */
      let current = checkCurrentMenu({ menuObj, actionId });
      let currentMenu = current.currentMenu, crumbsList = current.crumbsList;
      finished(currentMenu[0].path);
      this.crumbsList = crumbsList;
      /* 记录历史-浏览记录 */
      this.setLastMenuHistory(currentMenu[0], actionId);
      this.toggledActionId = currentMenu[0].id;
      this.selectedKeys = [`${currentMenu[0].id}`];
      this.openKeys = [`${currentMenu[0].parentId || currentMenu[0].rootId}`];
    } catch (error) {
      console.log(error);

    }
  }
  setLastMenuHistory = (actionItem, actionId) => {
    let history = sessionStorage.getItem('menu') || [];
    let index = -1;
    if (!isEmpty(history)) history = JSON.parse(history);
    for (let i = 0; i < history.length; i++) {
      if (history[i].id === actionId) {
        index = i;
      }
    }
    if (index > -1) {
      history.splice(index, 1);
    }
    history.push(actionItem);
    if (history.length >= 4) {
      history.splice(0, 1);
    }
    history = uniqBy(history, 'id');
    sessionStorage.setItem('menu', JSON.stringify(history));
  }

  @action.bound async savePassword(params) {
    try {
      let res = await services.posts('savePassword')(params)
      if (isDataExist(res)) {
        message.success('更新成功')
      }
    } catch (error) {
      console.log(error)
    }
  }
  @action.bound async saveBasicInfo(params) {
    try {
      let res = await services.posts('saveBasicInfo')(params)
      if (isDataExist(res)) {
        message.success('更新成功')
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action.bound addCrumbs(obj) {
    this.crumbsList.push(obj);
  }

  @action changeValue(key, value) {
    this[key] = value;
  }

  @observable AnalysisData = []
  /**
   * @Description: 首页-生产分析
   */
  @action async getAllStaticCount(params) {
    this.isLoading = true;
    try {
      let res = await services.gets('getAllStaticCount')(params)
      this.isLoading = false;
      if (isDataExist(res)) {
        this.AnalysisData = res.data.data;
      }
    } catch (error) {
      console.log(error)
    }
  }
  @observable productionOutput = []
  /**
   * @Description: 首页-生产产量统计（最近30天）
   */
  @action async getProductionOutput(params) {
    this.isLoading = true;
    try {
      let res = await services.gets('getProductionOutput')({
        tenantId: sessionStorage.getItem('tenantId'),
        ...params
      })
      this.isLoading = false;
      if (isDataExist(res)) {
        this.productionOutput = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  @observable defectStatistics = []
  /**
   * @Description: 首页-每月缺陷统计
   */
  @action async getDefectStatistics(params) {
    this.isLoading = true;
    try {
      let date = new Date(), startDate = [date.getFullYear(), date.getMonth() + 1, 1], endDate = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
      let res = await services.gets('getDefectStatistics')({
        tenantId: sessionStorage.getItem('tenantId'),
        startDate: startDate.join('-'),
        endDate: endDate.join('-'),
        ...params
      })
      this.isLoading = false;
      if (isDataExist(res)) {
        this.defectStatistics = res.data.data;
      }
    } catch (error) {
      console.log(error)
    }
  }
  @observable deviceStatus = []
  /**
   * @Description: 首页-设备状态统计
   */
  @action async getDeviceStatus(params) {
    this.isLoading = true;
    try {
      let res = await services.gets('getDeviceStatus')({
        tenantId: sessionStorage.getItem('tenantId'),
        ...params
      })
      this.isLoading = false;
      if (isDataExist(res)) {
        this.deviceStatus = res.data.data;
      }
    } catch (error) {
      console.log(error)
    }
  }



  /* 添加申请免登接口 */
  @action async setAutoAuth(params) {
    try {
      sessionStorage.setItem('token', 'Basic YWxpLWlvdDpzZWNyZXQ=');
      let res = await services.postsForm('setAutoAuth')(params)
      if (isDataExist(res)) {
        return true
      }
    } catch (error) {
      console.log(error);

    }
  }
}
let HomeStore = new Home();
export default HomeStore;