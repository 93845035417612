import { get, post, put } from '../utils/request';

/* 存储请求地址*/
export const requestUrl = {
    defineList: '/mom/abnormal/list',
    abnormalList: '/mom/abnormal/getInfo',
    saveAbnormal: '/mom/abnormal/save',
    removeAbnormal: '/mom/abnormal/remove',
    batchRemoveAbnormal: '/mom/abnormal/batchRemove',
    
    saveCall: '/mom/abnormal/order/save',
    toResponse: '/mom/abnormal/order/response',
    toHandle:'/mom/abnormal/order/handle',
    callList: '/mom/abnormal/order/list',
    
    typeList: '/mom/abnormal/type/list',
    allTypeList:'/mom/abnormal/type/getInfo',
    saveType: '/mom/abnormal/type/save',
    removeType: '/mom/abnormal/type/remove',
    batchRemoveType: '/mom/abnormal/type/batchRemove',
    AbnormalTypeList: '/mom/abnormal/type/listAbnormalType',
    
    resultList: '/mom/abnormal/order/reslist',
    
    userList: '/uaa/user/list',
    devList: '/mom/basic/device/getAllInfo',
    getWorkshopList:'/mom/basic/workshop/getInfo',

}

/* 测试接口的数据 */
export async function getRequest(url, data, options) {
    return await get(url, data, options);
}

/* 测试接口的数据 */
export async function postRequest(url, data, options) {
    return await post(url, data, options);
}
/* 测试接口的数据 */
export async function putRequest(url, data, options) {
    return await put(url, data, options);
}