import { get, post, put } from "../utils/request";

/* 存储请求地址*/
export const requestUrl = {
  getMenuList:'/uaa/menu/treeOnLogin',
  putLogout:'/uaa/user/logout',

  listDefineByPage:'/basic/define/listDefineNoPage',

  showAndHideProperties:'/basic/config/showConfig', // 展示所有字段的显隐
  searchProperties: "/basic/define/getPropertyByName", //显隐字段的搜索
  saveSetting: "/basic/config/saveConfigSort", // 保存设置

  // 后台的字段配置
  configMap:'/basic/config/allConfigMap', //后台配置字段列表
  saveWord: '/basic/config/addConfig', //新增或修改自定义
  dataType:'/basic/config/allDataType', //获取数据关联类型
  deleteWord:'/basic/define/deleteDefine', //删除字段接口

  layoutData:'/basic/config/openConfig', //交互-新增和编辑 查询所有字段可见
  saveConfig:'/basic/config/saveConfig',//交互-保存业务界面显隐的配置
  /* 搜索可配置 */
  getSearchItem:'/basic/config/getSearchList',

}

/* 测试接口的数据 */
export async function getRequest(url, data, options) {
  url = (url.indexOf('uaa') < 0 ? '/ge' : '') + url;
  return await get(url, data, options);
}

/* 测试接口的数据 */
export async function postRequest(url, data, options) {
  url = (url.indexOf('uaa') < 0 ? '/ge' : '') + url;
  return await post(url, data, options);
}
/* 测试接口的数据 */
export async function putRequest(url, data, options) {
  url = (url.indexOf('uaa') < 0 ? '/ge' : '') + url;
  return await put(url, data, options);
}
