import { observable, action, observe } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import { message } from 'antd';
import * as services from '../services/inspect';

class SPCAnalyze {
    @observable isLoading = false;
    @observable searchOptions = {};
    @observable tableData = {}
    @observable materialList = []
    @observable procedureList = []
    @observable inspectList = []
    @observable pieData = []
    @observable rateData = []
    @observable numberData = []
    @observable workshopList = []; //车间列表
    @observable reasonData = [];

    /**
     * 获取表格数据
     * @param {Number} type 0计量型 1计数型 
     * @param {Object} params 
     */
    @action.bound async getData(type, params) {
        this.isLoading = true;
        this.tableDate = {}
        try {
            let url = type ? 'getPData' : 'getRXData'
            let res = await services.getRequest(services.requestUrl[url], {
                ...this.searchOptions,
                ...params,
            })
            this.isLoading = false
            if (isDataExist(res)) {
                let tableData = res.data.data
                if (type) {
                    let simpleData = []
                    let sampleNum = [] //样品数
                    let badNum = [] //总不良数
                    let badRate = []  //不良率
                    let data = [] // p图
                    let range = [];
                    for (let i = 0; i < tableData.inspectNames.length; ++i) {
                        let temp = [tableData.inspectNames[i]]
                        for (let j = 0; j < 26; ++j) {
                            if (i === 0) {
                                sampleNum.push(tableData.simpleData[j] ? tableData.simpleData[j].sampleNum : '')
                                badNum.push(tableData.simpleData[j] ? tableData.simpleData[j].badNum : '')
                                badRate.push(tableData.simpleData[j] && tableData.simpleData[j].badRate ? tableData.simpleData[j].badRate.toFixed(4) : '')
                                tableData.simpleData[j] && data.push({
                                    no: `${j+1}`,
                                    rate: tableData.simpleData[j].badRate,
                                })
                                tableData.simpleData[j] && range.push(tableData.simpleData[j].badRate)
                            }
                            temp.push(tableData.simpleData[j] ? tableData.simpleData[j].sampleUnqualified[i] : '')
                        }
                        simpleData.push(temp)
                    }
                    this.tableData = { ...res.data.data, simpleData, sampleNum, badNum, badRate, data, range }
                }
                // xbar-p图
                else {
                    let simpleData = []
                    let xgroupSum = []
                    let xgroupAvg = []
                    let rgroup = []
                    let xdata = [] // x图
                    let rdata = [] //r图
                    let rrange = [], xrange = [];
                    for (let i = 0; i < tableData.simpleSize; ++i) {
                        let temp = [i]
                        for (let j = 0; j < 26; ++j) {
                            if (i === 0) {
                                xgroupSum.push(tableData.simpleData[j] ? tableData.simpleData[j].xgroupSum.toFixed(4) : '')
                                xgroupAvg.push(tableData.simpleData[j] ? tableData.simpleData[j].xgroupAvg.toFixed(4) : '')
                                rgroup.push(tableData.simpleData[j] ? tableData.simpleData[j].rgroup.toFixed(4) : '')
                                if (tableData.simpleData[j]) {
                                    xdata.push({
                                        no: `${j+1}`,
                                        rate: tableData.simpleData[j].xgroupAvg,
                                    })
                                    rdata.push({
                                        no: `${j + 1}`,
                                        rate: tableData.simpleData[j].rgroup,
                                    })
                                    xrange.push(tableData.simpleData[j].xgroupAvg);
                                    rrange.push(tableData.simpleData[j].rgroup)
                                }
                            }
                            temp.push(tableData.simpleData[j] ? tableData.simpleData[j].sampleValue[i] : '')
                        }
                        simpleData.push(temp)
                    }
                    this.tableData = { ...res.data.data, simpleData, xgroupSum, xgroupAvg, rgroup, xdata, rdata,
                        xrange, rrange }
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * 获取物料选择框
     * @param {Object} params 
     * @param {String} params.materialName 物料名
     */
    @action.bound async getMaterialList(params) {
        this.materialList = []
        try {
            let res = await services.getRequest(services.requestUrl.getMaterial, params)
            if (isDataExist(res)) {
                this.materialList = res.data.data
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * 根据物料id获取工序选择框
     * @param {Object} params
     * @param {String} params.procedureName 
     */
    @action.bound async getProcedureList(params) {
        this.procedureList = []
        try {
            let res = await services.getRequest(services.requestUrl.getProcedure, {
                materialId: this.searchOptions.materialId,
                ...params
            })
            if (isDataExist(res)) {
                this.procedureList = res.data.data
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * 根据工序id获取质检项
     * @param {Object} params 
     */
    @action.bound async getInspectList(params) {
        this.inspectList = []
        try {
            let res = await services.getRequest(services.requestUrl.configByProcedure, {
                procedureId: this.searchOptions.procedureId,
                ...params,
            })
            if (isDataExist) {
                this.inspectList = res.data.data
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * 质量分析合格率和不合格情况
     * @parma {Object} params
     */
    @action.bound async getRate(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.getRate, params)
            this.isLoading = false
            if (isDataExist(res)) {
                let rate = []
                let number = []
                let data = res.data.data.items || []
                data.map(item => {
                    let d = item.date.split(' ')[0].slice(5)
                    rate.push({ date: d, rate: item.rate})
                    item.inners && item.inners.map(i => {
                        number.push({date: d, ...i})
                    })
                })
                this.rateData = rate || []
                this.numberData = number;
                /**原因分析 */
                let reasonData = [] 
                res.data.data.rateRanges.map(item => {
                    reasonData.push({content: item.reason, rate: item.rate})
                })
                this.reasonData = reasonData;
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * 缺陷分析
     * @parma {Object} params
     */
    @action.bound async getPie(params) {
        this.inspectList = []
        try {
            let res = await services.getRequest(services.requestUrl.getPie, params)
            if (isDataExist(res)) {
                this.pieData = res.data.data || []
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * 获取车间选择框
     */
    @action.bound async getWorkshopList(params) {
        this.workshopList = []
        try {
            let res = await services.getRequest(services.requestUrl.getWorkshopList, params)
            if (isDataExist(res)) {
                this.workshopList = res.data.data || []
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    /**
     * 获取工序选择框
     */
    @action.bound async getProcedure(params) {
        this.procedureList = []
        try {
            let res = await services.getRequest(services.requestUrl.procedureList, params)
            if (isDataExist(res)) {
                this.procedureList = res.data.data || []
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    /**
     * @Description: 获取质量分析合格率数据
     * @param {object} params
     * @param {number} params.materialList 物料id
     * @param {string} params.startDate 开始时间
     * @param {string} params.endDate 结束时间
     * @return {array} data数据
     */
    @action.bound async getQualityRateData(params) {
        this.procedureList = []
        try {
            let res = await services.getRequest(services.requestUrl.getQualityRateData, params)
            if (isDataExist(res)) {
                return res.data.data;
            }
        }
        catch (error) { }
    }
    /**
     * @Description: 获取质量分析不合格原因数据
     * @param {object} params
     * @param {number} params.materialList 物料id
     * @param {string} params.startDate 开始时间
     * @param {string} params.endDate 结束时间
     * @return {array} data数据
     */
    @action.bound async getQualityReasonData(params) {
        this.procedureList = []
        try {
            let res = await services.getRequest(services.requestUrl.getQualityReasonData, params)
            if (isDataExist(res)) {
                return res.data.data;
            }
        }
        catch (error) { }
    }
    //打开/关闭模态框
    @action clearData = () => {
        this.isLoading = false;
        this.inspectList = [];
        this.searchOptions = {};
        this.tableData = {}
        this.materialList = []
        this.procedureList = []
        this.workshopList = [];
    };
}

let SPCAnalyzeStore = new SPCAnalyze();
export default SPCAnalyzeStore;
