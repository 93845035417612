/*
 * @catalogue: 知识图谱-终端定义
 * @Description: store
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-01-12 13:16:35
 */
import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { omit } from 'lodash';

class IpcManage {
	/* 工艺参数定义 */
	@observable deviceList = []
	@observable materialList = []
	@observable modalVisible = false
	@observable tableData = []
	@observable PageInfo = {}
	@observable searchOptions = {};
	/**
     * @Description: 知识图谱-终端定义
     * @param {object} params 查询条件
     */
	@action.bound async getParams(params) {
		this.isLoading = true;
		try {
			let res = await services.getRequest(
				services.requestUrl.ipcList,
				{
					...omit(this.PageInfo, 'total'),
					...this.searchOptions,
					...params
				}
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.tableData = res.data.data.items;
				this.PageInfo = res.data.data.page;
			}
		} catch (error) {
			console.log(error);
		}
    }
	// 新增参数
	@action.bound async addParams(params) {
		this.isLoading = true;
		const url =services.requestUrl.saveIpc;
		try {
			let res = await services.putRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 删除参数
	@action.bound async deleteParams(params) {
		this.isLoading = true;
		try {
			let res = await services.putRequest(
				services.requestUrl.deleteIpc,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}

	@action.bound async getDeviceList(params){
		try {
			let res = await services.getRequest(
				services.requestUrl.getDeviceList,params
			)
			if(isDataExist(res)){
				this.deviceList = res.data.data;
			}
		} catch (error) {
			console.log(error)
		}
	}
	@action.bound async getBomList(params){
		try {
			let res = await services.getRequest(
				services.requestUrl.allBomList,params
			)
			if(isDataExist(res)){
				this.materialList = res.data.data;
			}
		} catch (error) {
			console.log(error)
		}
	}

    /* 通用使用------ */
	//打开/关闭模态框
	@action.bound modalChange(type, record){
		if (type) {
			this.formData = record;
		} else {
			this.formData = {};
		}
		this.modalVisible = !this.modalVisible;
	};
	
	@action clearData(){
		this.techParamsName = '';
		this.deviceList = []
		this.materialList = []
		this.modalVisible = false
		this.searchOptions = []
		this.tableData = []
		this.PageInfo = {}
	}
}

let IpcManageStore = new IpcManage();
export default IpcManageStore;
