/*
 * @catalogue: 质量管理-质检配置
 * @Description: store文件
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: hhy
 * @LastEditTime: 2021-04-06 16:19:21
 */
import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/inspect';
import { message } from 'antd';
import { omit } from 'lodash';

class Inspect {
    /* 查询页面数据 */
    @observable isLoading = false;
    @observable materialList = [];
    @observable procedureList = [];

    @observable maintainList = [];
    @observable maintainPage = { pageIndex: 1, pageSize: 10 };
    @observable maintainType = 4;
    @observable checkedTag = 1;

    /* 弹窗内容 */
    @observable currentMain = {};
    @observable info = {};
    @observable editVisible = false;
    @observable configList = [];
    @observable procedureList = [];

    /* 获取所有的工序列表 */
    @action.bound async getProcedure(params) {
        this.isLoading = true;
        try {
            let res = await services.getRequest(
                services.requestUrl.procedureList,
                params
            );
            this.isLoading = false;
            if (isDataExist(res)) {
                this.procedureList = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    /* 获取所有的物料 */
    @action.bound async getAllBom(params) {
        this.isLoading = true;
        try {
            let res = await services.getRequest(
                services.requestUrl.allBomList,
                params
            );
            this.isLoading = false;
            if (isDataExist(res)) {
                this.materialList = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 获取所有点检项列表 */
    @action async getSelectAll(type, params) {
        /* 获取列表 */
        try {
            let req = type === 'material' ? 'getMaterial' : 'getProcedure';
            let res = await services.getRequest(services.requestUrl[req], params);
            if (isDataExist(res)) {
                if (type === 'material') {
                    this.materialList = res.data.data;
                } else {
                    this.procedureList = [];
                    this.procedureList = res.data.data;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* ---------------- 点检库 */
    @action async getDevice() {
        /* 获取所有设备类型 */
        this.isLoading = true;
        try {
            let res = await services.getRequest(services.requestUrl.getDevice);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.deviceList = [{ name: '全部', id: '' }].concat(res.data.data);
                this.deviceType = '';
            }
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * @Description: 质检管理-质检配置-质检项表格数据
     * @param {object} params
     */
    @action async getMaintain(params = {}) {
        /* 获取列表 */
        this.isLoading = true;
        this.maintainList = [];
        try {
            let res = await services.getRequest(services.requestUrl.getMaintain, {
                ...this.searchOptions,
                ...omit(this.maintainPage, 'total'),
                ...params,
            });
            this.isLoading = false;
            if (isDataExist(res)) {
                this.maintainList = res.data.data.items;
                this.maintainPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 表格操作 */
    @action async removeMaintain(params) {
        try {
            let data = {
                ...params
            };
            let res = await services.putRequest(
                services.requestUrl.singleRemove,
                data
            );
            if (isDataExist(res)) {
                message.success('删除成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getMaintain();
    }
    /* 获取级联框的编辑信息 */
    @action async getContentInfo(params, status = 'edit') {
        try {
            let res = await services.getRequest(
                services.requestUrl.getServeInfo,
                params
            );
            if (isDataExist(res)) {
                this.info = { ...res.data.data, status };
                this.editVisible = true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* 保存 */
    @action async saveMaintain(params) {
        try {
            let res = await services.putRequest(
                services.requestUrl.saveMaintain,
                params
            );
            if (isDataExist(res)) {
                this.getMaintain();
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* ------------ 方案 */
    @observable schemeList = [];
    @observable schemePage = { pageIndex: 1, pageSize: 10 };
    @observable modalSelectedKeys = []
    @observable modalPageInfo = { pageIndex: 1, pageSize: 10 };
    @observable modalSearchInfo = {};
    @observable modalLoading = false
    /**
     * @Description: 质量管理-质检配置-质检方案表格数据
     * @param {object} params
     */
    @action async getScheme(params = {}) {
        this.isLoading = true;
        this.schemeList = [];
        this.schemePage = { pageIndex: 1, pageSize: 10 };
        try {
            let data = {
                ...this.searchOptions,
                ...omit(this.schemePage, 'total'),
                ...params
            };
            let res = await services.getRequest(services.requestUrl.getScheme, data);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.schemeList = res.data.data.items;
                this.schemePage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getSchemaInfo(params) {
        try {
            let res = await services.getRequest(
                services.requestUrl.schemaInfo,
                params
            );
            if (isDataExist(res)) {
                this.currentMain = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async saveSchema(params) {
        let data = {
            ...params
        };
        try {
            let res = await services.putRequest(services.requestUrl.saveSchema, data);
            if (isDataExist(res)) {
                this.getScheme();
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async delSchema(params, type) {
        try {
            let data = {
                ...params
            };
            let res = await services.putRequest(services.requestUrl.delSchema, data);
            if (isDataExist(res)) {
                message.success('删除成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getScheme();
    }
    /**
     * @Description: 质量管理-质检配置-质检方案-选择工序后获取工序下绑定的所有质检项
     * @param {object} params 
     * @return {number} params.procedureId 工序id
     */
     @action.bound async getConfigByProcedure(params,) {
        this.modalLoading = true;
        try {
            let res = await services.getRequest(
                services.requestUrl.getMaintain,
                {
                    ...omit(this.modalPageInfo, 'total'),
                    ...this.modalSearchInfo,
                    ...params,
                }
            );
            if (isDataExist(res)) {
                this.configList = res.data.data.items;
                this.modalPageInfo = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
        this.modalLoading = false;
    }
    /**
     * @Description: 质量管理-质检配置-质检方案-编辑时获取质检方案下绑定的质检项列表
     * @param {object} params
     * @param {number} params.id 质检方案id
     */
    @action.bound async getConfigByPath(params) {
        this.modalLoading = true;
        try {
            let res = await services.getRequest(
                services.requestUrl.configByPath,
                params
            );
            if (isDataExist(res)) {
                let selectedKeys = []
                // 遍历每个已勾选的质检项
                for (let item of res.data.data) {
                    selectedKeys.push(item.inspectItemId)
                    // 遍历所有质检项
                    for (let config of this.configList) {
                        if (item.inspectItemId === config.id) {
                            // 保存上限、下限、标准值、检验方法
                            Object.assign(config, {
                                top: item.top,
                                down: item.down,
                                standard: item.standard,
                                inspectMethod: item.inspectMethod,
                                configId: item.id
                            })
                        }
                    }
                }
                // 保存已勾选质检项id
                this.modalSelectedKeys = selectedKeys
            }
        } catch (error) {
            console.log(error);
        }
        this.modalLoading = false;
    }
    
    /**
     * @Description: 操作弹窗表格的输入框
     * @param {number} index 操作行index
     * @param {string} type 输入数据类型 top上限值 standard标准值 down下限值 inspectMethod检验方法
     * @param {string} value 输入值
     */
    @action.bound handleInput(index, type, value) {
        try {
            let t = JSON.parse(JSON.stringify(this.configList))
            t[index][type] = value;
            this.configList = t;
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * @Description: 质量管理-质检配置-质检方案修改状态
     * @param {number} id 方案id
     */
    @action.bound async toggleInspectStatus(id) {
        this.modalLoading = true;
        try {
            let res = await services.getRequest(
                services.requestUrl.toggleInspectStatus,
                { id }
            );
            if (isDataExist(res)) {
                message.success(res.data.data);
                // 重新获取数据
                this.getScheme();
            }
        } catch (error) {
            console.log(error);
        }
    }
    /* ----------- 计划 */
    @observable checkPlanList = [];
    @observable checkPlanPage = { pageIndex: 1, pageSize: 10 };
    @observable deviceByIdList = [];
    @observable schemaByIdList = [];

    /**
     * @Description: 质量管理-质检配置 质检计划表格数据
     * @param {object} params
     */
    @action async getPlan(params) {
        this.isLoading = true;
        this.checkPlanList = [];
        try {
            let data = {
                ...this.searchOptions,
                ...omit(this.checkPlanPage, 'total'),
                ...params
            };
            let res = await services.getRequest(services.requestUrl.getPlan, data);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.checkPlanList = res.data.data.items;
                this.checkPlanPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getListByType(type, data) {
        try {
            let req = type === 'device' ? 'getDeviceByType' : 'getSchemaByType';
            let params = { typeId: this.deviceType, ...data };
            if (type === 'schema') params.type = this.maintainType;
            let res = await services.getRequest(services.requestUrl[req], params);
            if (isDataExist(res)) {
                if (type === 'device') {
                    this.deviceByIdList = res.data.data;
                } else {
                    this.schemaByIdList = res.data.data;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async savePlan(params) {
        let req = 'savePlan';
        let data = {
            ...params
        };
        try {
            let res = await services.putRequest(services.requestUrl[req], data);
            if (isDataExist(res)) {
                this.getPlan();
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async delPlan(params, type) {
        let req = type === 'batch' ? 'batchDelPlan' : 'delPlan';
        try {
            let res = await services.putRequest(services.requestUrl[req], {
                ...params
            });
            if (isDataExist(res)) {
                message.success('删除成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getPlan();
    }
    @action async getPlanInfo(params) {
        this.currentMain = {};
        try {
            let res = await services.getRequest(services.requestUrl.getPlanInfo, {
                ...params
            });
            if (isDataExist(res)) {
                this.currentMain = res.data.data;
                return res.data.data.excuteTime;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async toDetail(params) {
        try {
            let res = await services.putRequest(services.requestUrl.toDetail, {
                ...params
            });
            if (isDataExist(res)) {
                message.success('下发成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getPlan();
    }

    /* ---------点检操作-------- */
    @observable deviceId = 0;
    @observable op_deviceList = [];
    @observable operateList = [];
    @observable operatePage = { pageIndex: 1, pageSize: 10 };
    @observable listByConfigs = [];
    @observable typeList = [];
    @observable deviceList = [];
    @observable infos = [];
    @observable checkedList = [];
    @observable operateVisible = false;

    @action async getDeviceType() {
        try {
            let res = await services.getRequest(services.requestUrl.getDevice);
            if (isDataExist(res)) {
                this.typeList = [{ name: '全部', id: '' }].concat(res.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * @Description: 获取设备列表下拉框
     * @param {object} params
     */
    @action async getDeviceList(params) {
        try {
            let res = await services.getRequest(services.requestUrl.getDeviceList, params);
            if (isDataExist(res)) {
                this.deviceList = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getOperateList(params) {
        this.isLoading = true;
        this.operateList = [];
        try {
            let data = {
                ...this.operatePage,
                ...params,
                result: 1
            };
            let res = await services.getRequest(
                services.requestUrl.getOperateList,
                data
            );
            this.isLoading = false;
            if (isDataExist(res)) {
                this.operateList = res.data.data.items;
                this.operatePage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getListByConfigs(params) {
        this.isLoading = true;
        try {
            let res = await services.getRequest(
                services.requestUrl.getListByConfigs,
                params
            );
            this.isLoading = false;

            if (isDataExist(res)) {
                this.listByConfigs = res.data.data;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async saveOperate(params) {
        try {
            let res = await services.putRequest(
                services.requestUrl.saveOperate,
                params
            );
            if (isDataExist(res)) {
                message.success('操作成功');
            }
        } catch (error) {
            console.log(error);
        }
        this.getOperateList();
    }
    @action modifyConfigs({ config, level, data }) {
        try {
            const { contentType } = config;
            if (level === 1) {
                this.listByConfigs.map(item => {
                    if (item.id === config.id) {
                        Object.assign(item, {
                            result:
                                contentType === 7
                                    ? data.format('YYYY-MM-DD')
                                    : [1, 4].includes(contentType)
                                        ? data.target.value
                                        : data
                        });
                    }
                });
            }
        } catch (error) { }
    }

    /* ----------查询---------- */
    @observable typeId = 0;
    @observable deviceId = 0;
    @observable queryList = [];
    @observable queryPage = { pageIndex: 1, pageSize: 10 };
    @observable resultDetail = [];
    @observable resultNum = 0
    @observable resultVisible = false;
    @observable columns = []
    /**
     * @Description: 质量管理-质检配置 质检结果
     * @param {object} params 查询条件
     */
    @action async getQuery(params) {
        this.queryList = [];
        this.isLoading = true;
        try {
            let data = {
                ...this.searchOptions,
                ...omit(this.queryPage, 'total'),
                ...params
            };
            let res = await services.getRequest(services.requestUrl.getQuery, data);
            this.isLoading = false;
            if (isDataExist(res)) {
                this.queryList = res.data.data.items;
                this.queryPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error);
        }
    }
    @action async getResultInfo(params) {
        this.isLoading = true;
        try {
            let res = await services.getRequest(
                services.requestUrl.getResultInfo,
                params
            );
            this.isLoading = false;
            if (isDataExist(res)) {
                let data = res.data.data, list = [];
                this.resultNum = res.data.data[0].infos.length;
                for (let i = 0; i < data.length; i++) {
                    let item = data[i];
                    for (let j = 0; j < data[i].infos.length; j++) {
                        Object.assign(item, {
                            ...data[i].infos[j],
                            ['actural' + j]: data[i].infos[j].actural,
                            ['result' + j]: data[i].infos[j].result
                        })
                    }
                    list.push(item)
                }
                this.resultDetail = list
                return true;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    /* ---------- 执行报表 ---------- */
    @observable searchOptions = {};
    @observable executeCharts = {
        totalNum: 0, unStandardNum: 0, standardNum: 0,
        standards: [],
        timelyRate: 0, unTimelyRate: 0,
    }
    @observable tableData = [];
    @observable pageInfo = { pageIndex: 1, pageSize: 10 };
    /**
     * 获取图表数据
     * @param {object} params 
     * @param {number} params.planNo 计划号
     * @param {number} procedureId 工序
     * @param {string} startDate 开始时间
     * @param {string} endDate 结束时间
     */
    @action.bound async getExecuteCharts(params) {
        this.executeCharts = {
            totalNum: 0, unStandardNum: 0, standardNum: 0,
            standards: [],
            timelyRate: 0, unTimelyRate: 0,
        }
        try {
            let res = await services.getRequest(services.requestUrl.getExecuteCharts, params)
            if (isDataExist(res)) {
                let standards = [];
                for (let key in res.data.data.standards) {
                    standards.push({ name: key, value: res.data.data.standards[key] })
                }
                this.executeCharts = { ...res.data.data, standards }
                console.log(Array.from(res.data.data.standards))
            }
        }
        catch (error) { }
    }
    /**
     * 获取表格数据
     * @param {object} params
     * @param {number} params.planNo 计划号
     * @param {number} procedureId 工序
     * @param {string} startDate 开始时间
     * @param {string} endDate 结束时间
     */
    @action.bound async getExecuteTable(params) {
        this.tableData = [];
        try {
            let res = await services.getRequest(services.requestUrl.getExecuteTable,
                {
                    ...this.searchOptions,
                    ...omit(this.pageInfo, 'total'),
                    ...params
                }
            )
            if (isDataExist(res)) {
                this.tableData = res.data.data.items;
                this.pageInfo = res.data.data.page;
            }
        }
        catch (error) { }
    }

    @action clearData() {
        this.queryList = [];
        this.queryPage = {};
        this.editVisible = false;
        this.currentMain = {};
        this.info = {};
        /* 执行报表 */
        this.tableData = [];
        this.executeCharts = {
            totalNum: 0, unStandardNum: 0, standardNum: 0,
            standards: [],
            timelyRate: 0, unTimelyRate: 0,
        }
        this.resultVisible = false;
        this.searchOptions = {};
    }

    @action.bound modifyValue(key, value) {
        this[key] = value;
    }
}

let InspectStore = new Inspect();
export default InspectStore;
