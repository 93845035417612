
export const MenuObj = {
  id: 'root',
  name: '首页',
  path: '/index',
  activeRouter: [],
  displayNone: false,
  leafMenuModels: [
    {
      id: 1,
      name: '计划管理',
      icon: 'jihua',
      path: '/plan',
      action: false,
      displayNone: true,
      leafMenuModels: [
        {
          id: 101,
          parentId: 1,
          name: '订单管理',
          path: '/plan/order',
          action: false,
          displayNone: false
        },
        {
          id: 102,
          name: '生产计划',
          parentId: 1,
          path: '/plan/task',
          action: false,
          displayNone: false
        },
        {
          id: 103,
          name: '制造订单',
          parentId: 1,
          path: '/plan/manufacture',
          action: false,
          displayNone: false
        },
        {
          id: 104,
          name: '高级排程',
          parentId: 1,
          path: '/plan/advanced',
          action: false,
          displayNone: false
        },
      ]
    },
    {
      id: 2,
      name: '生产管理',
      icon: 'shengchan',
      path: '/production',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 201,
          parentId: 2,
          name: '工单管理',
          path: '/production/workorder',
          action: false,
          displayNone: false
        },
        {
          id: 202,
          parentId: 2,
          name: '生产报工',
          path: '/production/report',
          action: false,
          displayNone: false
        },
        {
          id: 203,
          parentId: 2,
          name: '生产分析',
          path: '/production/analysis',
          action: false,
          displayNone: true
        },
        {
          id: 204,
          parentId: 2,
          name: '产量统计',
          path: '/production/output',
          action: false,
          displayNone: true
        },
        {
          id: 205,
          parentId: 2,
          name: '生产进度',
          path: '/production/progress',
          action: false,
          displayNone: true
        },
        {
          id: 206,
          parentId: 2,
          name: '履历查询',
          path: '/production/resume',
          action: false,
          displayNone: true
        },
        {
          id: 207,
          parentId: 2,
          name: '执行情况',
          path: '/production/execution',
          action: false,
          displayNone: true
        },
        {
          id: 208,
          parentId: 2,
          name: '生产列表',
          path: '/production/resume',
          action: false,
          displayNone: true
        },
        {
          id: 209,
          parentId: 2,
          name: '计件报表',
          path: '/production/PieceworkSystem',
          action: false,
          displayNone: true
        },
        {
          id: 210,
          parentId: 2,
          name: '计件工资',
          path: '/production/PieceworkWage',
          action: false,
          displayNone: true
        },
        {
          id: 211,
          parentId: 2,
          name: '报工记录',
          path: '/production/reportRecord',
          action: false,
          displayNone: true
        }
      ]
    },
    {
      id: 4,
      name: '质量管理',
      icon: 'zhiliang',
      path: '/inspect',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 401,
          parentId: 4,
          name: '质检配置',
          path: '/inspect/define',
          action: false,
          displayNone: false
        },
        {
          id: 404,
          parentId: 4,
          name: '质量分析',
          path: '/inspect/qualityAnalysis',
          action: false,
          displayNone: false
        },
        {
          id: 405,
          parentId: 4,
          name: 'SPC分析',
          path: '/inspect/spc',
          action: false,
          displayNone: true
        },
        {
          id: 406,
          parentId: 4,
          name: '执行报表',
          path: '/inspect/executeReport',
          action: false,
          displayNone: true
        }
      ]
    },
    {
      id: 3,
      name: '设备管理',
      icon: 'device',
      path: '/device',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 301,
          parentId: 3,
          name: '设备监控',
          path: '/device/monitor',
          action: false,
          displayNone: false
        },
        {
          id: 310,
          parentId: 3,
          name: '设备点检',
          path: '/device/Inspection',
          action: false,
          displayNone: false
        },
        {
          id: 311,
          parentId: 3,
          name: '设备保养',
          path: '/device/Upkeep',
          action: false,
          displayNone: false
        },
        {
          id: 312,
          parentId: 3,
          name: '设备维修',
          path: '/device/maintain',
          action: false,
          displayNone: false
        },
        {
          id: 307,
          parentId: 3,
          name: '维修报告',
          path: '/device/maintainReport',
          action: false,
          displayNone: false
        },
        {
          id: 303,
          parentId: 3,
          name: '备件管理',
          path: '/device/sparelist',
          action: false,
          displayNone: false
        },
        {
          id: 304,
          parentId: 3,
          name: '设备分析',
          path: '/device/analysis',
          action: false,
          displayNone: false
        },
        {
          id: 305,
          parentId: 3,
          name: '维保分析',
          path: '/device/maintainAnalysis',
          action: false,
          displayNone: false
        },
        {
          id: 306,
          parentId: 3,
          name: '异常统计',
          path: '/device/anomalyStatistics',
          action: false,
          displayNone: false
        },
        {
          id: 308,
          parentId: 3,
          name: '设备切片',
          path: '/device/deviceSlice',
          action: false,
          displayNone: false
        },
        {
          id: 309,
          parentId: 3,
          name: '历史参数',
          path: '/device/historyParams',
          action: false,
          displayNone: false
        },
        {
          id: 313,
          parentId: 3,
          name: '设备参数',
          path: '/device/params',
          action: false,
          displayNone: false
        },
      ]
    },

    {
      id: 5,
      name: '物料管理',
      icon: 'wuliao',
      path: '/logistics',
      action: false,
      displayNone: false,
      actionRouter: [
        '/logistics/flow',
        '/logistics/warehouse',
        '/logistics/analysis',
        '/logistics/stockHistory',

      ],
      leafMenuModels: [
        {
          id: 501,
          parentId: 5,
          name: '仓库管理',
          path: '/logistics/warehouse',
          action: false,
          displayNone: false
        },
        {
          id: 502,
          parentId: 5,
          name: '流转库存',
          path: '/logistics/flow',
          action: false,
          displayNone: false
        },
        {
          id: 503,
          parentId: 5,
          name: '库存分析',
          path: '/logistics/analysis',
          action: false,
          displayNone: false
        }
      ]
    },
    {
      id: 6,
      name: '追溯管理',
      icon: 'zhuisu',
      path: '/retrospect',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 601,
          parentId: 6,
          name: '追溯查询',
          path: '/retrospect/query',
          action: false,
          displayNone: false
        },
        {
          id: 602,
          parentId: 6,
          name: '追溯报告',
          path: '/retrospect/report',
          action: false,
          displayNone: false
        },
      ]
    },
    {
      id: 13,
      name: "图文管理",
      icon: 'graphic',
      path: '/graphic/view',
      action: false,
      displayNone: false,
    },
    {
      id: 7,
      name: '工具管理',
      icon: 'tool',
      path: '/tools',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 701,
          parentId: 7,
          name: '工具定义',
          path: '/tools/define',
          action: false,
          displayNone: false
        }, {
          id: 702,
          parentId: 7,
          name: '工具库管理',
          path: '/tools/wares',
          action: false,
          displayNone: false
        }, {
          id: 703,
          parentId: 7,
          name: '工具寿命管理',
          path: '/tools/life',
          action: false,
          displayNone: false
        }, {
          id: 704,
          parentId: 7,
          name: '工具维保配置',
          path: '/tools/maintain',
          action: false,
          displayNone: false
        }, {
          id: 706,
          parentId: 7,
          name: '工具维修报告',
          path: '/tools/maintainReport',
          action: false,
          displayNone: false
        }, {
          id: 705,
          parentId: 7,
          name: '工具备件管理',
          path: '/tools/spares',
          action: false,
          displayNone: false
        }
      ]
    },
    {
      id: 8,
      name: '异常响应',
      icon: 'inventorywarning1',
      path: '/abnormal',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 801,
          parentId: 8,
          name: '异常定义',
          path: '/abnormal/define',
          action: false,
          displayNone: false
        },
        {
          id: 802,
          parentId: 8,
          name: '异常呼叫',
          path: '/abnormal/call',
          action: false,
          displayNone: false
        },
        {
          id: 803,
          parentId: 8,
          name: '结果查询',
          path: '/abnormal/query',
          action: false,
          displayNone: false
        },
        // {
        //   id: 804,
        //   parentId: 8,
        //   name: '异常分析',
        //   path: '/abnormal/analysis',
        //   action: false,
        //   displayNone: false
        // }
      ]

    },
    {
      id: 14,
      name: '条码管理',
      icon: 'code',
      path: '/code/query',
      action: false,
      displayNone: false,
    },
    {
      id: 9,
      name: '知识图谱',
      icon: 'zhishitupu',
      path: '/basic',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 901,
          parentId: 9,
          name: '工厂建模',
          path: '/basic/factoryuml',
          action: false,
          displayNone: false
        },
        {
          id: 902,
          parentId: 9,
          name: '工厂日历',
          path: '/basic/factorycalendar',
          action: false,
          displayNone: false
        },
        {
          id: 905,
          parentId: 9,
          name: '设备定义',
          path: '/basic/define',
          action: false,
          displayNone: false
        },
        {
          id: 903,
          parentId: 9,
          name: '物料定义',
          path: '/basic/material',
          action: false,
          displayNone: false
        },
        {
          id: 912,
          parentId: 9,
          name: 'BOM定义',
          path: '/basic/bom',
          action: false,
          displayNone: false
        },
        {
          id: 904,
          parentId: 9,
          name: '工序定义',
          path: '/basic/process',
          action: false,
          displayNone: false
        },
        {
          id: 907,
          parentId: 9,
          name: '工艺路线',
          path: '/basic/techLine',
          action: false,
          displayNone: false
        },
        {
          id: 909,
          parentId: 9,
          name: '工艺参数',
          path: '/basic/techparams',
          action: false,
          displayNone: false
        },
        {
          id: 913,
          parentId: 9,
          name: '原因定义',
          path: '/basic/reason',
          action: false,
          displayNone: false
        },
        {
          id: 906,
          parentId: 9,
          name: '仓库定义',
          path: '/basic/warehouse',
          action: false,
          displayNone: false
        },
        {
          id: 908,
          parentId: 9,
          name: '产能定义',
          path: '/basic/capacity',
          action: false,
          displayNone: false
        },
        {
          id: 910,
          parentId: 9,
          name: '终端定义',
          path: '/basic/ipc',
          action: false,
          displayNone: false
        },
        {
          id: 911,
          parentId: 9,
          name: '消息中心',
          path: '/basic/message',
          action: false,
          displayNone: false
        },
        {
          id: 915,
          parentId: 9,
          name: "制造BOM",
          path: "/basic/bomManufacture",
          action: false,
          displayNone: false
        },
      ]
    },
    {
      id: 11,
      name: '商业智能',
      icon: 'zhineng',
      path: '/dataintel',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 1101,
          parentId: 11,
          name: '经营驾驶舱',
          path: '/dataintel/drive',
          action: false,
          displayNone: false
        },
        {
          id: 1102,
          parentId: 11,
          name: '数据报表',
          path: '/dataintel/charts',
          action: false,
          displayNone: false
        },
        {
          id: 1103,
          parentId: 11,
          name: '工厂可视化',
          path: '/dataintel/factoryvisual',
          action: false,
          displayNone: false
        },
        {
          id: 1104,
          parentId: 11,
          name: 'BI看板一',
          path: '/dataintel/bi01',
          action: false,
          displayNone: false
        },
        {
          id: 1105,
          parentId: 11,
          name: 'BI看板二',
          path: '/dataintel/bi02',
          action: false,
          displayNone: false
        },
        {
          id: 1106,
          parentId: 11,
          name: 'BI看板三',
          path: '/dataintel/bi03',
          action: false,
          displayNone: false
        },
      ]
    },
    {
      id: 15,
      name: '报表查询',
      icon: 'baobiao',
      path: '/reportForms',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 1501,
          parentId: 15,
          name: '未配置物料',
          path: '/reportForms/report1',
          action: false,
          displayNone: false
        },
        {
          id: 1502,
          parentId: 15,
          name: '操作日志',
          path: '/reportForms/report2',
          action: false,
          displayNone: false
        },
        {
          id: 1503,
          parentId: 15,
          name: '用户日志',
          path: '/reportForms/report3',
          action: false,
          displayNone: false
        },
      ]
    },
    {
      id: 16,
      name: '报表管理',
      icon: 'add',
      path: '/bigScreen',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 1601,
          parentId: 16,
          name: '大屏数据导入',
          path: '/bigScreen/dataSet',
          action: false,
          displayNone: false
        },
      ]
    },
    {
      id: 12,
      name: '系统管理',
      icon: 'system',
      path: '/system',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 1201,
          parentId: 12,
          name: '部门管理',
          path: '/system/department',
          action: false,
          displayNone: false
        },
        {
          id: 1202,
          parentId: 12,
          name: '角色管理',
          path: '/system/role',
          action: false,
          displayNone: false
        },
        {
          id: 1203,
          parentId: 12,
          name: '用户管理',
          path: '/system/user',
          action: false,
          displayNone: false
        },
        {
          id: 1204,
          parentId: 12,
          name: '系统菜单',
          path: '/system/menu',
          action: false,
          displayNone: false,
        },
        {
          id: 1205,
          parentId: 12,
          name: '租户管理',
          path: '/system/company',
          action: false,
          displayNone: false,
        },
        {
          id: 1206,
          parentId: 12,
          name: '配置页面',
          path: '/system/config',
          action: false,
          displayNone: false,
        },
      ]
    },
    {
      id: 17,
      name: '配置管理',
      icon: 'system',
      path: '/config',
      action: false,
      displayNone: false,
      leafMenuModels: [
        {
          id: 1701,
          parentId: 17,
          name: '质检项',
          path: '/config/inspect',
          action: false,
          displayNone: false
        },
        {
          id: 1702,
          parentId: 17,
          name: '充电桩型号',
          path: '/config/model',
          action: false,
          displayNone: false
        },
        {
          id: 1703,
          parentId: 17,
          name: '基础工序',
          path: '/config/procedure',
          action: false,
          displayNone: false
        },
        {
          id: 1704,
          parentId: 17,
          name: '工序质检方案',
          path: '/config/procedureInspectScheme',
          action: false,
          displayNone: false
        },
        {
          id: 1705,
          parentId: 17,
          name: '产线工人',
          path: '/config/worker',
          action: false,
          displayNone: false
        },
        {
          id: 1706,
          parentId: 17,
          name: '产线工种',
          path: '/config/workerType',
          action: false,
          displayNone: false
        },
        {
          id: 1707,
          parentId: 17,
          name: '工艺路径',
          path: '/config/techline',
          action: false,
          displayNone: false
        },
      ]
    }
  ]
};

/* 设备点检 3级菜单 */
export const inspectList = [
  {
    id: 301,
    parentName: '设备点检',
    name: '点检库',
    displayNone: false,
  },
  {
    id: 302,
    parentName: '设备点检',
    name: '点检方案',
    displayNone: false,
  },
  {
    id: 303,
    parentName: '设备点检',
    name: '点检计划',
    displayNone: false,
  },
  {
    id: 304,
    parentName: '设备点检',
    name: '点检查询',
    displayNone: false,
  }
]

export const maintainList = [
  {
    id: 401,
    parentName: '设备保养',
    name: '保养库',
    displayNone: false,
  },
  {
    id: 402,
    parentName: '设备保养',
    name: '保养方案',
    displayNone: false,
  },
  {
    id: 403,
    parentName: '设备保养',
    name: '保养计划',
    displayNone: false,
  },
  {
    id: 404,
    parentName: '设备保养',
    name: '保养查询',
    displayNone: false,
  }
]

// 媒体查询 ： 条件
export const Media_Query = {
  'screen-xs': {
    maxWidth: 575
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599
  },
  'screen-xxl': {
    minWidth: 1600
  }
};
export const s_size = ['screen-xs'];
export const m_size = ['screen-sm', 'screen-md'];
export const l_size = ['screen-lg', 'screen-xl', 'screen-xxl'];
export const imgUrl = 'http://yunqimom.boranet.cn';