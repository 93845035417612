import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';

class Message {
    @observable isLoading = false;
    @observable tableData = [];
    @observable PageInfo  = {};
    @observable bomList = []
    @observable workDetail = []
    @observable workPage = {}
    @observable workId = ''
    @observable isNestedLoading = false;

    //获取表格数据
    @action async getData(params) {
        this.isLoading = true
        try {
            let res            = await services.getRequest(services.requestUrl.resultData, params)
                this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo  = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 获取所有物料信息
    @action async getBomList(){
        try {
            let res = await services.getRequest(services.requestUrl.getBomList,{})
            if(isDataExist(res)){
                this.bomList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 获取所有物料信息
    @action async getWorkInfo(params){
        this.isNestedLoading = true
        try {
            let data = {
                pageIndex:1,pageSize:10,
                ...params,
            }
            let res = await services.getRequest(services.requestUrl.getWorkInfo,data)
            this.isNestedLoading = false
            if(isDataExist(res)){
                this.workDetail = res.data.data.items;
                this.workPage = res.data.data.page;
            }
        } catch (error) {
            console.log(error)
        }
    }
}

let MessageStore = new Message();
export default MessageStore;