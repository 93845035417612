/**
 * @description 通用配置store,包括了表格配置title
 */
import { observable, action,toJS } from "mobx";
import { isDataExist } from "Utils/dataTools";
import * as services from "../services/plan";
import SettingStore from './SettingConfigStore';
import { message, } from "antd";
import { get,uniq } from 'lodash'

class Plan {
  constructor(){
    this.settings = SettingStore
  }

  /* 配置弹窗内容 */
  @observable bomSearch = []
  @observable planSearch = []
  @observable fieldParams = {}
  @observable columnList = []
  @observable columnRelativeList = []
  @observable formData = {}
  @observable autoSche =false
  @observable limitVisible = false


  /* 列表内容 */
  @observable selectedRowKeys = []
  @observable selectAllOrder = false
  @observable notSelectedRowKeys = []


  @observable isLoading = false
  @observable tableData = []
  @observable tablePage = {pageIndex:1,pageSize:50}
  @observable column = []

  /* 排程弹窗内容 */
  @observable pageKey = ''
  @observable scheType = ''
  @observable bomSelect = {}
  @observable bomValues = []
  @observable modalVisible = false

  /* 生产流转卡 */
  @observable transferDataTitle = []
  @observable transferDataBody = []
  @observable postLoading = false

  @observable ganttLoading = false
  @observable handleList = false
  
  /* 搜索区内容 */
  @action.bound async getBomAll(params){
    try {
      let res = await services.getRequest(services.requestUrl.getBomAll,{...params,pageSize:10000})
      if(isDataExist(res)){
        this.bomSearch = res.data.data.items
      }
    } catch (error) {
      console.log(error)
    }
  }
  @action.bound async getPlanAll(params){
    try {
      let res = await services.getRequest(services.requestUrl.getPlanAll,{...params,pageSize:10000})
      if(isDataExist(res)){
        this.planSearch = res.data.data.items
      }
    } catch (error) {
      console.log(error)
    }
  }

  /* 设置列表全选的内容 */
  @action.bound async onSelectAllOrder(selected, selectedRows, changeRows,type){
    try {
      this.selectAllOrder = selected;
      if(selected){
        await this.fetAllPlanNo({pattern: type === 'preSche' ? 1 : 2}).then(()=>{
          this.selectedRowKeys = this.allPlanNo; 
        })
        // for(let i=0,item;item = selectedRows[i++];){
        //   this.selectedRowKeys.push(item.planNo)
        // }
      }else{
        this.selectedRowKeys = []
      }
    } catch (error) {
      console.log(error)
    }
  }
  /* 获取排程table */
  @action.bound catchScheTable(selected,selectedKey){
    try {
      let index = -1,notIndex = -1;
      index = this.selectedRowKeys.findIndex(it=>it === selectedKey)
      notIndex = this.notSelectedRowKeys.findIndex(it=> it===selectedKey)
      if(selected){ //选中
        if(index < 0){
          this.selectedRowKeys.push(selectedKey)
        }
        if(notIndex > -1){
          this.notSelectedRowKeys.splice(notIndex,1)
        }
      }else{ //反选
        if(index > -1){
          this.selectedRowKeys.splice(index,1)
        }
        if(notIndex < 0){
          this.notSelectedRowKeys.push(selectedKey)
        }

      }
    } catch (error) {
      console.log(error)
    }
  }

  /* 获取列表信息 */
  @action.bound async getData(params){
    this.isLoading = true
    this.tableData = []
    try {
      let url = params.type === 'preSche' ? services.requestUrl.listByPage : services.requestUrl.listByPage2
      let res = await services.getRequest(url,{
        ...this.tablePage,
        ...this.fieldParams,
        ...params
      })
      this.isLoading =  false
      if(isDataExist(res)){
        /* 根据是否全选确定分页请求的order合并 */
        let page = res.data.data.page,data = res.data.data.items,selectedRowKeys = [];
        
        /* 全选操作 */
        // if(this.selectAllOrder){
        //     for(let i=0, item;item = data[i++];){
        //       let index = -1;
        //       index = this.notSelectedRowKeys.findIndex(it=>it === item.planNo)
        //       if(index > -1){
        //         continue;
        //       }
        //       selectedRowKeys.push(item.planNo)
        //     }
        //   }
        
        // this.selectedRowKeys = uniq(this.selectedRowKeys.concat(selectedRowKeys))

        if(params.type === 'advancedList'){
          for(let item of data){
            Object.assign(item,{children:[]})
          }
        }
        this.tableData = data
        this.tablePage = page
        this.column = params.type === 'preSche' ? [
          {
            title:'项目号',
            dataIndex:'projectNo',
            key:'projectNo'
          },{
            title:'订单号',
            dataIndex:'planNo',
            key:'planNo'
          },{
            title:'物料编码',
            dataIndex:'materialCode',
            key:'materialCode'
          },{
            title:'物料名称',
            dataIndex:'materialName',
            key:'materialName'
          },{
            title:'物料数量',
            dataIndex:'num',
            key:'num'
          },{
            title:'行号',
            dataIndex:'lineNo',
            key:'lineNo'
          },{
            title:'订单优先级',
            dataIndex:'priority',
            key:'priority'
          },{
            title:'交付日期',
            dataIndex:'dueTime',
            key:'dueTime'
          }
        ] : [
          {
            title:'项目号',
            dataIndex:'projectNo',
            key:'projectNo'
          },{
            title:'工单号',
            dataIndex:'planNo',
            key:'planNo'
          },{
            title:'当前正在执行的工序',
            dataIndex:'procedureName',
            key:'procedureName',
            width:130,
            render:value => value || '-'
          },{
            title:'物料编码',
            dataIndex:'materialCode',
            key:'materialCode'
          },{
            title:'物料名称',
            dataIndex:'materialName',
            key:'materialName'
          },{
            title:'物料数量',
            dataIndex:'num',
            key:'num'
          },{
            title:'订单优先级',
            dataIndex:'priority',
            key:'priority'
          },{
            title:'交付日期',
            dataIndex:'dueTime',
            key:'dueTime'
          },{
            title:'开始日期',
            dataIndex:'createTime',
            key:'createTime'
          }
        ]
      }
      
    } catch (error) {
      console.log(error)
    }
  }

  /* 获取所有订单的订单号用于跳转 */
  @observable allPlanNo = []
  @action.bound async fetAllPlanNo(params){
    try {
      let res = await services.getRequest(services.requestUrl.allPlanNo,params)
      if(isDataExist(res)){
        this.allPlanNo = res.data.data;
      }
    } catch (error) {
      console.log(error)
    }
  }
  /* 请求所有的物料及订单信息 */
  @observable materialList = []
  @observable planNoList = []
  @action.bound async getAllMaterial(params){
    this.materialList = []
    try {
      let res = await services.getRequest(services.requestUrl.getAllMaterial,params)
      if(isDataExist(res)){
        this.materialList = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  @action.bound async getAllPlanNo(params){
    try {
      let res = await services.getRequest(services.requestUrl.getAllPlanNo,params)
      if(isDataExist(res)){
        this.planNoList = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  /* 获取数据信息 */
  @action.bound async getOrderList(params){
    try {
      await this.settings.getData({
        url:params.type === 'preOrderSche' ? services.requestUrl.listByOrder : services.requestUrl.listByPage2,
        params:{
          ...this.fieldParams,
          ...params
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  @action.bound async sync(params,type){
    this.isLoading = true
    try {
      let url = type === 'preSche' ? services.requestUrl.preSycn: services.requestUrl.advancedSycn
      let res = await services.putRequest(url,params)
      this.isLoading = false
      if(isDataExist(res)){
        return true
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }

  @action.bound async postScheExt(params,type){
    this.postLoading = true
    try {
      let url = type === 'preSche' ? services.requestUrl.postScheExt : services.requestUrl.listByOrderAdvan
      let res = await services.putRequest(url,params)
      this.postLoading = false
      if(isDataExist(res)){
        return true
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }

  /* 获取显隐list */
  @action.bound getShowHiddenList(params){
    this.settings.isShowSettingDialog = !this.settings.isShowSettingDialog
    this.settings.getShowHiddenList(params)
  }

  

  //打开/关闭模态框
  @action.bound modalChange = async (tag, record,type) => {
    this.id = record && record.id;
    if(!this.modalVisible){
      this.getAllMaterial()
      if(type !== 'preSche') this.getAllPlanNo();
    }
    if (tag === 2) {
      // 编辑
      this.pageKey = "edit";
      this.formData = record
    } else if (tag === 1) {
      // 新增
      this.pageKey = "add";
      this.formData = {};
    } else {
      this.formData = {};
    }
    this.modalVisible = !this.modalVisible;
  };
   // 编辑设备
   @action.bound async getOneDetail(params) {
    this.isLoading = true;
    const url = services.requestUrl.getDataById;
    try {
      let res = await services.getRequest(url, params);
      this.isLoading = false;
      if (isDataExist(res)) {
        this.formData = get(res, "data.data");
        let formData = get(res, "data.data");
        let columnRelativeList = {};
        formData.forEach(async formItem => {
          let item = formItem.pagePropertyDo;
          if (item.fieldType === "关联关系") {
            let result = await services.getRequest(
              `${item.relativeUrl}`
            );
            if(isDataExist(result)){
              columnRelativeList = {
                ...columnRelativeList,
                [item.fieldKey]: result.data&&result.data.data
              };
              this.columnRelativeList = columnRelativeList;
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
   // 新增或者编辑获取数据；
   @action async getColumnList(params) {
    try {
      let res = await services.getRequest(
        services.requestUrl.getMarkByProperties,
        params
      );
      if (isDataExist(res)) {
        let columnList = get(res, "data.data");
        let columnRelativeList = {};
        columnList.forEach(async item => {
          if ([6,7].includes(item.dataType)) {
            let result = await services.getRequest(
              `${item.relativeUrl}`
            );
            if(isDataExist(result)){
              columnRelativeList = {
                ...columnRelativeList,
                [item.fieldKey]: result.data&&result.data.data
              };
              this.columnRelativeList = columnRelativeList;
            }
          }
        });
        this.columnList = get(res, "data.data");
      }
    } catch (error) {
      console.log(error);
    }
  }
  //删除
	@action.bound async deleteScheItem( params,type) {
		try {
      let url = type === 'preSche' ? services.requestUrl.deletePreItem : services.requestUrl.deleteAdvancedItem
			let res = await services.putRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
        message.success('删除成功')
        this.getData({type})
        return true
			}
		} catch (error) {
			console.log(error);
		}
  }
  //新增-保存设备
	@action.bound async saveScheInfo(type, params) {
		console.log('type:', type,'params', params);
    this.isLoading = true;
    let url = ''
    switch(type){
      case 'preAdd':
      case 'preEdit':
        url = services.requestUrl.addPreScheInfo;
        break;
      case 'advancedAdd':
      case 'advancedEdit':
        url = services.requestUrl.addAdvancedScheInfo;
        break;
      default:break;
    }
		try {
			let res = await services.putRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}

  @action async catchBombyPlan({type,key,params}){
    try {
      // 根据每一条获取制造BOM
      this.bomSelect[key] = []
      this.bomSelect[key] = await this.getBomByName(params)
      this.scheType = type
    } catch (error) {
      console.log(error)
    }
  }
  @action async getBomByName(params){
    try {
      let res = await services.getRequest(services.requestUrl.getBomByPlan,{
        pageSize:20,pageIndex:1,...params
      })
      if(isDataExist(res)){
        return res.data.data.items
      }
    } catch (error) {
      console.log(error);
      
    }
  }

  @action async saveSche(params){
    try {
      let res = await services.putRequest(services.requestUrl.saveSche,params)
      if(isDataExist(res)){
        message.success('进入排程...')
        return res.data.data
      }else{
        message.error('操作失败，无法进入排程...')
        return false
      }
    } catch (error) {
      console.log(error)
    }
  }

  /* 获取生产流转卡的信息 */
  @action async getTransferDs(params){
    this.postLoading = true
    try {
      let res = await services.getRequest(services.requestUrl.getTransferData,params)
      this.postLoading = false
      if(isDataExist(res)){
        this.transferDataTitle = res.data.data.productPlanVO;
        this.transferDataBody = res.data.data.planWorkDataDoList;
        return res.data.data.planWorkDataDoList
      }

    } catch (error) {
      console.log(error)
    }
  }

  @action.bound clearData(){
    this.bomSearch = []
    this.planSearch = []
    this.fieldParams = {}
    this.tablePage = { pageIndex:1,pageSize:50 };

    /* 列表内容 */
    this.selectedRowKeys = []
    this.selectAllOrder = false
    this.selectedPage = []

    this.settings.clearAllData()
  }


  /* 资源负荷表 */
  @observable loadData = []
  // @observable loadPage = {pageIndex:1,pageSize:10}
  @observable loading = false
  @observable detailRecord = {}
  @observable detailVisible = false
  @observable loadOrderData = []
  @observable resourceList = []
  @observable resourceAll = []
  @observable resourceGroup = []
  @observable operateVisible = false
  @observable operateStatus = ''
  @observable transferResource = []
  @observable transferTarget = []
  @observable orderLoading = false
  @observable btnLoading = false

  @action.bound async getLoadData(params){
    this.loading = true
    try {
      let res = await services.getRequest(services.requestUrl.getLoadData,{
        // ...this.loadPage,
        ...params})
      this.loading = false
      if(isDataExist(res)){
        this.loadData = res.data.data;
        // this.loadPage = res.data.data.page;
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  @action.bound async getLoadOrder(params){
    this.orderLoading = true
    this.loadOrderData = []
    try {
      let res = await services.getRequest(services.requestUrl.getLoadOrder,params)
      this.orderLoading = false
      if(isDataExist(res)){
        this.loadOrderData = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  /* 查询未分配资源list */
  @action.bound async getResourceList(params){
    try {
      let res = await services.getRequest(services.requestUrl.getResourceList,params)
      if(isDataExist(res)){
        this.resourceList = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  /* 查询未分配资源list */
  @action.bound async getResourceAll(params){
    try {
      let res = await services.getRequest(services.requestUrl.getResourceAll,params)
      if(isDataExist(res)){
        this.resourceAll = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  /* 更换资源list */
  @action.bound changeKeyValue({key,value,list,record}){
    try {
      let data = JSON.parse(JSON.stringify(this[list]))
      let index = data.findIndex(it=>it.id === record.id)
      if(index > -1){
        Object.assign(data[index],{
          [key]:value,
          isNew:true
        })
      }
      this[list] = data
    } catch (error) {
      
    }
  }
  /* 保护资源更换 */
  @action async saveChangeResWithOrder(params){
    try {
      let res = await services.putRequest(services.requestUrl.saveChangeResWithOrder,params)
      if(isDataExist(res)){
        message.success('更换资源成功')
        this.detailVisible = false
        return true
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  @action async getResourceGroup(params){
    try {
      let res = await services.getRequest(services.requestUrl.getResourceGroup,params)
      if(isDataExist(res)){
        this.resourceGroup = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  @action async deleteLoadItem(params){
    try {
      let res = await services.putRequest(services.requestUrl.deleteLoadItem,params)
      if(isDataExist(res)){
        message.success('删除成功')
        return true
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  @action async saveResGroup(params){
    this.btnLoading = true
    try {
      let requestUrl = this.operateStatus !== 'add' ? services.requestUrl.editResGroup : services.requestUrl.addResGroup;
      if(this.operateStatus === 'edit'){
        params.id = this.detailRecord.groupId
      }
      let res = await services.putRequest(requestUrl,params)
      this.btnLoading = false
      if(isDataExist(res)){
        message.success('保存成功')
        this.transferResource = []
        this.transferTarget = []
        return true
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  @action async saveResGroupByDeviceId(params){
    try {
      let res = await services.getRequest(services.requestUrl.saveResGroupByDeviceId,params)
      if(isDataExist(res)){
        return true
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  @action async getTransferResource(params){
    try {
      let res = await services.getRequest(services.requestUrl.getResourceList,params)
      if(isDataExist(res)){
        for(let item of res.data.data){
          Object.assign(item,{
            key:item.id,
            title:item.name,
          })
        }
        this.transferResource = res.data.data
        return res.data.data
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  @action async getTransferTarget(params){
    try {
      let res = await services.getRequest(services.requestUrl.transferTarget,params)
      if(isDataExist(res)){
        for(let item of res.data.data){
          Object.assign(item,{
            key:item.id,
            title:item.name,
          })
        }
        this.transferTarget = res.data.data
        return res.data.data
      }
    } catch (error) {
      console.log(error);
      
    }
  }

  /* 历史负荷图 */
  @observable historyPage = {
    pageIndex:1, pageSize:50
  }
  @observable historyData = []

  @action.bound async getHistoryData(params){
    this.isLoading = true
    try {
      let res = await services.getRequest(services.requestUrl.getHistoryData,params)
      this.isLoading = false
      if(isDataExist(res)){
        this.historyData = res.data.data
        // this.historyPage = res.data.data.page
      }
    } catch (error) {
      console.log(error)
    }
  }
  

  /* 额定产能参数设置 */
  @observable limitData = {}
  @observable limitGet = false
  @action.bound async getLimit(params,type){
    try {
      this.isLoading = true
      let res = await services.getRequest(services.requestUrl.getLimit,params)
      this.isLoading = false
      if(isDataExist(res)){
        this.limitData = res.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  @action.bound async saveLimit(params,type){
    try {
      this.isLoading = true
      let res = await services.putRequest(services.requestUrl.saveLimit,params)
      this.isLoading = false
      if(isDataExist(res)){
        message.success('保存成功')
        this.limitVisible = false
        this.limitGet = true
      }
    } catch (error) {
      console.log(error)
    }
  }

}

let PlanStore = new Plan();
export default PlanStore;
