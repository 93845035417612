/*
 * @catalogue: 知识图谱-原因定义
 * @Description: store
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-01-12 13:05:15
 */
import { observable, action, } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { message } from 'antd';
import { omit } from 'lodash'

class Reason {
  @observable isLoading = false
  @observable tableList = []
  @observable tablePage = {pageIndex:1,pageSize:10}
  @observable processList = []
  @observable searchOptions = {};

  /* 弹窗 */
  @observable formData = {}
  @observable visible = false
  @observable selectedRowKeys = []
  @observable plantList = []
  @observable DepList = []

  @action.bound async getProcess(params) {
    try {
      this.isLoading = true
      let res = await services.getRequest(services.requestUrl.processNoPage,params);
      this.isLoading = false
      if(isDataExist(res)){
        this.processList = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action.bound async getData(params) {
    try {
      this.isLoading = true
      let res = await services.getRequest(services.requestUrl.reasonList,{
        ...omit(this.tablePage,'total'),
        ...this.searchOptions,
        ...params,
      });
      this.isLoading = false
      if(isDataExist(res)){
        this.tablePage = res.data.data.page
        this.tableList = res.data.data.items;
      }
    } catch (error) {
      console.log(error);
    }
  }

 
  @action async deleteItem(params){
    try {
      let res = await services.putRequest(services.requestUrl.deleteReason,params)
      if(isDataExist(res)){
        message.success('删除成功！')
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action async saveItem(params){
    try {
      let res = await services.putRequest(services.requestUrl.saveReason,params)
      if(isDataExist(res)){
          return res  
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action modalChange(obj){
    this.formData = obj.record || {};
    this.visible = obj.visible;
  }
  @action changeValue(key, value) {
    this[key] = value;
  }
  @action clearData(){
    this.isLoading = false
    this.tableList = []
    this.tablePage = {pageIndex:1,pageSize:10}
    this.name = ''
    this.searchOptions = {};
  
    /* 弹窗 */
    this.formData = {}
    this.visible = false
    this.selectedRowKeys = []
    this.plantList = []
  }
}
let FactoryUMLStore = new Reason();
export default FactoryUMLStore;