/*
 * @catalogue: 异常响应
 * @Description: store
 * @Author: 黄红燕
 * @Date: 2020-12-08 17:21:26
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-01-11 15:37:14
 */
import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/abnormal';
import { omit } from 'lodash';

class Abnormal {
    @observable isLoading = false;
    @observable formData = {};//异常定义form表单
    @observable modalVisible = false;//结果查询模态框switch
    @observable tableData = [];//异常类型表格数据
    @observable PageInfo = {};//异常类型表格数据
    @observable AbnormalTypeList = [];//异常类型数据
    @observable abnormalList = [];//异常数据
    @observable userList = [];//用户列表
    @observable devList = [];//设备数据
    @observable selectedRows = [];//异常类型id
    @observable radioType=1 //异常定义-是否分级上报
    @observable awaitReportTime='' //异常定义-等待时间
    @observable searchOptions = {};

    //获取表格数据
    /**
     * @Description: 异常管理-异常定义/结果查询/异常类型/异常呼叫表格数据
     * @param {string} type 1: 定义列表  2: 结果列表  3:类型列表  4: 呼叫列表
     * @param {object} params 查询条件
     */
    @action.bound async getData(type, params) {
        this.isLoading = true
        let urlList = {
            1: services.requestUrl.defineList,
            2: services.requestUrl.resultList, 
            3: services.requestUrl.typeList,
            4: services.requestUrl.callList
        }
        try {
            let res = await services.getRequest(urlList[type], {
                ...omit(this.PageInfo, 'total'),
                ...this.searchOptions,
                ...params
            })
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取异常类型
    @action.bound async getAbnormalTypeList(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.allTypeList, params)
            
            this.isLoading = false
            if (isDataExist(res)) {
                this.AbnormalTypeList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取异常列表
    @action.bound async getAbnormalList(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.abnormalList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.abnormalList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //响应异常列表
    @action.bound async toResponse(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.toResponse, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取异常列表
    @action.bound async deleteAbnormal( params) {
        this.isLoading = true
        const url =  services.requestUrl.removeAbnormal
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取异常列表
    @action.bound async deleteType(type, params) {
        this.isLoading = true
        const url = type ? services.requestUrl.removeType : services.requestUrl.batchRemoveType
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //处理异常列表
    @action.bound async toHandle(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.toHandle, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //保存异常列表
    @action.bound async saveCall(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.saveCall, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取异常列表
    @action.bound async saveAbnormal(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.saveAbnormal, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取异常列表
    @action.bound async saveType(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.saveType, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取设备
    @action.bound async getDevList(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.devList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.devList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取用户信息
    @action.bound async getUserList(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.userList, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.userList = res.data.data.items
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * 获取所有车间
     * @param {Object} params 
     */
    @observable workshopList = []
    @action.bound async getWorkshopList(params){
        try{
            let res = await services.getRequest(services.requestUrl.getWorkshopList, params)
            if(isDataExist(res)){
                this.workshopList = res.data.data
            }
        }
        catch(error){
            console.log(error)
        }
    }

    //打开/关闭模态框type=1为编辑，否则是新增
    @action.bound modalChange = (type, record) => {
        if (type) {
            this.formData = record
        } else {
            this.formData = {}
        }
        this.radioType='1'
        this.awaitReportTime=''
        this.modalVisible = !this.modalVisible
    }
    /**
     * @Description: 清除数据
     */
    @action.bound clearData = () => {
        this.isLoading = false;
        this.formData = {};
        this.modalVisible = false;
        this.tableData = [];
        this. PageInfo = {};
        this.AbnormalTypeList = [];
        this.abnormalList = [];
        this.userList = [];
        this.devList = [];
        this.selectedRows = [];
        this.radioType=1 
        this.awaitReportTime='' 
        this.searchOptions = {};
    }
}

let AbnormalStore = new Abnormal();
export default AbnormalStore;