/*
 * @catalogue: 知识图谱-工序定义
 * @Description: store
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-01-12 12:52:52
 */
import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { message } from 'antd';
import { omit } from 'lodash';

class Process {
	/* 工序定义 */
	@observable isLoading = false;
	@observable tableData = [];
	@observable typeList = [];
	@observable PageInfo = {};
	@observable formData = {};
	@observable modalVisible = false;
	@observable treeData = [] //树形控件
	@observable searchValue = '' //已选择物料
	@observable searchOptions = {};

	//获取表格数据
	/**
     * @Description: 知识图谱-工序定义表格数据
     * @param {object} 查询条件
     * @return {*}
     */
	@action.bound async getData(params) {
		this.isLoading = true;
		try {
			let res = await services.getRequest( services.requestUrl.processList,
				{
					...omit(this.PageInfo, 'total'),
					...this.searchOptions,
					...params
				}
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.tableData = res.data.data.items;
				this.PageInfo = res.data.data.page;
			}
		} catch (error) {
			console.log(error);
		}
	}
	//获取全部车间-下拉框的数据
	@action.bound async getWorkshopList() {
		this.isLoading = true
		try {
			let res = await services.getRequest(services.requestUrl.getWorkshopList)
			this.isLoading = false
			if (isDataExist(res)) {
				this.treeData = res.data.data
			}
		} catch (error) {
			console.log(error)
		}
	}
	//根据车间获取设备-下拉框的数据
	@action.bound async getDeviceListByWorkShop(params) {
		this.isLoading = true
		try {
			let res = await services.getRequest(services.requestUrl.getDeviceListByWorkShop, params)
			this.isLoading = false
			if (isDataExist(res)) {
				// this.treeData = res.data.data
				return res.data.data
			}
		} catch (error) {
			console.log(error)
		}
	}
	//获取设备类型
	@action.bound async getDevType(params) {
		this.isLoading = true;
		try {
			let res = await services.getRequest(
				services.requestUrl.devTypeList,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.typeList = res.data.data.items;
			}
		} catch (error) {
			console.log(error);
		}
	}
	//获取设备类型
	@action.bound async addNew(params) {
		this.isLoading = true;
		try {
			let res = await services.putRequest(services.requestUrl.saveProcedure, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	//获取设备类型
	@action.bound async deletePro(params) {
		this.isLoading = true;
		try {
			let res = await services.putRequest(
				services.requestUrl.deleteProcedure,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				message.success('删除成功！')
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}

	/* 工艺参数定义 */
	@observable deviceId = '';
	@observable deviceList = []
	@observable materialList = []
	@observable deviceName = ''
	@observable procedureList = []

	@action.bound async getTechParams(params) {
		this.isLoading = true;
		try {
			let res = await services.getRequest(services.requestUrl.paramsList, {
				deviceId: this.deviceId,
				...params
			});
			this.isLoading = false;
			if (isDataExist(res)) {
				this.tableData = res.data.data.items;
				this.PageInfo = res.data.data.page;
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 新增参数
	@action.bound async addParams(params) {
		this.isLoading = true;
		const url = services.requestUrl.saveParams;
		try {
			let res = await services.putRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 删除参数
	@action.bound async deleteParams(params) {
		this.isLoading = true;
		try {
			let res = await services.putRequest(
				services.requestUrl.deleteParams,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}

	@action.bound async getDeviceList(params) {
		try {
			let res = await services.getRequest(
				services.requestUrl.getDeviceList, params
			)
			if (isDataExist(res)) {
				this.deviceList = res.data.data.items;
			}
		} catch (error) {
			console.log(error)
		}
	}
	@action.bound async getBomList(params) {
		try {
			let res = await services.getRequest(
				services.requestUrl.allBomList, params
			)
			if (isDataExist(res)) {
				this.materialList = res.data.data;
			}
		} catch (error) {
			console.log(error)
		}
	}

	/**
	 * 获取工序列表
	 * @param {Object} params
	 */
	@action.bound async getProcedureList(params) {
		try {
			let res = await services.getRequest(services.requestUrl.listProcedure, params)
			if (isDataExist(res)) {
				this.procedureList = res.data.data
			}
		}
		catch (error) {
			console.log(error)
		}
	}

	/* 通用使用------ */
	//打开/关闭模态框
	@action.bound modalChange = (type, record) => {
		if (type) {
			this.formData = record;
		} else {
			this.formData = {};
		}
		this.modalVisible = !this.modalVisible;
	};
	@action clearData() {
		this.isLoading = false;
		this.tableData = [];
		this.typeList = [];
		this.PageInfo = {};
		this.formData = {};
		this.modalVisible = false;
		this.techParamsName = '';
		this.deviceList = []
		this.materialList = []
		this.searchOptions = {};
	}
}

let ProcessStore = new Process();
export default ProcessStore;
