import { observable, action, toJS, } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';


class Order {
    @observable isLoading = false;
    @observable tableData = [];
    @observable PageInfo = {};
    @observable formData = {};
    @observable modalVisible = false;
    @observable searchOptions = {}
    //获取表格数据
    @action.bound async getData(params) {
        this.isLoading = true
        try {
            let data = {
                pageIndex:1,pageSize:10,
                ...this.searchOptions,...params
            }
            let res = await services.getRequest(services.requestUrl.orderList, data)
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    //保存更新信息
    @action.bound async addNew(type, params) {
        this.isLoading = true
        const url = type ? services.requestUrl.saveOrder : services.requestUrl.updateOrder
        try {
            let res = await services.putRequest(url, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //删除
    @action.bound async deleteCol(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.removeOrder, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //打开/关闭模态框
    @action modalChange = (type, record) => {
        if (type) {
            this.formData = record
        } else {
            this.formData = {}
        }
        this.modalVisible = !this.modalVisible
    }
}
let OrderDefineStore = new Order();
export default OrderDefineStore;