/*
 * @catalogue: 知识图谱-物料定义/Bom定义
 * @Description: 物料定义/Bom定义store
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-03-15 15:13:09
 */
import { observable, action, toJS } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { omit } from 'lodash';
import { message } from 'antd';

class Material {
	@observable isLoading = false;
	@observable tableData = [];
	@observable arr = []; //数据转换时过度数据
	@observable selectedRows = []; //表格被选中的行
	@observable PageInfo = { pageIndex: 1, pageSize: 10 };
	@observable formData = {};
	@observable type = ''; //查询条件
	@observable operateType = '';
	@observable modalVisible = false;
	@observable searchOptions = {};
	@observable btnLoading = false;
	@observable zynTarget = []

	/* 测试数据的切换能力  --- Bom定义 */
	@observable tableVisible = false;
	@observable expandedRowKeys = []

	/* 增加下级 */
	@action.bound async addChild(params) {
		this.isLoading = true;
		try {
			let res = await services.putRequest(services.requestUrl.addBom, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	/* 同步信息 */
	@action.bound async onSyncData(type) {
		this.btnLoading = true;
		try {
			let req = type
				? services.requestUrl.syncBom
				: services.requestUrl.syncMaterial;
			let res = await services.getRequest(req);
			this.btnLoading = false;
			if (isDataExist(res)) {
				message.success('同步成功');
			}
		} catch (error) {
			message.success('同步失败');
			console.log(error);
		}
		this.getData(type);
	}

	//新增物料 / Bom
	@action.bound async addNew(type, params) {
		this.isLoading = true;
		const url = type
			? services.requestUrl.saveBom
			: services.requestUrl.saveMaterial;
		try {
			let res = await services.putRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	//编辑物料 / Bom
	@action.bound async editBom(type, params) {
		this.isLoading = true;
		const url = type
			? services.requestUrl.enitBom
			: services.requestUrl.saveMaterial;
		try {
			let res = await services.putRequest(url, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	//删除物料 / Bom
	@action.bound async deleteBom(params) {
		this.isLoading = true;
		//1删除，0批量删除
		try {
			let res = await services.putRequest(
				services.requestUrl.removeBom,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	@action.bound async deleteMaterial(params) {
		this.isLoading = true;
		//1删除，0批量删除
		try {
			let res = await services.putRequest(
				services.requestUrl.removeMaterial,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	//打开/关闭模态框
	@action.bound modalChange(type, record) {
		this.operateType = type;
		if (type) {
			this.formData = record;
		} else {
			this.formData = {};
		}
		this.modalVisible = !this.modalVisible;
	}
	//获取表格数据
	/**
     * @Description: 知识图谱-物料定义表格数据
     * @param {number} type 0物料定义 1Bom定义
	 * @param {object} params 查询条件
     */
	@action.bound async getData(type, params) {
		this.isLoading = true;
		this.tableData = [];
		try {
			let data = {
				...omit(this.PageInfo, 'total'),
				...this.searchOptions,
				...params
			};
			let req = type
				? services.requestUrl.bomNoPagedList
				: services.requestUrl.getMaterial;
			let res = await services.getRequest(req, data);
			this.isLoading = false;
			if (isDataExist(res)) {
				if (type) {
					for (let item of res.data.data.items) {
						item.children = [];
					}
					this.tableData = res.data.data.items;
					this.zynTarget = res.data.data.items;
					this.PageInfo = res.data.data.page;
				} else {
					this.tableData = res.data.data.items;
					this.zynTarget = res.data.data.items;
					this.PageInfo = res.data.data.page;
				}
				this.expandedRowKeys = []
			}
		} catch (error) {
			console.log(error);
		}
	}
	//请求bom下级数据
	@action.bound async getBomSec(params) {
		this.isLoading = true;
		this.tableData = []
		try {
			let res = await services.getRequest(
				services.requestUrl.getBomSec,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				res.data.data.map(item => {
					item.targetName = params.materialName
				})
				let list = this.setChildren({
					dataS: JSON.parse(JSON.stringify(this.zynTarget)),
					resData: res.data.data,
					sourceId: params.sourceId
				});
				this.tableData = list;
				this.zynTarget = list;
			}
		} catch (error) {
			console.log(error);
		}
	}

	/* 切换BOM key 获取数据 */
	@action modifyRowClick(value) {
		let index = -1
		index = this.expandedRowKeys.findIndex(item => item === value)
		if (index < 0) {
			this.expandedRowKeys.push(value)
		}
		else {
			this.expandedRowKeys.splice(index, 1)
		}
	}

	setChildren = ({ dataS, resData, sourceId }) => {
		dataS.map(item => {
			if (sourceId === item.sourceId) {
				Object.assign(item, {
					children: resData
				});
			} else {
				this.setChildren({
					dataS: item.children,
					resData,
					sourceId
				});
			}
			return item.id;
		});
		return dataS;
	};

	@observable materialList = [];
	// 获取物料
	@action async getAllMaterial(params) {
		try {
			let res = await services.getRequest(services.requestUrl.getBomList, params);
			if (isDataExist(res)) {
				this.materialList = res.data.data;
			}
		} catch (error) {
			console.log(error);
		}
	}
	//数据类型转换(平级数据装换成树结构)
	@action.bound transTreeData = items => {
		if (items.length > 0) {
			let curPid = 0; //pid=0，为最上层节点 ，即无父节点
			let parent = this.findChild(curPid); //数组
			return parent;
		} else {
			return [];
		}
	};
	//找子节点
	@action.bound findChild = curPid => {
		let _arr = [];
		let items = this.arr;
		let length = items.length;
		for (let i = 0; i < length; i++) {
			if (items[i].parentId == curPid) {
				let _obj = items[i];
				_obj.children = this.findChild(_obj.cateId);
				_arr.push(_obj);
			}
		}
		return _arr;
	};

	@action clearAll() {
		this.isLoading = false;
		this.tableData = [];
		this.arr = []; //数据转换时过度数据
		this.selectedRows = []; //表格被选中的行
		this.PageInfo = { pageIndex: 1, pageSize: 10 };
		this.formData = {};
		this.type = ''; //查询条件
		this.modalVisible = false;
		this.searchOptions = {};
		this.bomsec = [];
	}
}

let MaterialStore = new Material();
export default MaterialStore;
