import { observable, action,toJS } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import { message } from 'antd';
import * as services from '../services/order';

class Order {
  @observable scheduleNumber = ''
  @observable isLoading = false;
  @observable orderList = [];
  @observable orderPageInfo = {pageIndex:1,
    pageSize:10};
  @observable searchOrderId = '';
  @observable masterData = []; // 获取的物料数据
  @observable masterList = [ //页面物料明细的数组
    {
      id:1,
      masterCode:'',
      count:0,
      measureUnit:'',
      deadline:'',
      description:''
    }
  ]
  @observable orderRecord = {}
  @observable customInfo = ''; // 客户信息
  @observable currentOrderInfo = {}; //获取当前的编辑信息
  @observable listColumnsDo = []
  @observable queryType = 'query';
  @observable currentMaterial = ''

  @action async toReport(params){
    try {
      let res = await services.posts('generatePlan')(params)
      if(isDataExist(res)){
        message.success('下发成功')
      }
    } catch (error) {
      console.log(error)
    }
    this.getOrder()
  }
  /* order dialog columns */
  @action async getListColumnDO(status){
    try {
      let res = await services.gets('listColumnDO')({tableName:'order'})
      if(isDataExist(res)){
        if(status === 'check'){
          res.data.data.map(item=>{
            Object.assign(item,{
              defualtValue : this.currentOrderInfo.extendValues[item.columnId]
            });
          })
        }
        this.listColumnsDo = res.data.data;
      }
    } catch (error) {
      console.log(error)
    }
  }
  @action.bound changeListColumns(id,value){
    this.listColumnsDo.map(col =>{
      if(col.columnId === id){
         col.defualtValue = value
      }
    })
  }

  @action async getOrder(params){
    this.isLoading = true
    this.orderList = []
    try {
      let req = this.queryType === 'query' ? 'queryOrderList' : 'getOrderList';
      let res = await services.gets(req)({...this.orderPageInfo,...params})
      this.isLoading = false
      if(isDataExist(res)){
        this.orderList = res.data.data.items
        this.orderPageInfo = res.data.data.page
      }
    } catch (error) {
      console.log(error)
    }
  }
  @action async editOrder(params){
    try {
      let res = await services.gets('editOrder')(params);
      if(isDataExist(res)){
        this.currentOrderInfo = res.data.data;
        //this.getListColumnDO('check')
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action async delOrder(params){
    try {
      let res = await services.posts('delOrder')(params);
      if(isDataExist(res)){
        message.success('删除成功');
        this.getOrder({
          orderId:this.searchOrderId,
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action async getMaster(params){
    try {
      let res = await services.gets('getMaster')(params);
      if(isDataExist(res)){
        this.masterData = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action async saveOrder(params,status){
    try {
      let req = 'addOrder';
      let res = await services.posts(req)(params);
      if(isDataExist(res)){
        message.success('保存成功');
        return res
      }
    } catch (error) {
      console.log(error);
    }
  }
  @action async changeStatus(params){
    try {
      let res = await services.posts('changeStatus')(params);
      if(isDataExist(res)){
        message.success('状态修改成功');
      }
    } catch (error) {
      console.log(error);
    }
    this.getOrder({
      orderId:this.searchOrderId,pageIndex:1,pageSize:10
    })
  }
  @action.bound addMasterItem(){
    let masterlist = JSON.parse(JSON.stringify(toJS(this.masterList)));
    let lastitem = masterlist.slice(masterlist.length-1)[0];
    masterlist.push({
      id:lastitem.id+1,
      masterCode:'',
      count:0,
      measureUnit:'',
      deadline:'',
      description:''
    });
    this.masterList = masterlist;
  }
  @action.bound delMasterItem(){
    let masterlist = JSON.parse(JSON.stringify(toJS(this.masterList)));
    if(masterlist.length > 1){
      masterlist.pop();
      this.masterList = masterlist;
    }else{
      message.warning('物料明细不能再减少了');
    }
  }
  @action changeMaster(values){
    let master = {};
    for(let key in values){
      let id = parseInt(key.toString());
      if(id){
        master[id] = {};
      }
    }

    try {
      
    } catch (error) {
      console.log(error);
    }
  }
  @action changeValue(key,value){
    if(key === 'data'){
      this.masterList.map(item=>{
        if(item.id === value.id){
          item.deadline = value.value;
        }
      });
    }else{
      this[key] = value;
    }
  }
  @action clearQuery(){
    this.searchOrderId = '';
  }
  @action clearData(){
    this.scheduleNumber = ''
    this.isLoading = false;
    this.orderList = [];
    this.orderPageInfo = {pageIndex:1,
      pageSize:10};
    this.searchOrderId = '';
    this.masterData = []; // 获取的物料数据
    this.masterList = [ //页面物料明细的数组
      {
        id:1,
        masterCode:'',
        count:0,
        measureUnit:'',
        deadline:'',
        description:''
      }
    ]
    this.orderRecord = {}
    this.customInfo = ''; // 客户信息
    this.currentOrderInfo = {}; //获取当前的编辑信息
    this.listColumnsDo = []
    this.queryType = 'query';
  }
  @action clearModal(){
    this.masterList = [ 
      {
        id:1,
        masterCode:'',
        count:0,
        measureUnit:'',
        deadline:'',
        description:''
      }
    ]
  }
}
let OrderStore = new Order();
export default OrderStore;
