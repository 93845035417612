import { observable, action } from 'mobx';
import * as services from '../services/production';
import { isDataExist } from 'utils/dataTools';
import { message, Modal } from 'antd';


class Resume {
  @observable isLoading = false;
  @observable List = {}  //定义设备信息


  /* 设备信息 */
  @action getList(params){
    this.isLoading = true;
		try {
			//let res = await services.gets('getList')(params);
			this.isLoading = false;
		  //if(isDataExist(res)){
			this.List = {
          name : '铸造机01',
          status: '在线',
          number : '1001092801',
          type : '铸造设备',
          time : '809h'
        } 
       
			//}
		} catch (error) {
			console.log(error);
		}
  }

}
let ResumeStore = new Resume();
export default ResumeStore;