import { get, post, put, del } from '../utils/request';

/* 存储请求地址*/
export const requestUrl = {
    // 列表：GET 新增/修改:POST 删除：DELETE
    inspect: '/mom/pile/inspectItem',//质检配置
    model: '/mom/pile/model',//充电桩型号配置
    procedure: '/mom/pile/procedure',//充电桩安装基础工序配置
    worker: '/mom/pile/worker',//产线工人配置
    workerType: '/mom/pile/workerType',//产线工种配置
    procedureInspectScheme: '/mom/pile/procedureInspectScheme',//充电桩工序的质检方案配置
    techline: '/mom/pile/techline',//工艺路径配置
    techlineSimpleList: '/mom/pile/techline/simpleList',//工艺路径列表 不分页
    inspectItemSimpleList: '/mom/pile/inspectItem/simpleList',//质检项列表 不分页
    procedureSimpleList: '/mom/pile/procedure/simpleList',//基础工序列表 不分页
    workerTypeSimpleList: '/mom/pile/workerType/simpleList',//产线工种列表 不分页
    getProcedureStepTypes: '/mom/pile/procedure/getProcedureStepTypes',//获取工序类型


}

/* 测试接口的数据 */
export async function getRequest(url, data, options) {
    return await get(url, data, options);
}

/* 测试接口的数据 */
export async function postRequest(url, data, options) {
    return await post(url, data, options);
}
/* 测试接口的数据 */
export async function putRequest(url, data, options) {
    return await put(url, data, options);
}

export async function deleteRequest(url, data, options) {
    return await del(url, data, options);
}