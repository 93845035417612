/*
 * @catalogue: 生产管理-生产进度
 * @Description: store
 * @Author: 黄红燕
 * @Date: 2020-12-07 17:42:37
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-01-11 16:19:08
 */
 import { observable, action } from 'mobx';
import * as services from '../services/production';
import { isDataExist } from 'utils/dataTools';
import { message, Modal } from 'antd';
import { omit,isEmpty } from 'lodash'
class Workorder {
  @observable isLoading = false;
  /* 列表 */
  @observable workList = []
  @observable workPage = {pageIndex:1,pageSize:10}
  @observable deviceByList = []

  /* 弹窗 */
  @observable visible = false
  @observable status = ''
  @observable currentWork = {}
  @observable editInfo = {}
  @observable searchOption = {}

  @observable materialId = ''
  @observable deviceId = ''
  @observable procedureId = ''

  @observable formData = []

  /**
   * @Description: 生产管理-生产进度
   * @param {object} data 查询条件
   */
  @action async getWorkList(data={}){
    this.isLoading = true;
    this.workList = []
		try {
			let params = {
        ...omit(this.workPage,'total'),
        ...this.searchOption,
        ...data
			};
			let res = await services.gets('getWorkList')(params);
			this.isLoading = false;
			if(isDataExist(res)){
				this.workList = res.data.data.items;
				this.workPage = res.data.data.page;
			}
		} catch (error) {
			console.log(error);
		}
  }


	@action changeVisible = (visible)=>{
		this.visible = visible;
  }
  @action clearModal(){
		this.status = '';
    this.visible = false;
    this.workerId = '';
    this.currentWork = {}
  }
  @action clearData(){
    this.isLoading = false;
    /* 筛选条件 */
    this.searchOption = {};
    /* 列表 */
    this.workList = []
    this.workPage = {pageIndex:1,pageSize:10}
    this.deviceByList = []
  
    /* 弹窗 */
    this.visible = false
    this.status = ''
    this.currentWork = {}
    this.editInfo = {}
  
    this.materialId = ''
    this.deviceId = ''
    this.procedureId = ''
  }
}

let WorkorderStore = new Workorder();
export default WorkorderStore;
