import { observable, action, toJS } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/config';
import { omit } from 'lodash';
class Config {
	@observable isLoading = false;
	@observable tableData = []; //表格数据
	@observable selectedRows = []; //选中表格
	@observable PageInfo = { pageIndex: 1, pageSize: 10 }; //分页信息
	@observable formData = {}; //表单数据
	@observable modalVisible = false; //模态框显隐
	@observable searchOptions = {};
	@observable techlineSimpleList = []; //不分页列表 工艺路径
	@observable inspectItemSimpleList = []; //不分页列表 质检项
	@observable procedureSimpleList = []; //不分页列表 基础工序
	@observable workerTypeSimpleList = []; //不分页列表 工种列表
	@observable stepTypeOptions = []; //工序类型


	//获取表格数据
	/**
	 * @Description: 配置管理-表格数据
	 * @param {number} type 1质检项列表 2充电桩型号配置 3充电桩安装基础工序配置 4产线工人配置 5产线工种配置 6充电桩工序的质检方案配置
	 * @param {object} params 查询条件
	 */
	@action.bound async getData(type, params) {
		this.isLoading = true;
		try {
			let data = { ...this.searchOptions, ...omit(this.PageInfo) }
			let res = await services.getRequest(services.requestUrl[type], {
				...data,
				...params
			});
			this.isLoading = false;
			if (isDataExist(res)) {
				this.tableData = res.data.data.items;
				this.PageInfo = res.data.data.page;
			}
		} catch (error) {
			console.log(error);
		}
	}

	//新增，修改
	@action.bound async addEdit(type, params) {
		this.isLoading = true;
		try {
			let res = await services.putRequest(services.requestUrl[type], params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}
	//删除
	@action.bound async deleteConfig(type,params) {
		this.isLoading = true;
		try {
			let res = await services.deleteRequest(services.requestUrl[type],params);
			this.isLoading = false;
			if (isDataExist(res)) {
				return res;
			}
		} catch (error) {
			console.log(error);
		}
	}

	//获取不分页列表
	/**
	 * @Description: 配置管理-列表
	 * @param {string} type techline:工艺路径列表  inspectItem:质检项列表
	 * @param {object} params 查询条件
	 */
	@action.bound async getSimpleList(type) {
		const urlName = `${type}SimpleList`
		this.isLoading = true;
		try {
			let res = await services.getRequest(services.requestUrl[urlName]);
			this.isLoading = false;
			if (isDataExist(res)) {
				this[urlName] = res.data.data;
			}
		} catch (error) {
			console.log(error);
		}
	}

	//获取工序类型
	@action.bound async getProcedureStepTypes() {
		this.isLoading = true;
		try {
			let res = await services.getRequest(services.requestUrl.getProcedureStepTypes);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.stepTypeOptions = res.data.data;
			}
		} catch (error) {
			console.log(error);
		}
	}

	@action clearData() {
		this.isLoading = false;
		this.tableData = []; //表格数据
		this.selectedRows = []; //选中表格
		this.PageInfo = { pageIndex: 1, pageSize: 10 }; //分页信息
		this.formData = {}; //表单数据
		this.modalVisible = false; //模态框显隐
		this.searchOptions = {};
		this.techlineSimpleList = []
		this.inspectItemSimpleList = []
		this.procedureSimpleList = []
		this.workerTypeSimpleList = []
		this.stepTypeOptions = []
	}

	//打开/关闭模态框
	@action modalChange = (type, record) => {
		if (type) {
			if (record) {
				this.formData = record;
			}
		} else {
			this.formData = { delFlag: 1 };
		}
		this.modalVisible = !this.modalVisible;
	};


}
let ConfigStore = new Config();
export default ConfigStore;
