import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { message } from 'antd';
import { omit, isEmpty } from 'lodash';
import CodeStore from './CodeStore';

class ToolsDefine {
	@observable isLoading = false;
	@observable tableList = [];
	@observable waresList = [];
	@observable materialList = [];
	@observable toolList = []; //工具-下拉框
	@observable operationList = {
		2: [{ id: 1, name: '生产领用' }, { id: 2, name: '保养出库' }, { id: 3, name: '维修出库', }],
		1: [{ id: 4, name: '购买入库' }, { id: 5, name: '生产归还' }, { id: 6, name: '保养归还' }, { id: 7, name: '维修归还' },]
	};
	@observable deviceList = [];
	@observable procedureList = [];
	@observable warehouseList = []; //仓库-下拉框
	@observable locatorList = []; //库位-下拉框
	@observable waresPage = { pageIndex: 1, pageSize: 10 };
	@observable tablePage = { pageIndex: 1, pageSize: 10 };
	@observable name = '';
	@observable searchOptions = {}; //添加的查询条件

	/* 弹窗 */
	@observable type = 0; //2出库1入库
	@observable formData = {};
	@observable visible = false;
	@observable selectedRowKeys = [];
	@observable plantList = [];

	@action.bound async getData(type, params) {
		try {
			this.isLoading = true;
			let req = !type ? 1 : 2; /*1：模具，2：工装，3：夹具*/
			let res = await services.getRequest(services.requestUrl.getToolsList, {
				...omit(this.tablePage, 'total'),
				...this.searchOptions,
				...params,
				pattern: req,
			});
			/*查询的四个参数：↑ pageIndex，pageSize，type(选择模具or工装)，name(输入的名字)*/
			this.isLoading = false;
			if (isDataExist(res)) {
				this.tablePage = res.data.data.page;
				this.tableList = res.data.data.items;
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 获取工具-下拉框
	@action async getTool(params) {
		try {
			let res = await services.getRequest(services.requestUrl.getTool, params)
			if (isDataExist(res)) {
				this.toolList = res.data.data
			}
		} catch (error) {
			console.log(error)
		}
	}
	/**
	 * 获取工具选择框数据
	 * @param {object} params 
	 * @param {string} params.name 工具名
	 * @param {number} params.patter  1模具 2工装
	 */
	@action async getToolList(params) {
		try {
			let res = await services.getRequest(services.requestUrl.getToolList, params)
			if (isDataExist(res)) {
				this.toolList = res.data.data
			}
		} catch (error) {
			console.log(error)
		}
	}
	// 获取仓库-下拉框
	@action async getWarehouseList(params) {
		try {
			let res = await services.getRequest(services.requestUrl.getWarehouseList, params)
			if (isDataExist(res)) {
				this.warehouseList = res.data.data
			}
		} catch (error) {
			console.log(error)
		}
	}
	// 获取库位-下拉框
	@action async getLocatorList(params) {
		try {
			let res = await services.getRequest(services.requestUrl.getLocatorList, params)
			if (isDataExist(res)) {
				this.locatorList = res.data.data
			}
		} catch (error) {
			console.log(error)
		}
	}
	// 出入库
	@action async stockInOut(params) {
		try {
			let res = await services.putRequest(services.requestUrl.stockInOut, params)
			if (isDataExist(res)) {
				return res
			}
		} catch (error) {
			console.log(error)
		}
	}
	// 出入库记录
	@action.bound async stockHistory(params) {
		try {
			this.isLoading = true;
			let res = await services.getRequest(services.requestUrl.stockHistory, params);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.tablePage = res.data.data.page;
				this.tableList = res.data.data.items;
			}
		} catch (error) {
			console.log(error);
		}
	}
	@action.bound async getWaresData(type, params) {
		try {
			this.isLoading = true;
			let req = !type ? 1 : 2; /*1：模具，2：工装，3：夹具*/
			let res = await services.getRequest(services.requestUrl.toolsWareList, {
				...params,
				pattern: req,
				name: this.name
			});
			/*查询的四个参数：↑ pageIndex，pageSize，type(选择模具or工装)，name(输入的名字)*/
			this.isLoading = false;
			if (isDataExist(res)) {
				this.waresPage = res.data.data.page;
				this.waresList = res.data.data.items;
			}
		} catch (error) {
			console.log(error);
		}
	}

	@action.bound async getAdre(type, params) {
		try {
			this.isLoading = true;
			let req = !type ? 1 : 2; /*1：模具，2：工装，3：夹具*/
			let res = await services.getRequest(services.requestUrl.adrList, {
				...params,
				pattern: req,
				name: this.name
			});
			/*查询的四个参数：↑ pageIndex，pageSize，type(选择模具or工装)，name(输入的名字)*/
			this.isLoading = false;
			if (isDataExist(res)) {
				this.waresPage = res.data.data.page;
				this.waresList = res.data.data.items;
			}
		} catch (error) {
			console.log(error);
		}
	}
	@action.bound async getPlant() {
		try {
			let res = await services.getRequest(services.requestUrl.getPlantList, {
				pageIndex: 1,
				pageSize: 10000
			});
			if (isDataExist(res)) {
				this.plantList = res.data.data.items;
			}
		} catch (error) { }
	}

	@action async getReportCount(params) {
		try {
			let res = await services.getRequest(
				services.requestUrl.getReportCount,
				params
			);
			if (isDataExist(res)) {
				let histogramDs = [
					{
						name: 'failCount'
					},
					{
						name: 'successCount'
					}
				];
				histogramDs.map(ele => {
					res.data.data.map(item => {
						ele[item.reportTime] = item[ele.name];
					});
				});
				this.histogramDs = histogramDs;
			}
		} catch (error) {
			console.log(error);
		}
	}
	// 获取模具关联物料
	@action async getMouldInfo(params) {
		try {
			let res = await services.getRequest(
				services.requestUrl.getMouldInfo,
				params
			);
			if (isDataExist(res)) {
				this.formData = res.data.data;
			}
		} catch (error) { }
	}
	//获取物料列表
	@action async getMaterialList(params) {
		this.isLoading = true;
		try {
			//getMaterial
			let res = await services.getRequest(
				services.requestUrl.getMaterialListInfo,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.materialList = res.data.data;
			}
		} catch (error) {
			console.log(error);
		}
	}
	//获取设备列表
	@action async getDeviceTypeInfo(params) {
		this.isLoading = true;
		try {
			let res = await services.getRequest(
				services.requestUrl.getDevicetypeInfo,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.deviceList = [];
				res.data.data.map(item => {
					this.deviceList.push({ label: item.name, value: item.id });
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	//获取工序列表
	@action async getProcedureInfo(params) {
		this.isLoading = true;
		try {
			let res = await services.getRequest(
				services.requestUrl.listProcedure,
				params
			);
			this.isLoading = false;
			if (isDataExist(res)) {
				this.procedureList = [];
				res.data.data.map(item => {
					this.procedureList.push({ label: item.name, value: item.id });
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	// //获取库位列表
	// @action async getWarehouseListInfo(params) {
	// 	this.isLoading = true;
	// 	try {
	// 		let res = await services.getRequest(
	// 			services.requestUrl.getWarehouseList,
	// 			params
	// 		);
	// 		this.isLoading = false;
	// 		if (isDataExist(res)) {
	// 			this.warehouseList = res.data.data;
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }
	@action async deleteWare(params) {
		try {
			// let req = !type ? 'deletePlant' : 'deleteWorkShop';
			let res = await services.putRequest(
				services.requestUrl.deleteWare,
				params
			);
			if (isDataExist(res)) {
				message.success('删除成功！');
			}
		} catch (error) {
			console.log(error);
		}
	}

	@action async deleteWareStock(params) {
		try {
			// let req = !type ? 'deletePlant' : 'deleteWorkShop';
			let res = await services.putRequest(
				services.requestUrl.deleteWareStock,
				params
			);
			if (isDataExist(res)) {
				message.success('删除成功！');
			}
		} catch (error) {
			console.log(error);
		}
	}

	@action async deleteItem(params) {
		try {
			// let req = !type ? 'deletePlant' : 'deleteWorkShop';
			let res = await services.putRequest(
				services.requestUrl.removeTools,
				params
			);
			if (isDataExist(res)) {
				message.success('删除成功！');
			}
		} catch (error) {
			console.log(error);
		}
	}
	@action async saveItem(type, params) {
		try {
			// 0: 工厂保存  1:车间保存  2:工厂更新  3:车间更新
			// let req = type === 1 ? 'saveWorkShop' : type === 2 ? 'updatePlant' : type === 3 ? 'updateWorkShop' : 'savePlant';
			let req = !type ? 1 : 2; /*1：模具，2：工装，3：夹具*/
			let res = await services.putRequest(services.requestUrl.saveTools, {
				...params,
				pattern: req
			});
			console.log("")
			if (isDataExist(res)) {
				return res
			}
			else {
				return res = 1
			}
		} catch (error) {
			console.log(error);
		}
	}
	@action async saveWaresItem(type, params) {
		try {
			// 0: 工厂保存  1:车间保存  2:工厂更新  3:车间更新
			// let req = type === 1 ? 'saveWorkShop' : type === 2 ? 'updatePlant' : type === 3 ? 'updateWorkShop' : 'savePlant';
			let req = !type ? 1 : 2; /*1：模具，2：工装，3：夹具*/
			let res = await services.putRequest(services.requestUrl.saveWarehouse, {
				...params,
				pattern: req
			});
			if (isDataExist(res) == true) {
				return res
			} else {
				return res = 1
			}
		} catch (error) {
			console.log(error);
		}
	}

	@action async saveAdreItem(type, params) {
		try {
			// 0: 工厂保存  1:车间保存  2:工厂更新  3:车间更新
			// let req = type === 1 ? 'saveWorkShop' : type === 2 ? 'updatePlant' : type === 3 ? 'updateWorkShop' : 'savePlant';
			let req = !type ? 1 : 2; /*1：模具，2：工装，3：夹具*/
			let res = await services.putRequest(services.requestUrl.mudSave, {
				...params,
				pattern: req
			});
			if (isDataExist(res) == true) {
				return res
			} else {
				return res = 1
			}
		} catch (error) {
			console.log(error);
		}
	}

	/* modify formData with material & count  */
	@action modifyFormData({ id, index, type, keys }) {
		let materialVOS = this.formData.materialVOS || [];
		let current =
			type === 'material' ? this.materialList.filter(m => m.id === id)[0] : [];
		if (type === 'add') {
			if (isEmpty(materialVOS[index + 1])) {
				materialVOS.push({
					id: index + 1,
					materialId: undefined,
					num: undefined
				})
			}
		} else if (type === 'delete') {
			materialVOS.splice(index, 1)
		} else {
			if (!isEmpty(materialVOS)) {
				switch (type) {
					case 'material':
						materialVOS[index] = {
							...omit(current, 'id'),
							materialId: current.id,
							num: ''
						};
						break;
					case 'num':
						materialVOS[index].num = id;
						break;
					default:
						break;
				}
			} else {
				switch (type) {
					case 'material':
						materialVOS.push({
							...omit(current, 'id'),
							materialId: current.id
						});
						break;
					case 'num':
						materialVOS.push({
							num: id
						});
						break;
					default:
						break;
				}
			}
		}
		this.formData = {
			...this.formData,
			materialVOS
		};
	}
	@action modalChange(obj) {
		this.type = obj.type;
		if (obj.record) {
			this.formData = obj.record;
		}
		this.visible = obj.visible;
	}
	@action changeValue(key, value) {
		this[key] = value;
	}
	/**
	 * @Description: 工具管理-工具定义-刀具
	 * @param {object} params
	 */
	@action.bound async getKnife(params) {
		try {
			this.isLoading = true;
			let res = await services.getRequest(services.requestUrl.getKnife, {
				...omit(this.tablePage, 'total'),
				...this.searchOptions,
				...params,
				pattern: 4,
			});
			this.isLoading = false;
			if (isDataExist(res)) {
				this.tablePage = res.data.data.page;
				this.tableList = res.data.data.items;
			}
		} catch (error) {
			console.log(error);
		}
	}
	@observable knifeTypeList = []; // 刀具类型
	/**
     * @Description: 工具管理-工具定义-刀具类型
     * @param {object} params
     */
	@action.bound async getKnifeTypeList(params) {
		try {
			let res = await services.getRequest(services.requestUrl.getKnifeTypeList, params);
			if (isDataExist(res)) {
				this.knifeTypeList = res.data.data;
			}
		} catch (error) {
			console.log(error);
		}
	}
	@action clearData() {
		this.isLoading = false;
		this.tableList = [];
		this.tablePage = { pageIndex: 1, pageSize: 10 };
		this.toolList = [];
		this.name = '';
		this.searchOptions = {};
		this.knifeTypeList = [];

		/* 弹窗 */
		this.type = 0;
		this.formData = {};
		this.visible = false;
		this.selectedRowKeys = [];
		this.plantList = [];
	}
}
let ToolsDefineStore = new ToolsDefine();
export default ToolsDefineStore;
