import { get, post, put} from '../utils/request'

/* 存储请求地址 */
export const requestUrl={
  getPic:'/mom/picture/list', //表格数据
  
  getDevice:'/mom/basic/device/getAllInfo',//设备-下拉框
  getMaterial:'/mom/basic/material/getInfo',//物料-下拉框
  getProcedure: '/mom/basic/procedure/getInfo',//工序-下拉框
  getProcedureByMaterialId: '/mom/basic/techline/getProcedureByMaterialId',
  getTool:'/mom/mould/mould/listAll',//工具-下拉框
  save:'/mom/picture/save',//新增修改
  delete:'/mom/picture/remove', //删除
}

/* 测试接口的数据 */
export async function getRequest(url, data, options) {
  return await get(url, data, options);
}

/* 测试接口的数据 */
export async function postRequest(url, data, options){
  return await post(url, data, options);
}

/* 测试接口的数据 */
export async function putRequest(url, data, options){
  return await put(url, data,options);
}