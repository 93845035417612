/*
 * @catalogue: 知识图谱-仓库定义
 * @Description: store
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: 黄红燕
 * @LastEditTime: 2021-02-22 16:08:18
 */
import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/basic';
import { omit } from 'lodash';

class Warehouse {
    @observable isLoading = false;
    @observable tableData = [];
    @observable processList = [];
    @observable PageInfo = {};
    @observable formData = {};
    @observable searchOptions = '';
    @observable modalVisible = false;

    //获取表格数据
    @action.bound async getData(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.warehouseList, {
                ...omit(this.PageInfo, 'total'),
                ...this.searchOptions,
                ...params
            })
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }
    //获取工序列表
    @action.bound async getProList(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.allProcess, params)
            this.isLoading = false
            if (isDataExist(res)) {
                this.processList = res.data.data
            }
        } catch (error) {
            console.log(error)
        }
    }
    //保存更新信息
    @action.bound async addNew(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.savewarehouse, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 删除仓库
    @action.bound async deleteWarehouse(params) {
        this.isLoading = true
        try {
            let res = await services.putRequest(services.requestUrl.deleteWarehouse, params)
            this.isLoading = false
            if (isDataExist(res)) {
                return res
            }
        } catch (error) {
            console.log(error)
        }
    }
    //打开/关闭模态框
    @action modalChange = (type, record) => {
        if (type) {
            this.formData = record
        } else {
            this.formData = {}
        }
        this.modalVisible = !this.modalVisible
    }
}

let WarehouseStore = new Warehouse();
export default WarehouseStore;