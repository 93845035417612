/*
 * @catalogue: 
 * @Description: 
 * @Author: 黄红燕
 * @Date: 2020-12-03 14:01:28
 * @LastEditors: hhy
 * @LastEditTime: 2021-04-01 15:38:24
 */
import { get, post, put } from '../utils/request';

/* 存储请求地址*/
export const requestUrl = {
  /* 通用 */
  getMaterial: '/mom/basic/material/getInfo',
  getProcedure: '/mom/basic/techline/getProcedureByMaterialId',

  /* SPC analysis */
  getCate: '/mom/inspect/inspecschema/getCateCode',
  getInspectRules: '/mom/inspect/inspecschema/getInspectByCateCode',
  getProcedureList: '/mom/inspect/inspecstatistic/getProcedureIdByCateCode',
  getMeasureData: '/mom/inspect/spcAnaly/list',
  getNumberData: '/mom/inspect/spcAnaly/list',
  getDevice: '/mom/basic/deviceType/getInfo',
  getDeviceList: '/mom/basic/device/getInfo',
  configByProcedure: '/mom/inspect/item/listByProcedureId',
  configByPath: '/mom/inspect/path/getInfo',
  toggleInspectStatus: '/mom/inspect/path/toggleInspectStatus', // 修改质检方案状态

  getRXData: '/mom/inspect/analy/spcAnalyseXR',
  getPData: '/mom/inspect/analy/spcAnalyseP',

  /**
   * 质量分析
   */
  getRate: '/mom/inspect/analy/inspectRateAnalyse',
  getPie: '/mom/inspect/analy/defectAnalysis',
  getWorkshopList: '/mom/basic/workshop/getInfo',
  /**
   * 凯马质量分析
   */
  getQualityRateData: '/mom/reportForm/qualifiedRate', // 合格率
  getQualityReasonData: '/mom/reportForm/unqualifiedReason', // 不合格原因

  /* -------------点检 && 保养--------------- */
  /* 库 */
  getMaintain: '/mom/inspect/item/list', // 获取库
  singleRemove: '/mom/inspect/item/remove',
  saveMaintain: '/mom/inspect/item/save',
  getServeInfo: '/mom/device/config/getInfo',

  /* 方案 */
  getScheme: '/mom/inspect/path/list',
  delSchema: '/mom/inspect/path/remove',
  saveSchema: '/mom/inspect/path/save',
  schemaInfo: '/mom/inspect/path/getInfo',

  /* 计划 */
  getPlan: '/mom/inspect/plan/list',
  savePlan: '/mom/inspect/plan/save',
  delPlan: '/mom/inspect/plan/remove',
  batchDelPlan: '/mom/inspect/plan/batchRemove',
  getPlanInfo: '/mom/inspect/plan/getInfo',
  toDetail: '/mom/inspect/plan/save',

  /* 查询 */
  getQuery: '/mom/inspect/task/list',
  getResultInfo: '/mom/inspect/task/getInfo',
  allBomList: '/mom/basic/material/getInfo',
  procedureList: '/mom/basic/procedure/getInfo',

  /* 执行报表 */
  getExecuteCharts: '/mom/inspect/task/getExeAnalyse',
  getExecuteTable: '/mom/inspect/task/getExecuteDetail',
};

/* 测试接口的数据 */
export async function getRequest(url, data, options) {
  return await get(url, data, options);
}

/* 测试接口的数据 */
export async function postRequest(url, data, options) {
  return await post(url, data, options);
}
/* 测试接口的数据 */
export async function putRequest(url, data, options) {
  return await put(url, data, options);
}
