/**
 * @description 通用配置store,包括了表格配置title
 */
import { observable, action, } from "mobx";
import { isDataExist } from "Utils/dataTools";
import * as services from "../services/basic";
import { message, } from "antd";
import { get } from 'lodash'

class AttendenceCalendar {
  // constructor(){
  //   this.settings = SettingStore
  // }
  @observable isLoading = false;
  @observable tableData = [];
  @observable tablePage = { pageIndex: 1, pageSize: 10 };
  @observable pageKey = '';
	@observable id = '';
	@observable formData = {};
  @observable isShowDialog = false;
  @observable modalVisible = false;
  @observable attendence = [];
  @observable device = [];
  @observable deviceAll = [];
  @observable deviceInitial = [];
  @observable attendenceId = '';
  @observable deviceId = '';
  /* 配置弹窗内容 */
  @observable fieldParams = {}
  @observable columnList = []
  @observable columnRelativeList = []
  /* 列表内容 */
  @observable selectedRowKeys = []
  
  //获取所有设备/资源
  @action.bound async getAllDevice(params){
    this.isLoading = true;
    try{
      let res = await services.getRequest(services.requestUrl.getAllDevice, params)
      this.isLoading = false
      if (isDataExist(res)) {
          this.device = res.data.data
      }
    }catch (error){
      console.log(error);
    }
  }

  @action.bound async getAllDeviceTotal(params){
    this.isLoading = true;
    try{
      let res = await services.getRequest(services.requestUrl.getAllDevice, params)
      this.isLoading = false
      if (isDataExist(res)) {
          let all = {id:"*",name:"*"}
          this.deviceAll = res.data.data.concat(all)
          this.deviceInitial = this.deviceAll.find(item => item.code === 'LHHP00').id
      }
    }catch (error){
      console.log(error);
    }
  }

  //获取所有班次
  @action.bound async getAllAttendence(params) {
    this.isLoading = true
    try {
        let res = await services.getRequest(services.requestUrl.getAllAttendenceName, params)
        this.isLoading = false
        if (isDataExist(res)) {
            this.attendence = res.data.data
        }
    } catch (error) {
        console.log(error)
    }
}
  
  /* 获取数据信息 */
  @action.bound async getData(params){
    this.isLoading = true;
    try {
      let res = await services.getRequest(services.requestUrl.getAttendenceCalendarData, params)
      this.isLoading = false
      if (isDataExist(res)) {
        this.tablePage = res.data.data.page
        this.tableData = res.data.data.items
      }
    } catch (error) {
      console.log(error)
    }
  }

  //打开/关闭模态框
  @action.bound modalChange = (type, record) => {
    this.id = record && record.id;
    //编辑
    if (type === 1) {
          let newRecord = {};
          newRecord.id = record.id;
          newRecord.deviceName = record.deviceName;
          newRecord.deviceId = record.deviceId;
          newRecord.dateTime = record.dateTime;
          newRecord.attendenceId = record.attendenceId;
          newRecord.attendenceName = record.attendenceName;
          newRecord.priority = record.priority;
          newRecord.description = record.description;
          newRecord.creator = record.creator;
          newRecord.createTime = record.createTime;
          newRecord.updator = record.updator;
          newRecord.updateTime = record.updateTime;
          newRecord.tenantId = record.tenantId;
          this.pageKey = "edit";
          let dateTimeAll = record.dateTime
          let eachList = []
          //多个时间段
          if(dateTimeAll.indexOf(';') >= 0){
            let dateTimeS = dateTimeAll.split(';');
            for(var i = 0; i < dateTimeS.length; i++){
              //时间段
              if(dateTimeS[i].indexOf('-') >= 0){
                let attendenceTimeEach = dateTimeS[i].split('-');
                let each = {};
                each.front = attendenceTimeEach[0]
                each.back = attendenceTimeEach[1]
                eachList.push(each);
              }
              //只有一个时间
              else{
                let each1 = {};
                each1.front = dateTimeS[i]
                eachList.push(each1);
              }
            }
          }
          //一个时间段
          else{
            //时间段
            if(dateTimeAll.indexOf('-') >= 0){
              let attendenceTimeEach1 = dateTimeAll.split('-');
              let each1 = {};
              each1.front = attendenceTimeEach1[0]
              each1.back = attendenceTimeEach1[1]
              eachList.push(each1);
            }
            //只有一个时间
            else{
              let each1 = {};
              each1.front = dateTimeAll
              eachList.push(each1);
            }
            
          }
          newRecord.dateTime = eachList;
          //设备
          if(record.deviceName === "*"){
            newRecord.deviceId = "*"
          }else{
            let deviceIdS = record.deviceId
            let deviceIdList = [];
            if(deviceIdS.indexOf(',') >= 0){
              
              let device = deviceIdS.split(',');
              for(var j = 0; j < device.length; j++){
                deviceIdList.push(parseInt(device[j]));
              }
            }else{
              deviceIdList.push(parseInt(deviceIdS));
            }
            newRecord.deviceId = deviceIdList;
          }
          this.formData = newRecord
    } 
    //新增
    else if(type === 0){
        this.pageKey = "add";
        this.formData = {}
    }
    this.modalVisible = !this.modalVisible
  }


  //新增班次
  @action.bound async saveAttendenceCalendar(params){
    this.isLoading = true;
    try {
      let res = await services.putRequest(services.requestUrl.saveAttendenceCalendar,params)
      this.isLoading = false
      if(isDataExist(res)){
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  //编辑班次
  @action.bound async editAttendenceCalendar(params){
    this.isLoading = true;
    try {
      let res = await services.putRequest(services.requestUrl.editAttendenceCalendar,params)
      this.isLoading = false
      if(isDataExist(res)){
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  //删除班次
  @action.bound async deleteAttendenceCalendar(params) {
    this.isLoading = true
    try {
        let res = await services.putRequest(services.requestUrl.removeAttendencelendar, params)
        this.isLoading = false
        if (isDataExist(res)) {
            return res
        }
    } catch (error) {
        console.log(error)
    }
}
  @action clearData(){
    this.attendence = [];
    this.device = [];
    this.deviceAll = []
    this.isLoading = false;
		this.tableData = []
    this.tablePage = { pageIndex: 1, pageSize: 10 };
		this.pageKey = '';
    this.id = '';
    this.attendenceId = '';
    this.deviceId = '';
		this.formData = {};
    this.isShowDialog = false;
  }

  
  
}

let AttendenceCalendarStore = new AttendenceCalendar();
export default AttendenceCalendarStore;
