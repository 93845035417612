import { get,put } from '../utils/request';

const requestList = {
  getOrderList :'/mom/product/order/list',
  queryOrderList :'/mom/product/order/listOrder',
  editOrder:'/mom/product/order/getInfo',
  delOrder:'/mom/product/order/remove',
  getMaster: '/mom/basic/material/getInfo',
  updateOrder:'/mom/product/order/toReport',
  addOrder:'/mom/product/order/save',
  changeStatus:'/mom/product/order/changeStatus',
  //listColumnDO:'/mom/basic/defineColumn/listColumnDO',
  generatePlan :'/mom/product/order/generatePlan',
}

export function gets(type){
  return async function(data,options){
    return await get(requestList[type],data,options);
  }
}
export function posts(type){
  return async function(data,options){
    return await put(requestList[type],data,options);
  }
}