import { observable, action } from 'mobx';
import { isDataExist } from 'Utils/dataTools';
import * as services from '../services/ReportForms';
import { message } from 'antd';
import { omit } from 'lodash';

class ReportForms {
    @observable isLoading = false;
    @observable tableData = [];  //表格数据
    @observable PageInfo = { pageIndex: 1, pageSize: 10, total: 0 }; //表格分页
    @observable type = 1 // 1ipc 2pc
    @observable procedureList = [] //工序列表
    @observable deviceList = [] //设备列表
    @observable materialList = [] //物料列表
    @observable searchOptions = {} //查询条件

    /**
     * 未配置工艺路线
     * @param {Object} params
     * @param {Boolean} params.techline 是否选中
     * @param {Boolean} params.mould 是否选中
     * @param {Boolean} params.inspect 是否选中
     */
    @action.bound async getData(params) {
        this.isLoading = true
        try {
            let res = await services.getRequest(services.requestUrl.getData, {
                ...omit(this.PageInfo, 'total'),
                ...params
            })
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * 操作日志
     * @param {Object} params 
     */
    @action.bound async getLog(params) {
        this.isLoading = true
        let url = this.type === 1 ? services.requestUrl.getLog : services.requestUrl.getUserHistory
        try {
            let res = await services.getRequest(url, {
                ...omit(this.PageInfo, 'total'),
                type: this.type === 2 ? 1 : '',
                ...this.searchOptions,
                ...params
            })
            this.isLoading = false
            if (isDataExist(res)) {
                this.tableData = res.data.data.items
                this.PageInfo = res.data.data.page
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
    * 获取物料列表
    * @param {Object} params 
    * @param {String} params.materialName 设备名模糊搜索
    */
    @action.bound async getMaterialList(params) {
        try {
            let res = await services.getRequest(services.requestUrl.getMaterialList, params)
            if (isDataExist(res)) {
                this.materialList = res.data.data
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
     * 获取工序列表
     * @param {Object} params 
     */
    @action.bound async getProcedureList(params) {
        try {
            let res = await services.getRequest(services.requestUrl.getProcedureList, params)
            if (isDataExist(res)) {
                this.procedureList = res.data.data
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /**
    * 获取设备列表
    * @param {Object} params 
    * @param {String} params.deviceName 设备名模糊搜索
    */
    @action.bound async getDeviceList(params) {
        try {
            let res = await services.getRequest(services.requestUrl.getDeviceList, params)
            if (isDataExist(res)) {
                this.deviceList = res.data.data
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    //打开/关闭模态框type=1为编辑，否则是新增
    @action.bound modalChange = (type, record) => {
        if (type) {
            this.formData = record;
        } else {
            this.formData = {};
        }
        this.modalVisible = !this.modalVisible;
    }
    /**
     * 清除数据
     */
    @action.bound clearData = () => {
        this.isLoading = false;
        this.tableData = [];  //表格数据
        this.PageInfo = { pageIndex: 1, pageSize: 10, total: 0 }; //表格分页
        this.procedureList = [] //工序列表
        this.deviceList = [] //设备列表
        this.searchOptions = {} //查询条件
    }

}
let ReportFormsStore = new ReportForms();
export default ReportFormsStore;
