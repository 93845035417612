
import { observable,action  } from 'mobx';

class IntelAI {
    @observable tag01 = {
        Avail:{
            percent:'97%',
            huan:-33.33,
            tong:27.25
        },
        Perform:{
            percent:'67%',
            huan:-5.21,
            tong:7.56
        },
        Quality:{
            percent:'26%',
            huan:-3.26,
            tong:7.89
        },
        Oee:{
            percent:'22%',
            huan:-13.32,
            tong:1.02
        }
    }
    @observable lineData = []
    @observable lineData1 = []
    @observable lineData2 = []
    @observable chartData1 = []
    @observable chartData2 = []
    // @observable 
    
    @action resetData(){
      this.lineData = []
      this.lineData1 = []
      this.lineData2 = []
      this.chartData1 = []
      this.chartData2 = []
        for(let i = 0;i<12;i++){
            let item = {
              month: `${i+1}`,
              city: '',
              revenue: Math.round(Math.random()*10),
            }
            this.lineData.push(item)
        }
        for(let i = 0;i<12;i++){
            let item = {
              month: `${i+1}`,
              city: '',
              revenue: Math.round(Math.random()*10),
            }
            this.lineData1.push(item)
        }
        for(let i = 0;i<12;i++){
            let item = {
              month: `${i+1}`,
              city: '',
              revenue: Math.round(Math.random()*10),
            }
            this.lineData2.push(item)
        }
        this.chartData1 = [
            {
              year: "等待生产",
              sales: Math.round(Math.random()*500)
            },
            {
              year: "无计划停机",
              sales: Math.round(Math.random()*500)
            },
            {
              year: "设备保养",
              sales: Math.round(Math.random()*500)
            },
            {
              year: "计划维修",
              sales: Math.round(Math.random()*500)
            },
            {
              year: "设备调试",
              sales: Math.round(Math.random()*500)
            },
            {
              year: "紧急维修",
              sales: Math.round(Math.random()*500)
            },
        ]
        this.chartData2 = [
            {
              item: "产品一",
              count: Math.round(Math.random()*10)
            },
            {
              item: "产品二",
              count: Math.round(Math.random()*10)
            },
            {
              item: "产品三",
              count: Math.round(Math.random()*10)
            },
            {
              item: "产品四",
              count: Math.round(Math.random()*10)
            },
            {
              item: "产品五",
              count: Math.round(Math.random()*10)            
            }
          ];
    }
}

let IntelAIStore = new IntelAI();
export default IntelAIStore;