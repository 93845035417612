import { get, post, put } from '../utils/request';

/* 存储请求地址*/
export const requestUrl = {
    getPlanNoList: '/mom/frame/materialFrame/getByFrameCode',
    getTreeData: '/mom/review/bom',
    getProcedureList: '/mom/basic/techline/getProcedureByMaterialId',
    getTreeNodeDetail: '/mom/review/getDetailInfo',
    getInspectByWork: '/mom/inspect/task/list',
    getResultInfo:'/mom/inspect/task/getInfo',
    getDeviceByWrk: '/mom/trace/device',
}

/* 测试接口的数据 */
export async function getRequest(url, data, options) {
    return await get(url, data, options);
}

/* 测试接口的数据 */
export async function postRequest(url, data, options) {
    return await post(url, data, options);
}
/* 测试接口的数据 */
export async function putRequest(url, data, options) {
    return await put(url, data, options);
}